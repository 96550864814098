import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'stores/StoreHelper';
import { useSearchParams, useLocation } from 'react-router-dom';
import { AdDto, ChannelBoardDto } from 'services/data-contracts';
import { useFollowContext } from 'providers/follow/useFollowContext';
import { toast } from 'react-toastify';
import { useGlobalContext } from 'context/useGlobalContext';
import { ReactComponent as MyImage } from 'resources/images/commons/my.svg';
import { useRootContext } from 'libs/hooks/useRootContext';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

import ChecklistIcon from '@mui/icons-material/Checklist';
import { useChat } from 'libs/hooks/useChat';

import useNavigation from 'hooks/useNavigation';
import styled from 'styled-components';
import useToken from 'libs/hooks/useToken';
import NewPostSelector from 'features/board/containers/NewPostSelector';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import useLocalParams from 'libs/hooks/useLocalParams';
import MyNotifyInfo from './MyNotifyInfo';
import FollowListContainer from './follower/container/FollowListContainer';
import { Badge } from '@mui/material';
import chatController from 'features/community/controllers/chatController';

const CSTownNavigationTabBar = ({
  type,
  board,
}: {
  type: 'main' | 'link';
  board?: ChannelBoardDto | null;
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { uiStore, myBoardStore, adStore } = useStore();
  const { validUser } = useToken();
  const { initializeFirstChannel } = useChannelSelector();
  const { follow } = useFollowContext();
  const { report, isLoggedIn } = useGlobalContext();
  const { myChannel, unreadCount } = useRootContext();
  const [searchParam] = useSearchParams();
  const { channelName } = useLocalParams();
  const { navigate, reset } = useNavigation();
  const { getUnreadCounts } = chatController();

  const [setShowAI] = React.useState(false);
  const [inquiryCount, setInquiryCount] = useState<number>(0);

  const handleClickAlarm = () => {
    const result = validUser();

    if (result) {
      uiStore.universalModal.show({
        title: t('A233'),
        description: t('A234'),
        useDefaultButtons: true,
        bottomSheetOptions: { blocking: true, topPosition: 100 },
        modalOption: { style: { maxWidth: 500, maxHeight: 500 } },
        children: (
          <>
            <MyNotifyInfo />
          </>
        ),
      });
    } else {
      showLoginConfirm('2');
    }
  };

  const handleClickAddPost = async () => {
    if (type === 'link') {
      showNewPost();
      return;
    }

    const result = validUser();
    if (result === true) {
      await initializeFirstChannel();
      const boards = (await myBoardStore.getBoards(
        myChannel?.id as number,
      )) as never as ChannelBoardDto[];
      const defaultBoard = boards?.find((board: ChannelBoardDto) => board.isDefault);
      if (defaultBoard && myChannel?.isBusiness) {
        navigate(`/deals/new?channelid=${myChannel?.id}&boardid=${defaultBoard.id}&posttype=1`);
      } else {
        // 보드 목록 표시하기
        showNewPost();
      }
    } else {
      showLoginConfirm('1');
    }
  };

  const showNewPost = async () => {
    if (!validUser()) {
      showLoginConfirm('9');
    } else {
      // const params = await getPrimitiveParams();
      if (typeof board !== 'undefined' && board?.channel?.id === myChannel?.id) {
        if (board?.boardType !== 'LINK') {
          navigate(`/post/${board?.id}/new?posttype=2&channelid=${myChannel?.id}`);
        } else {
          navigate(`/link/${board?.id}/new?posttype=2&channelid=${myChannel?.id}`);
        }
      } else {
        uiStore.bottomSheet.show({
          children: (
            <NewPostSelector channelName={channelName || ''} channelId={myChannel?.id || 0} />
          ),
        });
      }
    }
  };

  const showLoginConfirm = (referrer: string, params?: string) => {
    uiStore.confirm.show({
      message: t('A245'),
      onConfirmed: async () => {
        navigate(`/login?action=(${referrer}.${params})`);
      },
    });
  };

  const handleClickFollow = () => {
    const result = validUser();

    if (result) {
      uiStore.universalModal.show({
        children: <FollowListContainer />,
        useHeader: false,
        bottomSheetOptions: { blocking: true },
        modalOption: { style: { maxWidth: 500, maxHeight: 500 } },
      });
    } else {
      showLoginConfirm('3');
    }
  };

  const handleClickMyChannel = () => {
    if (validUser()) {
      // navigate(`/${myChannelStore.currentChannel?.channelName}`);
      navigate(`/my`);
    } else {
      showLoginConfirm('4');
    }
  };

  const handleAction = async (action: string) => {
    const params = searchParam.get('params');
    switch (action) {
      case '1':
        handleClickAddPost();
        break;
      case '2':
        handleClickAlarm();
        break;
      case '3':
        handleClickFollow();
        break;
      case '4':
        navigate(`/${myChannel?.channelName}`);
        break;
      case '5': // follow channel
        const channelName = searchParam.get('channelname');
        if (channelName) {
          await follow(channelName, false);
          // updateFollowStatus(true, channelName);
        }
        break;
      case '6':
        if (params !== null) {
          const adid = params.split('=')[1];
          const adId = Number.parseInt(adid);
          if (!Number.isNaN(adId)) {
            const ad = (await adStore.getAd(adId, {
              ownChannelId: myChannel?.id as number,
            })) as never as AdDto;
            if (ad.isInterest) {
              toast('이미 찜된 광고 입니다.');
            } else {
              handleClickFavorite(ad, !ad.isInterest);
            }
          } else {
            alert('광고 정보가 바르지 않습니다. 다시 시도해 주세요.');
          }
        }
        break;
      case '7': // Report
        if (params !== null) {
          const adid = params.split('=')[1];
          const adId = Number.parseInt(adid);

          if (!Number.isNaN(adId)) {
            report(adId);
          }
        }
        break;
      default:
        // showPreview(action);
        break;
    }
    // window.history.replaceState('', '', '/');
  };

  const handleClickFavorite = async (adItem: AdDto, isFavorite: boolean) => {
    const result = validUser();
    if (result) {
    } else {
      showLoginConfirm('6', `adid=${adItem.id}`);
    }
  };

  const getCounts = async () => {
    const counts = await getUnreadCounts();
    setInquiryCount(counts);
  };

  useLayoutEffect(() => {
    const action = searchParam.get('action');
    if (action) {
      handleAction(action);
    }
  }, [location]);

  useEffect(() => {
    if (myChannel) {
      getCounts();
    }
  }, [myChannel]);

  useEffect(() => {
    if (unreadCount) {
      setInquiryCount(unreadCount.inquiry);
    }
  }, [unreadCount]);

  if (window.isWebView) {
    return <></>;
  }
  return (
    <>
      <GNBBottomStyle>
        <div
          onClick={() => {
            reset('/cstown/deals');
          }}
        >
          <MenuFontStyle>
            <img src="/images/home.svg" alt="" />
            공구목록
          </MenuFontStyle>
        </div>
        <div
          onClick={() => {
            reset('/cstown/inquiries');
          }}
        >
          <MenuFontStyle>
            <Badge
              badgeContent={inquiryCount}
              sx={{
                '& .MuiBadge-badge': {
                  background: '#173300',
                  color: '#96e06e',
                  fontWeight: 600,
                  marginTop: '5px',
                },
              }}
            >
              <SmsOutlinedIcon />
            </Badge>
            <div style={{ fontSize: 10 }}>내 문의목록</div>
          </MenuFontStyle>
        </div>
        <div
          onClick={() => {
            reset('/cstown/checklist');
          }}
        >
          <MenuFontStyle>
            <ChecklistIcon />
            체크리스트
          </MenuFontStyle>
        </div>
        {myChannel?.channelType === 'SELLER' ? (
          <div
            onClick={() => {
              reset('/cstown/partners');
            }}
          >
            <MenuFontStyle>
              <PersonAddAltOutlinedIcon />
              파트너관리
            </MenuFontStyle>
          </div>
        ) : (
          <div
            onClick={() => {
              reset('/cstown/managers');
            }}
          >
            <MenuFontStyle>
              <PersonAddAltOutlinedIcon />
              매니저관리
            </MenuFontStyle>
          </div>
        )}
        <div onClick={handleClickMyChannel}>
          <MenuFontStyle disabled={!isLoggedIn}>
            <MyImage
              style={{
                fill: '#173300',
                width: 25,
                height: 25,
              }}
            />
            MY
          </MenuFontStyle>
        </div>
      </GNBBottomStyle>
    </>
  );
};

const GNBBottomStyle = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  gap: 20px;
  max-width: 550px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e6e6e6;
  background: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin: auto;
  > div {
    cursor: pointer;
    width: 150px;
  }

  @media ${(props) => props.theme.media.mobile} {
    border-radius: 0;
  }
`;

const NewButtonStyle = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  background: #23ba50;
  border-radius: 20px;
  padding: 5px 13px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const MenuFontStyle = styled.div<{ disabled?: boolean }>`
  font-size: 10px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const CSButtonStyle = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #173300;
  border-radius: 150px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
`;

export default CSTownNavigationTabBar;
