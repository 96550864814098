import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { MyDealState } from 'types/CommonTypes';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';

import { useParams } from 'react-router-dom';
import * as Containers from '../containers';
import CSTownDealsController from './pageControllers/CSTownDealsController';

const CSTownDealsPage = () => {
  const { stateModel, setPageState, receiver } = useStateContext<MyDealState>();
  const { myChannel } = useRootContext();
  const { getDeals, setDealsByType } = CSTownDealsController();
  const { dealid } = useParams<{ dealid: string }>();

  const reset = async () => {
    await getDeals();
    setDealsByType(1);
  };

  const getDealList = async () => {
    await getDeals();
  };

  useEffect(() => {
    if (stateModel?.reset?.result === true) {
      reset();
      setPageState('reset', { result: false });
    }
  }, [stateModel?.reset]);

  useEffect(() => {
    if (receiver && receiver?.id === 'reload' && typeof receiver?.param === 'undefined') {
      reset();
    }
  }, [receiver]);

  useEffect(() => {
    if (stateModel?.deals) {
      // setDealsByType(selectedTabRef.current || '진행중');
      if (dealid) {
        const dealId = Number.parseInt(dealid, 10);
        if (dealId > 0) {
          setPageState('selectedDeal', stateModel?.deals.find((deal) => deal.id === dealId));
        }
      }
    }
  }, [stateModel?.deals]);

  useEffect(() => {
    if (stateModel?.selectedDeal) {
      setPageState('selectedDeal', undefined);
    }
  }, []);

  useEffect(() => {
    if (myChannel) {
      getDealList();
    }
  }, [myChannel]);

  useEffect(() => {
    if (stateModel?.originalDeals) {
      setDealsByType(1);
    }
  }, [stateModel?.originalDeals]);

  return (
    <>
      {isMobile ? (
        <Containers.MobileContainers.MobileDealListContainer deals={stateModel?.deals} />
      ) : (
        <Containers.PCContainers.CstownDealsContainer deals={stateModel?.deals} />
      )}
    </>
  );
};

export default CSTownDealsPage;
