import CreditCardIcon from '@mui/icons-material/CreditCard';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LayersIcon from '@mui/icons-material/Layers';
import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';

type MenuItem = {
  isGroup?: boolean;
  title?: string;
  name?: string;
  Icon?: React.ElementType;
  path?: string;
};

const menuItems: MenuItem[] = [
  {
    title: '내 구독정보',
    name: 'subscriptions',
    Icon: CreditCardIcon,
    path: '/my/subscriptions',
  },
  {
    title: '상품 문의 목록',
    name: 'inquiry',
    Icon: ChatOutlinedIcon,
    path: '/my/deals/inquiry',
  },
  {
    title: '제안 목록',
    name: 'business-proposal',
    Icon: EditNoteOutlinedIcon,
    path: '/my/business-proposal',
  },
  {
    title: '채널 문의목록',
    name: 'proposal',
    Icon: AssignmentOutlinedIcon,
    path: '/my/proposal',
  },
];

const managementMenuItems: MenuItem[] = [
  {
    title: '페이지',
    name: 'categories',
    Icon: LayersIcon,
    path: '/management/categories',
  },
  {
    title: '프로필',
    name: 'profile',
    Icon: PhotoCameraFrontOutlinedIcon,
    path: '/management/profile',
  },
  {
    title: '마이샵',
    name: 'shop',
    Icon: StorefrontIcon,
    path: '/management/shop',
  },
  {
    title: '디자인',
    name: 'design',
    Icon: BrushOutlinedIcon,
    path: '/management/design',
  },
  {
    title: '배경',
    name: 'background',
    Icon: BrokenImageOutlinedIcon,
    path: '/management/background',
  },
];

export { menuItems, managementMenuItems, MenuItem };
