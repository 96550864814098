/* eslint-disable no-await-in-loop */
import { useRef } from 'react';
import { MetaData, User } from '@sendbird/chat';

import {
  GroupChannelCreateParams,
  MessageCollectionInitPolicy,
  MessageFilter,
  GroupChannel,
  GroupChannelHandler,
} from '@sendbird/chat/groupChannel';
import { ChannelDto, LinkDto } from 'services/data-contracts';
import ChatStore from 'stores/ChatStore';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChat } from 'libs/hooks/useChat';
import { AxiosResponse } from 'axios';
import { ChannelQueryType, LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { ReplyType } from '@sendbird/chat/message';
import { isGuid } from 'libs/helper/utils';

const chatController = () => {
  const chatStore = new ChatStore();
  const channelCollectionRef = useRef<any>();

  const { myChannel } = useRootContext();
  const { sendbirdChatRef, initChat } = useChat();

  /**
   * @description 사용자를 체크하여 없으면 생성한다.
   * @param userId
   * @param nickname
   * @param profileUrl
   */
  const setupUsers = async (users: ChannelDto[]) => {
    for (let index = 0; index < users.length; index++) {
      const result = await chatStore.getUser(users[index].channelName as string);
      if ((result?.status as number) > 299) {
        await createSendbirdUser(users[index]);
      } else {
        const isGuest = isGuid(users[index].channelName || '');
        const userUpdateParams: Partial<User> = {
          userId: users[index].channelName,
          nickname: isGuest ? 'Guest' : users[index].profile?.nickname || users[index].channelName,
          profileUrl:
            users[index].profile?.profileImage?.publicUrl ||
            'https://ohmoss.com/images/ohmossc.png',
        };

        if (sendbirdChatRef.current.currentUser.userId === users[index].channelName) {
          await sendbirdChatRef.current.updateCurrentUserInfo(userUpdateParams);
        }
      }
    }
  };

  const createSendbirdUser = async (channelInfo: ChannelDto) => {
    const response = (await chatStore.createUser(channelInfo)) as AxiosResponse<any, any>;
  };

  const getUnreadCounts = async () => {
    const count = sendbirdChatRef.current.groupChannel.getTotalUnreadMessageCount({
      // channelCustomTypesFilter: [`userInquiry-${myChannel?.id}`],
      // 셀러나 브랜드가 다른곳에 문의를 하더라도 카운팅됨(센드버드 구조 문제)
      channelCustomTypesFilter: [`userInquiry`],
    });
    return count?.data?.unread_count;
  };

  /**
   * @description 셀러/브랜드간 채팅
   * @param invitors
   * @param invided
   * @param title
   * @returns
   */
  const createChatroom = async (invitors: ChannelDto[], invited: ChannelDto[]) => {
    try {
      await setupUsers([...invitors, ...invited]);

      const chatOperators = invitors.map((operator) => operator.channelName as string);
      const chatInvited = invited.map((operator) => operator.channelName as string);

      const groupChannelParams: GroupChannelCreateParams = {};
      groupChannelParams.invitedUserIds = [...chatOperators, ...chatInvited];
      groupChannelParams.operatorUserIds = [invitors[0].channelName as string];

      groupChannelParams.isDistinct = true;
      groupChannelParams.customType = 'member';
      const groupChannel =
        await sendbirdChatRef.current.groupChannel.createChannel(groupChannelParams);

      return [groupChannel, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * @description 채널 생성(고객 문의)
   * @param userIdsToInvite 초대된 사람들
   * @param channelName 채팅방 이름(공구 제목)
   * @param ownerId 채널 관리자
   * @returns
   */
  const createInquiryChannel = async (
    operators: ChannelDto[],
    deal: LinkDto,
    members: ChannelDto[],
    roomCustomData?: string,
    guestId?: string,
  ) => {
    try {
      await setupUsers(operators);
      let sendbirdTemp = sendbirdChatRef.current;

      if (typeof sendbirdTemp === 'undefined') {
        sendbirdTemp = await initChat(myChannel?.channelName as string);
      }
      const chatOperators = operators.map((operator) => operator.channelName as string);
      let recentChannel;
      if (guestId) {
        recentChannel = await getChannel(
          `inquiry-${guestId}-${roomCustomData}-${deal?.channel?.id}`,
        );
      } else {
        recentChannel = await getChannel(
          `inquiry-${myChannel?.id}-${roomCustomData}-${deal?.channel?.id}`,
        );
      }

      if (recentChannel) {
        const recentUserIds = recentChannel.members.map((member) => member.userId);
        const notInRecent = members.filter(
          (member) => !recentUserIds.includes(member.channelName as string),
        );

        if (notInRecent.length > 0) {
          recentChannel.inviteWithUserIds(
            notInRecent.map((member) => member.channelName as string),
          );
        }
        return [recentChannel, null];
      }

      const groupChannelParams: GroupChannelCreateParams = {};
      groupChannelParams.invitedUserIds = members.map((member) => member.channelName as string);
      groupChannelParams.operatorUserIds = chatOperators;

      groupChannelParams.coverUrl = deal?.thumbnail?.publicUrl;
      groupChannelParams.isDistinct = false;
      groupChannelParams.name = deal?.title;
      if (guestId) {
        groupChannelParams.channelUrl = `inquiry-${guestId}-${roomCustomData}-${deal?.channel?.id}`;
      } else {
        groupChannelParams.channelUrl = `inquiry-${myChannel?.id}-${roomCustomData}-${deal?.channel?.id}`;
      }
      groupChannelParams.customType = `userInquiry-${deal?.channel?.id?.toString()}`;
      // groupChannelParams.data = sellerChannelName;
      groupChannelParams.data = deal?.channel?.channelName as string;
      const groupChannel = await sendbirdTemp.groupChannel.createChannel(groupChannelParams);

      const data: MetaData = {
        itemKey: roomCustomData || '',
        chatType: 'userInquiry',
        owner: myChannel?.id ? myChannel.id.toString() : guestId || '',
        dealChannelName: deal?.channel?.channelName as string,
        dealOwner: deal?.channel?.id?.toString() || '',
      };
      await groupChannel.createMetaData(data);

      return [groupChannel, null];
    } catch (error) {
      return [null, error];
    }
  };

  const inviteOrDeleteUser = async (deals?: LinkDtoWithAssignedDeal[], channelNames?: string[]) => {
    const assignedDelKeys = deals
      ?.filter((item) => item.assigned)
      .map((item) => item.linkKey) as string[];

    const releaseddDelKeys = deals
      ?.filter((item) => !item.assigned)
      .map((item) => item.linkKey) as string[];

    if (assignedDelKeys.length > 0) {
      createInvitations(assignedDelKeys, channelNames as string[]);
    }
    if (releaseddDelKeys.length > 0) {
      removeChannelsMember(releaseddDelKeys, channelNames as string[]);
    }
  };

  /**
   * @description 셀러가 브랜드를 초대할때, 브랜드가 멤버를 초대할때 채널에 초대한다.
   * @param dealKeys
   * @param channelName
   */
  const createInvitations = async (dealKeys: string[], channelNames: string[]) => {
    channelNames.forEach(async (channelName) => {
      const user = await getUser(channelName);
      if (user === false) {
        createSendbirdUser({ channelName });
      }
    });

    const query = sendbirdChatRef.current.groupChannel.createMyGroupChannelListQuery();
    query.metadataKey = 'itemKey';
    query.metadataValues = dealKeys;
    query.includeEmpty = true;
    const channels: GroupChannel[] = await query.next();

    const channelNamesSet = new Set<string>(channelNames);
    channels.forEach((channel) => {
      if (channel.members.some((member) => channelNamesSet.has(member.userId))) {
        return;
      }
      chatStore.inviteUsers(channel.url, channelNames);
    });
  };

  /**
   * @description 채널 초대를 수락한다.
   * @param dealKeys
   */
  const acceptChannelInvitation = async (dealKeys?: string[]) => {
    const query = sendbirdChatRef.current.groupChannel.createMyGroupChannelListQuery();
    if (dealKeys) {
      query.metadataKey = 'itemKey';
      query.metadataValues = dealKeys;
    }
    query.includeEmpty = true;
    const channels: GroupChannel[] = await query.next();
    channels?.forEach((channel) => {
      channel.acceptInvitation();
    });
  };

  /**
   * @description 채널로 부터 사용자(브랜드, 멤버)를 퇴장시킨다.
   * @param dealKeys
   * @param channelName
   */
  const removeChannelsMember = async (dealKeys: string[], channelNames: string[]) => {
    const listQuery = sendbirdChatRef.current.groupChannel.createMyGroupChannelListQuery();

    listQuery.includeEmpty = true;
    listQuery.includeMetaData = true;
    listQuery.memberStateFilter = 'all';
    listQuery.order = 'latest_last_message';
    listQuery.metadataKey = 'itemKey';
    listQuery.metadataValues = dealKeys;

    if (listQuery.hasNext) {
      const channels: GroupChannel[] = await listQuery.next();

      for (const channel of channels) {
        for (const channelName of channelNames) {
          try {
            await channel.banUserWithUserId(channelName);
            await channel.unbanUserWithUserId(channelName);
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
  };

  /**
   * @description 초대를 수락한 딜중에, 초대장이 남아있는 채널을 수락한다.
   */
  const acceptIncommingInvitations = async (deals: string[]) => {
    const query = sendbirdChatRef.current.groupChannel.createMyGroupChannelListQuery();
    query.includeEmpty = true;
    query.includeMetaData = true;
    query.metadataKey = 'itemKey';
    query.metadataValues = deals;
    query.memberStateFilter = 'invited_only';
    query.order = 'latest_last_message';
    if (query.hasNext) {
      const channels: GroupChannel[] = await query.next();
      channels.forEach((channel) => {
        channel.acceptInvitation();
      });
    }
  };

  const loadChannels = async (handlers: GroupChannelHandler, query: ChannelQueryType) => {
    let sendbirdChatTemp = sendbirdChatRef.current;
    if (sendbirdChatTemp === null) {
      sendbirdChatTemp = await initChat(myChannel?.channelName as string);
    }

    sendbirdChatTemp.groupChannel.addGroupChannelHandler(
      myChannel?.channelName as string,
      handlers,
    );

    const listQuery = sendbirdChatTemp.groupChannel.createMyGroupChannelListQuery();

    switch (query.channelType) {
      // 위임된 브랜드, 멤버용
      case 'DELEGATED':
        listQuery.userIdsFilter = {
          userIds: [myChannel?.channelName as string],
          includeMode: true,
          queryType: 'AND',
        };

        // listQuery.customTypesFilter = [`userInquiry`];
        listQuery.customTypeStartsWithFilter = 'userInquiry';

        break;
      // 특정 아이템 취득용(오너 및 브랜드, 멤버 모두 해당)
      case 'ITEM':
        listQuery.metadataKey = query.metaKey;
        listQuery.metadataValues = query.metaValues;
        break;
      // 멤버간 채팅용
      case 'MEMBER':
        listQuery.customTypesFilter = ['member'];
        break;
      // 셀러용
      case 'OWNER':
        // listQuery.userIdsFilter = {
        //   userIds: [myChannel?.channelName as string],
        //   includeMode: true,
        //   queryType: 'AND',
        // };
        listQuery.customTypesFilter = [`userInquiry-${myChannel?.id}`];
        listQuery.metadataKey = 'dealOwner';
        listQuery.metadataValues = query.metaValues;
        break;
      // 고객용
      case 'INQUIRY':
        listQuery.metadataKey = query.metaKey;
        listQuery.metadataValues = query.metaValues;
        // listQuery.customTypesFilter = ['userInquiry'];
        listQuery.customTypeStartsWithFilter = 'userInquiry';

        break;
      default:
        break;
    }

    listQuery.includeEmpty = false;
    listQuery.includeMetaData = true;
    listQuery.order = 'latest_last_message';
    listQuery.limit = 60;
    listQuery.myMemberStateFilter = 'joined_only';

    if (listQuery.hasNext) {
      const results = await listQuery.next();
      // try {
      //   collection.setGroupChannelCollectionHandler(handlers);
      // } catch (e) {
      //   const t = e;
      // }
      // if (collection.hasMore) {
      //   const temp = await collection.loadMore();
      //   const a = temp;
      //   return [temp, ''];
      // }
      // Owner일 경우, userInquiry 채널은 제외(내가 다른 셀러에게 상품 문의한것이므로)
      if (query.channelType === 'OWNER') {
        const filteredResults = results.filter((item: any) => {
          return item.customType === 'userInquiry';
        });
        return [results, ''];
      } else {
        return [results, ''];
      }
    } else {
      return [[], ''];
    }
  };

  const getChannel = async (channelUrl: string) => {
    try {
      const channel: GroupChannel =
        await sendbirdChatRef.current.groupChannel.getChannel(channelUrl);
      return channel;
    } catch (e) {
      return null;
    }
  };

  const deleteChannel = async (channelUrl: string) => {
    const channel: GroupChannel = await sendbirdChatRef.current.groupChannel.getChannel(channelUrl);
    await channel.delete();
  };

  const sendMessage = async (channel: GroupChannel, msg: string) => {
    const userMessageParams = {
      message: msg,
    };
    channel
      .sendUserMessage(userMessageParams)
      .onSucceeded((message: unknown) => {})
      .onFailed((error) => {});
  };

  const sendFile = async (channel: GroupChannel, files: File[]) => {
    const fileMessageParams = { file: files[0] };
    channel
      .sendFileMessage(fileMessageParams)
      .onSucceeded((message) => {})
      .onFailed((error) => {});
  };

  const loadMessages = (
    channel: GroupChannel,
    messageHandlers: any,
    onCacheResult: any,
    onApiResult: any,
    filters: string[],
  ) => {
    if (channel === null) {
      return;
    }
    const messageFilter = new MessageFilter();

    messageFilter.replyType = ReplyType.ALL;
    const collection = channel.createMessageCollection({
      filter: messageFilter,
      startingPoint: Date.now(),
      limit: 100,
    });

    collection.setMessageCollectionHandler(messageHandlers);
    collection
      .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
      .onCacheResult(onCacheResult)
      .onApiResult(onApiResult);

    return collection;
  };

  const getUser = async (channelName: string) => {
    const result = await chatStore.getUser(channelName as string);
    return result !== null;
  };

  const searchMessages = async (keyword: string) => {
    const params: any = {
      keyword: keyword,
      channelUrl: '',
      channelCustomType: '',
      limit: 20,
      exactMatch: false,
      messageTimestampFrom: null,
      messageTimestampTo: null,
      order: 'score',
      reverse: false,
    };
    const query = await sendbirdChatRef.current.createMessageSearchQuery(params);
    const messages = await query.next();
    const t = messages;
  };

  const disposeChannels = () => {
    if (channelCollectionRef.current) {
      channelCollectionRef.current.dispose();
    }
  };

  return {
    loadMessages,
    createInquiryChannel,
    getUnreadCounts,
    createChatroom,
    loadChannels,
    deleteChannel,
    getChannel,
    sendMessage,
    sendFile,
    getUser,
    inviteOrDeleteUser,
    disposeChannels,
    acceptChannelInvitation,
    acceptIncommingInvitations,
    searchMessages,
  };
};

export default chatController;
