import React, {
  CSSProperties,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import OverlayStyle from 'resources/controls/OverlayStyle';
import { IconButton } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import { OhmossButton } from 'resources/controls/OhmossButton';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import DehazeIcon from '@mui/icons-material/Dehaze';
import useNavigation from 'hooks/useNavigation';
import useLocalParams from 'libs/hooks/useLocalParams';
import ChannelSearchBox from 'components/commons/ChannelSearchBox';
import useWebViewPrevUrl from 'hooks/useWebViewPrevUrl';
import MainSwitchButton from './MainSwitchButton';
import MobileBackButton from './MobileBackButton';
import { useRootContext } from 'libs/hooks/useRootContext';
import useDetectScroll from '@smakss/react-scroll-direction';
import { Row } from 'components/commons/layouts';

const GnbComponent = observer(
  forwardRef(
    (
      {
        position,
        defaultComponent,
        useCommonButtons,
        current,
        style,
        children,
      }: {
        position?: 'top' | 'bottom';
        defaultComponent: ReactNode;
        useCommonButtons?: boolean;
        current?: 'MAG' | 'LINK';
        style?: CSSProperties;
        children?: React.ReactElement;
      },
      ref,
    ) => {
      const prevUrl = useWebViewPrevUrl();
      const { navigate, reset } = useNavigation();
      const { scrollDir, scrollPosition } = useDetectScroll();

      const { uiStore, myChannelStore } = useStore();
      const { channelName } = useLocalParams();
      const { myChannel } = useRootContext();

      const [isScroll, setIsScroll] = useState<boolean>(false);
      const [bottom, setBottom] = useState<number>(0);
      const [showMenu, setShowMenu] = useState<boolean>(false);

      const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setShowMenu((prev) => !prev);
      };

      useImperativeHandle(ref, () => ({
        close,
      }));

      const close = () => {
        setShowMenu(false);
      };

      const handleClickSearch = () => {
        uiStore.modal.show({
          title: t('A235'),
          style: { maxHeight: 500 },
          description: t('A236'),
          children: (
            <>
              <ChannelSearchBox />
            </>
          ),
        });
      };

      useEffect(() => {
        const t = myChannelStore.currentChannel;
      }, [myChannelStore.currentChannel]);

      useEffect(() => {
        if (scrollDir === 'down' && scrollPosition.top > 50) {
          setIsScroll(true);
        } else if (scrollDir === 'up' && scrollPosition.top < 50) {
          setIsScroll(false);
        }
      }, [scrollPosition]);

      return (
        <>
          <UserGNBStyle isScroll={isScroll} location={position} bottom={bottom}>
            <DefaultComponentStyle className="">
              <MobileBackButton containerStyle={{ paddingLeft: 16 }} />
              {!channelName && !prevUrl && (
                <img
                  src="./images/oh_logo.svg"
                  width="60"
                  style={{ fill: 'var(--primary-color)', marginLeft: 20 }}
                  onClick={() => {
                    reset('/');
                  }}
                />
              )}
              {defaultComponent}
              <Row style={{ gap: 5, width: 'unset' }}>
                <>
                  {myChannel?.channelName === channelName && (
                    <OhmossButton
                      style={{
                        maxWidth: 60,
                        fontSize: 12,
                        height: 30,
                        borderRadius: 30,
                        color: '#fff',
                        fontWeight: 500,
                        background: 'var(--primary-color)',
                      }}
                      onClick={() => {
                        navigate('/management');
                      }}
                    >
                      EDIT
                    </OhmossButton>
                  )}
                </>
                <MainSwitchButton current={current} />
                <div style={{}}>
                  {useCommonButtons !== false && (
                    <IconButtonStyle
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleClickSearch();
                      }}
                    >
                      <SearchIcon style={{ color: 'var(--primary-color)' }} />
                    </IconButtonStyle>
                  )}
                </div>
                <IconStyle
                  style={{ minWidth: 40 }}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    handleClickOpenMenu(e);
                    setShowMenu(!showMenu);
                  }}
                >
                  <DehazeIcon style={{ color: 'var(--primary-color)', width: 60, height: 30 }} />
                </IconStyle>
              </Row>
            </DefaultComponentStyle>
            <InnerGnbStyle show={showMenu} position={'top'} style={{ top: -10 }}>
              {showMenu && <div style={{ paddingBottom: 20 }}>{children}</div>}
            </InnerGnbStyle>
          </UserGNBStyle>
          <OverlayStyle
            isShow={showMenu}
            onClick={() => {
              setShowMenu(false);
            }}
          />
        </>
      );
    },
  ),
);

const IconButtonStyle = styled(IconButton)`
  width: 50px;
  @media ${(props) => props.theme.media.mobile} {
    margin-right: 5px !important;
  }
`;
const UserGNBStyle = styled.div<{
  isScroll: boolean;
  location?: 'top' | 'bottom';
  height?: number;
  bottom?: number;
}>`
  max-width: ${(props) => (props.isScroll ? '550px' : '1250px')};
  top: 10px;
  /** 낮추지 말것 */
  z-index: 2;
  position: sticky;
  position: fixed;
  background: rgba(255, 255, 255, 0.3);
  left: 0px;
  right: 0px;
  height: 60px;
  margin: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);

  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  /* overflow: hidden; */

  @media ${(props) => props.theme.media.mobile} {
    position: fixed;
    top: 0px;
    box-shadow: none;
    max-width: 100%;
  }
`;

const DefaultComponentStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  z-index: 10000011;
  /* background: #173300; */
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  @media ${(props) => props.theme.media.mobile} {
    border-radius: 0px;
  }
`;

const InnerGnbStyle = styled.div<{ show: boolean; position?: 'top' | 'bottom' }>`
  background: ${(props) => (props.show ? '#fff' : 'rgba(255, 255, 255, 0)')};
  position: absolute;
  pointer-events: ${(props) => (props.show ? 'box-only' : 'none')};
  left: 0;
  right: 0;
  top: 0px;
  height: auto;
  right: 10px;
  border-radius: 30px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  top: 0;
  max-width: 100%;
  width: 100%;
  margin: auto;
  max-width: 550px;
  z-index: 1;
`;

const IconStyle = styled.div`
  cursor: pointer;
  margin-right: 10px;
  padding: 0 3px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease;
  }
`;

const HeaderImg = styled.img``;

export default GnbComponent;
