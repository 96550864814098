import SearchBox from 'components/commons/SearchBox';
import DealInfo from 'features/myPage/components/DealInfo';
import React, { useRef, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { SimpleDealDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

const DealListSelector = ({
  deals,
  onClick,
}: {
  deals?: SimpleDealDto[];
  onClick?: (deal: LinkDtoWithAssignedDeal) => void;
}) => {
  const { uiStore } = useStore();
  const originSearch = useRef<SimpleDealDto[]>([]);
  const [search, setSearch] = useState<SimpleDealDto[]>([]);
  const [selectedDeal, setSelectedDeal] = useState<SimpleDealDto>();

  const handleSearch = (value: string) => {
    if (value === '') {
      setSearch(originSearch.current);
    } else if (deals) {
      const result = deals?.filter((search) => {
        return (
          search?.title?.includes(value) || search?.sellerChannel?.channelName?.includes(value)
        );
      });
      setSearch(result);
    }
  };

  const handleClickDeal = (deal: LinkDtoWithAssignedDeal) => {
    setSelectedDeal(deal);
  };

  const handleClickConfirm = () => {
    uiStore.modal.confirm(selectedDeal);
  };

  return (
    <SelectorContainer>
      <SearchBox onEntered={handleSearch} onCancel={() => handleSearch('')} />
      <div style={{ marginTop: 20 }} />
      {search?.length === 0 &&
        deals?.map((deal) => (
          <SearchResultBoxStyle selected={deal?.id === selectedDeal?.id}>
            <DealInfo
              onClick={handleClickDeal}
              useOnlyTitle
              item={deal as LinkDtoWithAssignedDeal}
              useSimpleInfo
            />
          </SearchResultBoxStyle>
        ))}
      {search?.length > 0 &&
        search?.map((deal) => (
          <SearchResultBoxStyle>
            <DealInfo
              onClick={handleClickDeal}
              useOnlyTitle
              item={deal as LinkDtoWithAssignedDeal}
              useSimpleInfo
            />
          </SearchResultBoxStyle>
        ))}
      <BtnContainer>
        <OhmossButton onClick={handleClickConfirm}>확인</OhmossButton>
      </BtnContainer>
    </SelectorContainer>
  );
};

const SearchResultBoxStyle = styled.div<{ selected?: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0 10px 0;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  background-color: ${(props) => (props.selected ? '#f5f5f5' : 'white')};
`;

const SelectorContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  margin-top: 20px;
`;

const BtnContainer = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100%;
`;

export default DealListSelector;
