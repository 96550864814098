import styled from 'styled-components';
import React, { CSSProperties } from 'react';
import { Row } from 'components/commons/layouts';
import { ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import StyledRefBox from 'components/commons/layouts/StyledRefBox';

export const ListBoxWrapper = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  display: flex;
`;

export const ListBoxTemplate = styled.div`
  max-width: 450px;
  width: auto;
  min-width: 300px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  height: calc(100vh - var(--gnb-height));
  width: 100%;
  /* background: #f3f8fb; */
  padding: 10px;
  @media ${(props) => props.theme.media.mobile} {
    max-width: 100%;
  }
`;

export const ListBoxContentsTemplate = styled.div`
  width: 100%;
  /* height: calc(100vh - var(--gnb-height)); */
  overflow: auto;
`;

export const ListItemTemplate = styled.div<{ selected?: boolean }>`
  width: 100%;
  background: ${(props) => (props?.selected ? 'white' : 'transparent')};
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
`;

export const ListBoxItemTemplate = styled.div<{ on?: boolean }>`
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  min-width: 25%;
  margin-bottom: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border: ${(props) => (props.on ? '2px solid var(--primary-color)' : '2px solid transparent')};
`;

export const ListBoxItem = ({
  children,
  title,
  action,
  style,
  onClick,
}: {
  children?: React.ReactChild;
  title?: React.ReactChild;
  action?: React.ReactChild;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <>
      <ListBoxItemTemplate
        style={{ ...style, flex: 1 }}
        onClick={() => {
          onClick?.();
        }}
      >
        {title && (
          <Row style={{ height: 40, justifyContent: 'space-between' }}>
            <ItemSectionTitle>{title}</ItemSectionTitle>
            <>{action}</>
          </Row>
        )}
        {children && children}
      </ListBoxItemTemplate>
    </>
  );
};

export const DetailHeader = styled.div`
  align-items: flex-start;
  right: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  /* position: fixed;
  top: 80px;
  z-index: 10; */
`;

export const NoContentStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--gnb-height));

  > div {
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    padding: 20px;
    background: #fff;
  }
`;

export const CountStyle = styled.div<{ type?: 'UPCOMING' | 'ONGOING' | 'ENDED' }>`
  ${(props) => {
    if (props.type === 'UPCOMING') {
      return {
        color: '#202d43',
        background: '#f9fafb',
        border: '2px solid #e8ebef',
      };
    } else if (props.type === 'ONGOING') {
      return {
        background: '#F4F3FF',
        border: '2px solid #D4D2FE',
        color: '#4D14D9',
      };
    } else if (props.type === 'ENDED') {
      return {
        background: '#ECFDF3',
        border: '2px solid #A7EEC3',
        color: '#0A7444',
      };
    } else {
      return {
        background: '#f9fafb',
        border: '2px solid #e8ebef',
        color: '#202d43',
      };
    }
  }}
  width:auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 20px;
  padding: 5px 10px;
`;

export const MenuIconBoxStyle = styled.div`
  background: #fff;
  padding: 5px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  > div {
    margin: 0 10px;
  }
`;

export const MenuBoxItemStyle = styled.div<{ selected?: boolean }>`
  display: flex;
  margin: 10px 0;
  padding: 5px;
  font-size: 14px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;

  /* border: ${(props) =>
    props.selected ? '2px solid var(--primary-color)' : '2px solid transparent'}; */
  box-shadow: ${(props) => (props.selected ? '0px 0px 10px rgba(0, 0, 0, 0.3)' : 'none')};

  background: ${(props) => (props.selected ? 'var(--primary-color)' : 'transparent')};
  color: ${(props) => (props.selected ? '#fff' : '#656565')};
`;

export const MenuBoxStyle = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const DealItemContainerStyle = styled.div`
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  border: 2px solid #ebebeb;
`;

export const DealItemServiceIconStyle = styled.div`
  background: #efefef;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 40px;
`;

export const CSTownMobileLayout = styled.div`
  padding: 10px;
  width: 100%;
  /* height: calc(100vh - 60px); */
  height: 100%;
  /* padding-top: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* > div:nth-child(2) {
    flex-grow: 1;
    overflow-y: auto;
  } */

  @media ${(props) => props.theme.media.mobile} {
    /* height: calc(100vh - 140px); */
  }
`;

export const BoxStyle = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  border-radius: 15px;
  background: #fff;
  margin: 10px;
  border: ${(props) => (props.selected ? '1px solid #c4c4c4' : '1px solid transparent')};
  box-shadow: ${(props) => (props.selected ? '0px 0px 20px rgba(0, 0, 0, 0.1)' : 'none')};
`;

export const SubMenuStyle = styled.div`
  background: #fff;
  display: flex;
  gap: 40px;
  padding: 0 20px;
  margin-bottom: 20px;
  /* border: 1px solid var(--border-color); */
  /* border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; */
`;
