import React from 'react';
import { Divider, MenuContainer } from 'features/myPage/components/CommonStyleComponent';
import MenuItem from 'components/commons/menuItem/MenuItem';
import useNavigation from 'hooks/useNavigation';
import CSTownInquiriesContainer from '../CSTownInquiriesContainer';

const InquiryList = ({ inquiries }: { inquiries?: any }) => {
  const { navigate } = useNavigation();
  return (
    <MenuContainer>
      <MenuItem
        title="전체 문의 보기"
        onClick={() => {
          navigate('/cstown/inquiries');
        }}
      />
      <Divider style={{ margin: '15px 0 0 0' }} />
      {inquiries?.length === 0 && (
        <div style={{ color: '#CACACA', padding: '10px 10px 0 0', fontWeight: '400' }}>
          문의가 없습니다.
        </div>
      )}
      {inquiries && inquiries?.length > 0 ? (
        <CSTownInquiriesContainer inquiries={inquiries} />
      ) : (
        <div style={{ color: '#CACACA', padding: '10px 10px 0 0', fontWeight: '400' }}>
          문의가 없습니다.
        </div>
      )}
    </MenuContainer>
  );
};
export default InquiryList;
