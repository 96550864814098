import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useSetDefaultValue from 'features/management/hooks/useDefaultValue';
import MUISwitch from '../buttons/MUISwitch';
import { Row } from '../layouts';

const MenuItemCheckBox = ({
  title,
  initialValue,
  onlySwitch,
  value,
  disabled = false,
  menuType = 'link',
  onChangeValue,
}: {
  title?: React.ReactNode | string;
  onlySwitch?: boolean;
  value?: boolean;
  disabled?: boolean;
  initialValue?: boolean;
  menuType?: 'link' | 'action' | 'danger' | 'contents';
  onChangeValue?: (result?: boolean) => void;
}) => {
  const [checkBoxValue, setValue] = useState<boolean>(false);

  useSetDefaultValue(setValue, initialValue, false);

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setValue(value);
    }
  }, [value]);

  return (
    <>
      {onlySwitch === true ? (
        <Row style={{ width: 'unset' }}>
          <TitleStyle style={{ marginRight: 10 }} type={menuType}>
            {title}
          </TitleStyle>
          <MUISwitch
            disabled={disabled}
            checked={checkBoxValue}
            onChange={(e: any, value: boolean) => {
              if (disabled === false) {
                setValue(value);
                if (onChangeValue) {
                  onChangeValue(value);
                }
              }
            }}
          />
        </Row>
      ) : (
        <ItemBoxStyle style={{ alignItems: 'center' }}>
          {title && <TitleStyle type={menuType}>{title}</TitleStyle>}
          <>
            <MUISwitch
              disabled={disabled}
              checked={checkBoxValue}
              onChange={(e: any, value: boolean) => {
                if (disabled === false) {
                  setValue(value);
                  if (onChangeValue) {
                    onChangeValue(value);
                  }
                }
              }}
            />
          </>
        </ItemBoxStyle>
      )}
    </>
  );
};

const ItemBoxStyle = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  color: #1a1a1a;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 11px 0px;
`;

const DangerType = css`
  color: #fe324b;
`;

const TitleStyle = styled.div<{ type?: string }>`
  margin-right: 10px;
  ${(props) => {
    if (props.type === 'danger') return DangerType;
  }}
`;

export default MenuItemCheckBox;
