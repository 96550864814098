import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import useNavigation from 'hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import useToken from 'libs/hooks/useToken';
import { Row } from 'components/commons/layouts';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';

import styled from 'styled-components';
import Resign from 'components/commons/Resign';
import useLogout from 'hooks/useLogout';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import useLocalParams from 'libs/hooks/useLocalParams';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import Text from 'components/commons/Text';
import { ListBoxItem } from 'features/csTown/commonComponents/styles';
import { Avatar } from '@mui/material';
import SettingProvider from 'features/mossMainSettings/context/settings/SettingProvider';

const AccountPage = observer(() => {
  const maxChannel = 2;
  const { t } = useTranslation();
  const { uiStore, myStore, myChannelStore, authStore } = useStore();
  const { withDrawal } = SettingProvider();

  const { myChannel } = useRootContext();
  const { channelName } = useLocalParams();
  const { validUser } = useToken();
  const { navigate } = useNavigation();

  const logout = useLogout();

  const handleCreateNewChannel = () => {
    if (myChannelStore.myChannels?.length < maxChannel) {
      navigate(`/${channelName}/newlinkchannel`);
    } else {
      toast.warn('채널은 최대 2개까지 생성 가능합니다.');
    }
  };

  useEffect(() => {
    if (validUser()) {
      myStore.getLoginInfo();
      myStore.getMe();
    }
  }, []);

  return (
    <>
      <ManagementLayout
        style={{
          justifyContent: 'flex-start',
        }}
      >
        <ListBoxItem title={t('User information')}>
          <ItemSectionStyle>
            <div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              <b>{myStore.loginInfo?.loginType}</b>로 가입:
            </div>
            <div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{myStore.loginInfo?.username}</div>
          </ItemSectionStyle>
        </ListBoxItem>
        <ListBoxItem title={t('My channels')}>
          <>
            <Row style={{ flexDirection: 'column', gap: 10, marginTop: 20 }}>
              {myChannelStore?.myChannels?.map((channel, index) => (
                <ChannelItem key={index}>
                  <Avatar src={channel?.profile?.profileImage?.publicUrl} />
                  <div style={{ textAlign: 'left' }}>
                    <Text type="H4">{channel?.channelName}</Text>
                    <Text type="H5" buttonRole="DESCRIPTION">
                      {channel?.channelType}
                    </Text>
                  </div>
                  {channel?.id === myChannel?.id && (
                    <div style={{ position: 'absolute', right: 42 }}>
                      <img src="/images/green_check.svg" alt="" />
                    </div>
                  )}
                </ChannelItem>
              ))}
            </Row>
            {myChannelStore.myChannels?.length < maxChannel && (
              <div style={{ marginTop: 20 }}>
                <OhmossButton
                  onClick={() => {
                    handleCreateNewChannel();
                  }}
                >
                  새 채널
                </OhmossButton>
              </div>
            )}
          </>
        </ListBoxItem>
        <ListBoxItem title="계정">
          <Row style={{ justifyContent: 'flex-start', gap: 10, marginTop: 10 }}>
            <OhmossButton type="cancel" onClick={logout}>
              {t('Logout')}
            </OhmossButton>
            <OhmossButton
              type="simple"
              onClick={() => {
                navigate('/forgot-password');
              }}
            >
              {t('Change password')}
            </OhmossButton>
          </Row>
        </ListBoxItem>
        <div style={{ textAlign: 'left', marginBottom: 50, padding: 20 }}>
          <OhmossButton style={{ width: 100 }} type="danger" onClick={withDrawal}>
            {t('Withdrawal')}
          </OhmossButton>
        </div>
      </ManagementLayout>
    </>
  );
});

const ChannelItem = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 10px;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.01063rem;
  border-radius: 62.4375rem;
  border: 1.5px solid #173300;
  background: rgba(23, 51, 0, 0.1);
`;

export default AccountPage;
