import React, { useState } from 'react';
import NoContent from 'components/commons/NoContent';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import DealList from 'features/myPage/containers/DealList';
import { LinkDto } from 'services/data-contracts';
import CSTownDealsController from 'features/csTown/pages/pageControllers/CSTownDealsController';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';

const DealListContainer = ({ deals }: { deals?: LinkDto[] }) => {
  const { setDealsByType } = CSTownDealsController();
  const [selectedTab, setSelectedTab] = useState<string>('진행중');

  return (
    <div style={{}}>
      {/* <CommonTabButtons
        items={['진행중', '대기중', '완료됨']}
        onHandleTabClick={(tab: string) => {
          setDealsByType(tab);
          setSelectedTab(tab);
        }}
      /> */}
      <AnimatedTabButton
        onClick={(item: any) => {
          const key = Number(item.key);
          setDealsByType(key);
        }}
        style={{ width: '400px' }}
      >
        <TabItem key={1}>진행중</TabItem>
        <TabItem key={2}>대기중</TabItem>
        <TabItem key={3}>완료됨</TabItem>
      </AnimatedTabButton>
      <div>
        {typeof deals !== 'undefined' && deals?.length > 0 ? (
          <div style={{ padding: 20 }}>
            <DealList deals={deals} />
          </div>
        ) : (
          <NoContent title={`${selectedTab} 공구가`} />
        )}
      </div>
    </div>
  );
};

export default DealListContainer;
