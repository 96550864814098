import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import MobileModalHeader from './MobileModalHeader';
import { log } from 'console';
import { useMediaContext } from 'providers/useMediaContext';

const FullModal = observer(() => {
  const { uiStore } = useStore();
  const ScreenTypes = useMediaContext();
  const [zIndex, setZIndex] = useState(2);
  const [children, setChildren] = useState<React.ReactNode>();
  const [temp, setTemp] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null); // 타입을 명시적으로 정의

  const preventTouchMove = useCallback((e: TouchEvent) => {
    let node: EventTarget | null = e.target;

    while (node !== null && node !== document.body) {
      if (node instanceof HTMLElement && node.classList.contains('messsage-item')) {
        return;
      }
      node = (node as HTMLElement).parentNode;
    }
    e.preventDefault();
  }, []);

  useEffect(() => {
    setZIndex(uiStore.modal.modalShow ? 100 : 3);
    setChildren(uiStore.modal.options?.children);

    const { body } = document;
    if (uiStore.modal.modalShow) {
      setTemp(window.visualViewport?.height ?? 0);
      body.style.overflow = 'hidden';
      body.addEventListener('touchmove', preventTouchMove, { passive: false });
    } else {
      body.style.overflow = '';
      body.removeEventListener('touchmove', preventTouchMove);
    }

    return () => {
      // Cleanup 함수 추가
      body.style.overflow = '';
      body.removeEventListener('touchmove', preventTouchMove);
    };
  }, [uiStore.modal.modalShow, preventTouchMove]);

  useEffect(() => {
    if (uiStore.bottomSheet.modalShow || uiStore.universalModal.modalShow) {
      setZIndex(3);
    } else {
      setZIndex(100);
    }
    console.log('modalShow', uiStore.bottomSheet.modalShow);
  }, [uiStore.bottomSheet.modalShow, uiStore.universalModal.modalShow]);

  return (
    <ReactModal
      isOpen={uiStore.modal.modalShow}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex,
          display: 'flex',
          // height: temp,
          height: '100%',
        },
        content: {
          inset: 20,
          // width: ScreenTypes.isSmall ? 550 : 900,
          height: ScreenTypes.isLarge ? '80%' : '100%',
          width: '100%',
          position: 'unset',
          border: 0,
          maxWidth: 850,
          borderRadius: uiStore.modal.options?.modalType === 'page' ? 0 : 10,
          padding: 0,
          margin: 'auto',
          overflow: 'auto',
          left: uiStore.modal.options?.modalType === 'modal' ? 20 : 0,
          right: uiStore.modal.options?.modalType === 'modal' ? 20 : 0,
          ...uiStore.modal.options?.style,
        },
      }}
      ariaHideApp={false}
    >
      <ContentStyle id="ModalContentStyleBox">
        <HeaderStyle>
          <MobileModalHeader
            type={uiStore.modal.options?.modalType}
            title={uiStore.modal.options?.title || ''}
          />
        </HeaderStyle>
        <ContentBodyStyle
          id="fullModalContent"
          className="full-modal-content"
          ref={containerRef}
          type={uiStore.modal.options?.modalType}
        >
          {children}
        </ContentBodyStyle>
      </ContentStyle>
    </ReactModal>
  );
});

const pageStyle = css``;

const modalStyle = css`
  left: 20px;
  right: 20px;
  border-radius: 10px;
  /* max-height: 60%; */
`;

const HeaderStyle = styled.div`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
`;

const ContentStyle = styled.div`
  max-width: 100%;
  /* height: 100vh; */
  /* max-height: 90vh; */
  overflow-y: auto;
  /* height: calc(100vh - 100px); */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 문의하기에서 100% 되어야 함. */
  /* height: 100vh; */
  position: relative;
`;

const ContentBodyStyle = styled.div<{ type?: 'page' | 'modal' }>`
  width: 100%;
  /* height: calc(100% - 50px); */
  flex-grow: 1;
  /** 닫기 버튼만큼 빼줘야함 */
  height: calc(100% - 50px);
  /* height: 300px; */
  /* overflow: auto; */
  /* text-align: center; */
  ${(props) => {
    if (props.type === 'page') {
      return pageStyle;
    } else {
      return modalStyle;
    }
  }}/* justify-content: center;
  align-items: flex-start; */
`;

export default FullModal;
