import React, { useEffect, useRef, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { IContactItem } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';

import TitleForm from 'components/items/forms/TitleForm';
import styled from 'styled-components';
import TimePicker from 'components/items/TimePicker';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import moment from 'moment';
import CommonUrl from '../commonControl/CommonUrl';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import Required from 'components/commons/Required';

const CalendarItem = ({ itemModel, seq }: { itemModel?: IContactItem; seq?: number }) => {
  const endDateRef = useRef<any>();
  const { uiStore } = useStore();

  const [isValid, setIsValid] = useState<boolean>(false);

  const {
    initialModel,
    stateModel,
    setState,
    setMultipleState,
    initialBinding,
    updateServiceModel,
    serviceModel,
    unmountModel,
  } = useModelManagement<IContactItem>();

  const validate = () => {
    const title = validationHelper.required(serviceModel?.title);
    const start = validationHelper.date(serviceModel?.startDate);
    const end = validationHelper.date(serviceModel?.endDate);

    // const startTemp = moment(serviceModel?.startDate || '');
    // const endTemp = moment(serviceModel?.endDate || '');

    setIsValid(title && start && end);
  };

  const initializeModel = () => {
    if (itemModel) {
      initialBinding(itemModel);
      setMultipleState({ startDate: itemModel.startDate, endDate: itemModel.endDate });
    } else {
      const localTime = moment().startOf('day').hour(8);
      const start = localTime.minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss[Z]');
      const end = localTime.minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss[Z]');
      initialBinding({
        startDate: start,
        endDate: end,
        isActive: true,
        seq: seq,
      });
    }
  };

  useEffect(() => {
    initializeModel();
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{ padding: '0 20px' }}>
      <ItemSectionStyle>
        <ItemSectionTitle>
          일정 타이틀
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              validate();
            }}
            initialValue={serviceModel?.title}
            errorText="타이틀은 필수항목입니다."
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>일정 상세</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            multiline={true}
            rows={3}
            onChangeValue={(value: string) => {
              updateServiceModel('content', value);
            }}
            initialValue={serviceModel?.content}
          />
        </ItemContent>
      </ItemSectionStyle>
      <CommonUrl
        initialValue={initialModel?.linkUrl}
        onChange={(value: string) => {
          updateServiceModel('linkUrl', value);
        }}
      />
      <ItemSectionStyle>
        <ItemSectionTitle>
          <Row style={{ justifyContent: 'space-between' }}>
            <Row style={{ width: 'unset' }}>
              <div>시작 일시</div>
              <Required />
            </Row>
            <MenuItemCheckBox
              onlySwitch
              onChangeValue={(value: boolean) => {
                updateServiceModel('alldayEvent', value);
              }}
              initialValue={initialModel?.alldayEvent}
              title="하루종일"
            />
          </Row>
        </ItemSectionTitle>
        <ItemContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              onBlur={validate}
              pickerType="start"
              alldayEvent={serviceModel?.alldayEvent}
              // defaultValue={model?.startDate}
              initialValue={serviceModel?.startDate}
              onChange={(time: string) => {
                if (time) {
                  const startTemp = moment(time || '');
                  const endTemp = moment(serviceModel?.endDate || '');
                  if (startTemp > endTemp) {
                    const newTime = startTemp
                      .add(30, 'minutes')
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss[Z]');
                    setMultipleState({
                      startDate: startTemp.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                      endDate: newTime,
                    });
                  } else {
                    setState('startDate', startTemp.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
                  }
                }
              }}
            />
          </LocalizationProvider>
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>
          종료 일시 <Required />
        </ItemSectionTitle>
        <ItemContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              onBlur={validate}
              pickerType="end"
              ref={endDateRef}
              minTime={moment(serviceModel?.startDate)
                .utc()
                .format('HH:mm')}
              alldayEvent={serviceModel?.alldayEvent}
              // defaultValue={model?.endDate}
              initialValue={serviceModel?.endDate}
              onChange={(time: string) => {
                if (time) {
                  const startTemp = moment(serviceModel?.startDate || '');
                  const endTemp = moment(time || '');
                  if (startTemp > endTemp) {
                    const newTime = moment(serviceModel?.startDate)
                      .utc()
                      .add(-30, 'minutes')
                      .format('YYYY-MM-DDTHH:mm:ss[Z]');
                    updateServiceModel('startDate', newTime);
                    updateServiceModel('endDate', endTemp.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
                  } else {
                    updateServiceModel('endDate', endTemp.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
                  }
                }
              }}
            />
          </LocalizationProvider>
        </ItemContent>
      </ItemSectionStyle>
      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.bottomSheet.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            validate();
            if (isValid) {
              uiStore.bottomSheet.confirm(serviceModel);
            }
            // else {
            //   toast('잘못된 값이 포함되어 있습니다.', { type: 'warning' });
            // }
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton>
      </Row>
    </div>
  );
};

const EventStyle = styled.div`
  height: 30px;
`;

export default CalendarItem;
