import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const screenWidth = window.innerWidth;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CommonRoundTabButtons({
  items,
  initialValue,
  style,
  noOfTabs,
  onHandleTabClick,
}: {
  items: string[];
  initialValue?: string;
  style?: React.CSSProperties;
  noOfTabs?: number;
  onHandleTabClick: (label: string) => void;
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (label: string) => {
    onHandleTabClick(label);
  };

  React.useEffect(() => {
    if (initialValue && items) {
      const i = items.findIndex((item: string) => item === initialValue);
      setValue(i || 0);
    }
  }, [initialValue]);

  return (
    <Tabs
      sx={{
        marginTop: '30px',
        width: 'auto',
        display: 'inline-block',
        alignItems: 'center',
        paddingTop: '5px',
        border: '1px solid #d6d6d6',
        // background: '#fff',
        borderRadius: '30px',
        '& .MuiTabs-root': { margin: '0' },
        '& .MuiTabs-indicator': { height: '100%' },
      }}
      TabIndicatorProps={{
        style: {
          background: 'rebeccapurple',
          borderRadius: '30px',
          border: '3px solid #dbdbdb',
        },
      }}
      value={value}
      onChange={(event: React.SyntheticEvent, newValue: number) => handleChange(event, newValue)}
    >
      {items.map((item: string) => (
        <Tab
          sx={{
            // width: noOfTabs === 4 ? '25%' : '33.33%',
            fontSize: '14px',
            fontWeight: '500',
            // color: 'grey',
            // background: '#fff',
            color: '#4d4d4d',
            borderRadius: '30px',
            padding: '10px 30px',
            margin: '0 10px',
            wordBreak: 'keep-all',
            whiteSpace: 'pre-wrap',
            zIndex: 1,
            '&.Mui-selected': {
              // color: '#173300',
              color: '#fff',
            },
            // '&.MuiTab-indicator': {
            //   border: 'none',
            // },
          }}
          label={item}
          onClick={() => handleTabClick(item)}
          {...a11yProps(1)}
        />
      ))}
    </Tabs>
  );
}
