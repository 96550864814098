import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const ManagementInnerLayout = ({ style, children }: { style?: CSSProperties; children: any }) => {
  return (
    <ManagementInnerLayoutStyle style={style} type={window.browserType}>
      {children}
    </ManagementInnerLayoutStyle>
  );
};

const ManagementInnerLayoutStyle = styled.div<{ type: 'ios' | 'android' | 'pc' }>`
  background: var(--background);
  @media ${(props) => props.theme.media.desk} {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 20px;
    background: transparent;
    /* flex-grow: 1; */
  }
  @media ${(props) => props.theme.media.mobile} {
    padding-bottom: ${(props) => (props.type === 'ios' ? '80px' : '0px')};
    padding-bottom: 60px;
    flex-grow: unset;
  }
`;
export default ManagementInnerLayout;
