import React, { useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { MemberDto } from 'services/data-contracts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CSTownPartnerState } from 'types/CommonTypes';
import { useStateContext } from 'libs/hooks/usePageState';

import PartnerSettingsContainer from 'features/myPage/containers/PartnerSettingsContainer';
import TabButtons from 'features/csTown/components/pc/TabButtons';
import NoContent from 'components/commons/NoContent';
import CSPartnerList from 'features/csTown/components/pc/CSPartnerList';
import { CSTownMobileLayout } from 'features/csTown/commonComponents/styles';

type tabs = '파트너' | '초대중';
const PartnerListContainer = ({ onSearch }: { onSearch: (value: string) => void }) => {
  const { uiStore } = useStore();
  const { stateModel, setPageState } = useStateContext<CSTownPartnerState>();

  const [tab, setTab] = useState<tabs>('파트너');

  const handleClickSelect = async (partner: MemberDto) => {
    setPageState('selectedPartner', partner);
  };

  const handleAddPartner = (isEdit?: boolean) => {
    uiStore.modal.show({
      title: '파트너 설정',
      style: {
        minHeight: '100%',
        borderRadius: 0,
      },
      children: (
        <PartnerSettingsContainer memberInfo={isEdit ? stateModel?.selectedPartner : undefined} />
      ),
    });
  };

  return (
    <CSTownMobileLayout>
      <div style={{ margin: '10px 0' }}>
        <TabButtons
          items={['파트너', '초대중']}
          onHandleTabClick={(value: string) => {
            setTab(value as tabs);
            setPageState('selectedPartner', undefined);
          }}
        />
      </div>
      <div style={{ flexGrow: 1, position:'relative' }}>
        {tab === '파트너' && stateModel?.partnerList?.length === 0 && (
          <NoContent title="등록된 파트너가" />
        )}
        {tab === '초대중' && stateModel?.invitationList?.length === 0 && (
          <NoContent title="초대중인 파트너가" />
        )}
        <CSPartnerList
          partners={tab === '파트너' ? stateModel?.partnerList : stateModel?.invitationList}
          onSelect={handleClickSelect}
        />
      </div>
      <OhmossButton
        style={{ borderRadius: 10 }}
        onClick={() => {
          handleAddPartner(false);
        }}
      >
        파트너 초대
      </OhmossButton>
    </CSTownMobileLayout>
  );
};

export default PartnerListContainer;
