import React, { useEffect, useState } from 'react';
import { ProposalDto } from 'services/data-contracts';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import NoContent from 'components/commons/NoContent';
import ProposalController from 'controllers/ProposalController';
import { useStore } from 'stores/StoreHelper';
import CloseIcon from '@mui/icons-material/Close';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import ReceivedProposalContainer from '../components/ReceivedProposalContainer';
import ProposalBox from '../components/ProposalBox';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useMediaContext } from 'providers/useMediaContext';

const ProposalList = () => {
  const { myChannel } = useRootContext();
  const { uiStore } = useStore();
  const [sentInquiries, setSentInquiries] = useState<ProposalDto[]>();
  const [receivedInquiries, setReceivedInquiries] = useState<ProposalDto[]>();
  const [tabLabel, setTabValue] = useState<string>('받은 문의');
  const ScreenTypes = useMediaContext();

  const { getReceivedProposals, deleteProposalByReceipient, getSentProposals } =
    ProposalController();

  const getProposals = async () => {
    const receivedInquiriesTemp = (await getReceivedProposals()) as unknown as ProposalDto[];
    const sentInquiriesTemp = (await getSentProposals()) as unknown as ProposalDto[];

    const receivedInquiriesArr = receivedInquiriesTemp.filter(
      (proposal) => proposal.requestType === 'BLOCK',
    );
    const sentInquiriesArr = sentInquiriesTemp.filter(
      (proposal) => proposal.requestType === 'BLOCK',
    );
    setReceivedInquiries(receivedInquiriesArr);
    setSentInquiries(sentInquiriesArr);
  };

  const handleClickProposal = (proposal: ProposalDto) => {
    uiStore.modal.show({
      style: {
        maxHeight: '85%',
      },
      children: <ReceivedProposalContainer proposal={proposal} />,
    });
  };

  const deleteProposal = async (proposal: ProposalDto) => {
    if (proposal?.id !== undefined) {
      uiStore.confirm.show({
        message: '정말 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteProposalByReceipient(proposal);
          getProposals();
        },
      });
    }
  };

  useEffect(() => {
    if (myChannel) getProposals();
  }, [myChannel]);

  return (
    <>
      <div style={{ marginTop: ScreenTypes.isLarge ? 10 : 0 }}>
        <CommonTabButtons items={['받은 문의', '보낸 문의']} onHandleTabClick={setTabValue} />
      </div>
      {receivedInquiries?.length === 0 && <NoContent title="등록된 문의가" />}
      {tabLabel === '받은 문의' && (
        <div>
          {receivedInquiries?.map((inquiry) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InquiryStyle
                onClick={() => {
                  handleClickProposal(inquiry);
                }}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProposalBox inquiry={inquiry} inquiryType="RECEIVED" />
                </Row>
              </InquiryStyle>
              <div style={{ marginLeft: 10, alignSelf: 'center' }}>
                <CloseIcon onClick={() => deleteProposal(inquiry)} />
              </div>
            </div>
          ))}
        </div>
      )}

      {tabLabel === '보낸 문의' && (
        <div>
          {sentInquiries?.map((inquiry) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InquiryStyle
                onClick={() => {
                  handleClickProposal(inquiry);
                }}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProposalBox inquiry={inquiry} inquiryType="SENT" />
                </Row>
              </InquiryStyle>
              <div style={{ marginLeft: 10, alignSelf: 'center' }}>
                <CloseIcon onClick={() => deleteProposal(inquiry)} />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const InquiryStyle = styled.div`
  cursor: pointer;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px;
`;

export default ProposalList;
