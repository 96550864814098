import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Badge, IconButton } from '@mui/material';
import useNavigation from 'hooks/useNavigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Row } from 'components/commons/layouts';
import { useRootContext } from 'libs/hooks/useRootContext';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import { useStore } from 'stores/StoreHelper';
import MyNotifyInfo from 'components/commons/MyNotifyInfo';
import { ChannelNotificationDto } from 'services/data-contracts';
import { ClickAwayListener } from '@mui/material';
import { observer } from 'mobx-react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Divider } from 'features/myPage/components/CommonStyleComponent';
import { useLocation } from 'react-router-dom';
import { useChat } from 'libs/hooks/useChat';
import Text from 'components/commons/Text';
import CommonMyMenus from 'features/mossMainSettings/components/CommonMyMenus';
import { useStateContext } from 'libs/hooks/usePageState';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const CsTownGnb = observer(() => {
  const location = useLocation();
  const { navigate } = useNavigation();
  const { authStore } = useStore();
  const { subMenus } = useStateContext();
  const { myChannel } = useRootContext();
  const { myNotificationStore, uiStore } = useStore();
  const { unReadMessageCounts, getUnreadCount, initCompleted } = useChat();

  const [showPopup, setShowPopup] = useState(false);
  const [pageTitle, setPageTitle] = useState<string>('');

  const handleClickAlarm = () => {
    uiStore.drawer.show({
      children: (
        <>
          <MyNotifyInfo
            onClick={(alarn: ChannelNotificationDto) => {
              uiStore.drawer.close();
            }}
          />
        </>
      ),
    });
  };

  const getCounts = async () => {
    await getUnreadCount();
  };

  useEffect(() => {
    if (myChannel && initCompleted) {
      getCounts();
    }
  }, [myChannel, initCompleted]);

  const handleClickLogout = async () => {
    uiStore.confirm.show({
      message: '로그아웃 하시겠습니까?',
      onConfirmed: async () => {
        await authStore.logout();
        navigate('/');
      },
    });
    authStore.logout();
  };

  const handleClickSettings = () => {
    setShowPopup(false);

    uiStore.modal.show({
      children: (
        <PaddingStyle>
          <CommonMyMenus />
        </PaddingStyle>
      ),
    });
  };

  useEffect(() => {
    const t = location.pathname.split('/');
    if (t.length > 1) {
      const menu = t[2];
      switch (menu?.toUpperCase()) {
        case 'DEALS':
          setPageTitle('내 공구목록');
          break;
        case 'INQUIRIES':
          setPageTitle('내 문의목록');
          break;
        case 'DASHBOARD':
          setPageTitle('통합 대시보드');
          break;
        case 'CHECKLIST':
          setPageTitle('체크리스트');
          break;
        case 'PARTNERS':
          setPageTitle('파트너 관리');
          break;
        case 'MANAGERS':
          setPageTitle('매니저 관리');
          break;
        default:
          setPageTitle('');
          break;
      }
    } else {
      setPageTitle('');
    }
  }, [location]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowPopup(false);
      }}
    >
      <>
        <div style={{ width: '100%' }}>
          <Row
            style={{
              justifyContent: 'space-between',
              padding: 20,
            }}
          >
            <Row style={{ justifyContent: 'flex-start', maxWidth: 410 }}>
              <IconButton
                style={{ marginRight: 20, background: '#fff' }}
                onClick={() => {
                  if (pageTitle.toLocaleLowerCase() === 'my page') {
                    navigate(`/${myChannel?.channelName}`);
                  } else {
                    history.back();
                  }
                }}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
              <Text type="H3">{pageTitle}</Text>

              <div>{subMenus && subMenus}</div>
            </Row>
            <Row
              style={{
                justifyContent: 'flex-start',
                width: 'unset',
                gap: 20,
              }}
            >
              <Badge
                variant="dot"
                invisible={unReadMessageCounts === 0}
                onClick={() => {
                  navigate('/cstown/inquiries');
                }}
                sx={{
                  '& .MuiBadge-badge': {
                    background: '#173300',
                    color: '#96e06e',
                    fontWeight: 600,
                    marginTop: '5px',
                  },
                }}
              >
                <IconButton style={{ background: '#efefef' }}>
                  <SmsOutlinedIcon />
                </IconButton>
              </Badge>
              <Badge
                badgeContent={myNotificationStore.alarmsCount}
                onClick={handleClickAlarm}
                sx={{
                  '& .MuiBadge-badge': {
                    background: '#173300',
                    color: '#96e06e',
                    fontWeight: 600,
                    marginTop: '5px',
                  },
                }}
              >
                <IconButton style={{ background: '#efefef' }}>
                  <NotificationsOutlinedIcon />
                </IconButton>
              </Badge>
              <Row
                style={{
                  justifyContent: 'space-between',
                  minWidth: 230,
                  borderLeft: '1px solid #d6d6d6',
                  marginLeft: 20,
                  paddingLeft: 30,
                }}
              >
                <Avatar src={myChannel?.profile?.profileImage?.publicUrl} />
                <div style={{ marginLeft: 10 }}>
                  <div style={{ fontWeight: 600 }}>{myChannel?.channelName}</div>
                  <div style={{ color: '#777', fontSize: 12 }}>
                    {myChannel?.channelType} {myChannel?.membershipType}
                  </div>
                </div>
                <IconButton
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setShowPopup((prev) => !prev);
                  }}
                >
                  {showPopup ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Row>
            </Row>
          </Row>
        </div>
        <div>
          {showPopup && (
            <PopUpStyle>
              <>
                {myChannel?.channelType === 'MEMBER' ? (
                  <>
                    <div>
                      <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        <IconButton onClick={handleClickLogout} style={{ background: '#efefef' }}>
                          <PowerSettingsNewIcon />
                        </IconButton>
                        <Text buttonRole="DESCRIPTION">Logout</Text>
                      </Row>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginBottom: 20 }}>
                      <Row style={{ justifyContent: 'space-evenly', marginBottom: 20 }}>
                        <div>
                          <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
                            <IconButton
                              onClick={() => {
                                navigate('/my');
                              }}
                              style={{ background: '#efefef' }}
                            >
                              <AccountCircleOutlinedIcon />
                            </IconButton>
                            <Text buttonRole="DESCRIPTION">MY</Text>
                          </Row>
                        </div>
                        <div>
                          <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
                            <IconButton
                              onClick={handleClickSettings}
                              style={{ background: '#efefef' }}
                            >
                              <SettingsOutlinedIcon />
                            </IconButton>
                            <Text buttonRole="DESCRIPTION">Settings</Text>
                          </Row>
                        </div>
                        <div>
                          <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
                            <IconButton
                              onClick={handleClickLogout}
                              style={{ background: '#efefef' }}
                            >
                              <PowerSettingsNewIcon />
                            </IconButton>
                            <Text buttonRole="DESCRIPTION">Logout</Text>
                          </Row>
                        </div>
                      </Row>
                      <OhmossButton
                        type="primaryPc"
                        onClick={() => {
                          navigate('/management');
                        }}
                      >
                        내 링크관리
                      </OhmossButton>
                    </div>
                    <Divider />
                    <MyChannelList
                      onClick={() => {
                        navigate('/cstown');
                      }}
                      type="channel"
                    />
                    <Divider />
                  </>
                )}
              </>
            </PopUpStyle>
          )}
        </div>
      </>
    </ClickAwayListener>
  );
});

const PaddingStyle = styled.div`
  padding: 20px 40px;
`;

const PopUpStyle = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  height: calc(100vh - 50px);
  width: 300px;
  overflow-y: auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #ebebeb;
  z-index: 10;
`;

export default CsTownGnb;
