import { useStore } from 'stores/StoreHelper';
import {
  MembershipDto,
  MembershipGiftDto,
  MembershipPlanDto,
  MembershipPurchaseDto,
  OrderDto,
  TosspayApprovalDto,
} from 'services/data-contracts';
import MembershipStore from 'stores/MembershipStore';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useRootContext } from 'libs/hooks/useRootContext';
import { v4 as uuidv4 } from 'uuid';
import { TOSSPAY_KEY } from 'stores/AppStore';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const MembershipController = () => {
  const { xhrStore } = useStore();
  const { myChannel } = useRootContext();

  const membershipStore = new MembershipStore();

  // tossPayment -> env로 옮길것.
  const clientKey = 'test_ck_7XZYkKL4Mrjd6LzKzjm30zJwlEWR';

  const getMembershipPlans = async (channelType: 'SELLER' | 'BRAND' | 'MEMBER') => {
    xhrStore.setState('pending');
    const plans = (await membershipStore.getMembershipPlans({
      channelType,
    })) as unknown as MembershipPlanDto[];
    xhrStore.setState('done');
    return plans;
  };

  const getMembershipPlan = async (id: number) => {
    xhrStore.setState('pending');
    const plan = (await membershipStore.getMembershipPlan(id)) as unknown;
    xhrStore.setState('done');
    return plan;
  };

  const pay = async (membershipPlanId: number, duration: number) => {
    xhrStore.setState('pending');
    const paymentInfo = (await membershipStore.pay(
      { ownChannelId: myChannel?.id as number },
      {
        membershipPlanId,
        monthly: duration,
      },
    )) as unknown as OrderDto;

    if (typeof paymentInfo !== 'undefined') {
      loadTossPayments(clientKey).then((tossPayments) => {
        tossPayments.requestPayment('카드', {
          amount: paymentInfo?.totalAmount as number,
          orderId: paymentInfo?.orderNo as string,
          orderName: paymentInfo?.membershipPlan?.name as string,
          customerName: paymentInfo?.channel?.channelName,
          successUrl: `${window.location.origin}/my/subscriptions/payment`,
          failUrl: `${window.location.origin}/my/subscriptions/payment`,
        });
      });
    }
    xhrStore.setState('done');
    return paymentInfo;
  };

  const billing = async (membershipId: number, duration: number) => {
    const uuid = uuidv4();
    loadTossPayments(TOSSPAY_KEY as string).then((tossPayments) => {
      tossPayments.requestBillingAuth('카드', {
        customerKey: uuid,
        successUrl: `${window.location.origin}/my/subscriptions/payment?id=${membershipId}&dr=${duration}&ck=${uuid}`,
        failUrl: `${window.location.origin}/my/subscriptions/payment`,
      });
    });
  };

  const cancelBillingMembership = async (membershipId: number) => {
    return await membershipStore.cancelMembership(myChannel?.id as number, membershipId);
  };

  const purchaseMembership = (params: MembershipPurchaseDto) => {
    const result = membershipStore.purchaseMembership(myChannel?.id as number, params);
    return result;
  };

  const approve = async (ownChannelId: number, data: TosspayApprovalDto) => {
    // xhrStore.setState('pending');
    // const response = (await membershipStore.approve(ownChannelId as number, {
    //   ...data,
    // })) as unknown as MembershipDto;
    // xhrStore.setState('done');
    // return response;
  };

  const getCurrentMembership = async () => {
    xhrStore.setState('pending');
    const response = (await membershipStore.getCurrentMembership(
      myChannel?.id as number,
    )) as unknown as MembershipDto;
    xhrStore.setState('done');
    return response;
  };

  const getPreviousMemberships = async () => {
    xhrStore.setState('pending');
    const response = (await membershipStore.getMemberships(
      myChannel?.id as number,
    )) as unknown as MembershipDto[];
    xhrStore.setState('done');
    return response;
  };

  const cancelMembership = async (orderId: number) => {
    xhrStore.setState('pending');
    const response = (await membershipStore.cancelMembership(
      myChannel?.id as number,
      orderId,
    )) as unknown as OrderDto;
    xhrStore.setState('done');
    return response;
  };

  const payGift = async (
    membershipPlanId: number,
    duration: number,
    receiverChannelName: string,
  ) => {
    xhrStore.setState('pending');
    const paymentInfo = (await membershipStore.payGift(
      { ownChannelId: myChannel?.id as number },
      {
        membershipPlanId,
        monthly: duration,
        receiverChannelName,
      },
    )) as unknown as OrderDto;

    if (typeof paymentInfo !== 'undefined') {
      loadTossPayments(clientKey).then((tossPayments) => {
        tossPayments.requestPayment('카드', {
          amount: paymentInfo?.totalAmount as number,
          orderId: paymentInfo?.orderNo as string,
          orderName: paymentInfo?.membershipPlan?.name as string,
          customerName: paymentInfo?.channel?.channelName,
          successUrl: `${window.location.origin}/my/subscriptions/payment?receiverChannelName=${receiverChannelName}`,
          failUrl: `${window.location.origin}/my/subscriptions/payment`,
        });
      });
    }
    xhrStore.setState('done');
    return paymentInfo;
  };

  const approveGiftPayment = async (ownChannelId: number, data: TosspayApprovalDto) => {
    xhrStore.setState('pending');
    const response = (await membershipStore.approveGiftPayment(ownChannelId as number, {
      ...data,
    })) as unknown as MembershipDto;
    xhrStore.setState('done');
    return response;
  };

  const getSentGifts = async (channelId: number) => {
    xhrStore.setState('pending');
    const response = (await membershipStore.getSentGifts(channelId)) as unknown as MembershipDto[];
    xhrStore.setState('done');
    return response;
  };

  const getSentMembershipGift = async (channelId: number, membershipGiftId: number) => {
    xhrStore.setState('pending');
    const response = (await membershipStore.getSentMembershipGift(
      channelId,
      membershipGiftId,
    )) as unknown as MembershipDto;
    xhrStore.setState('done');
    return response;
  };

  const getReceivedGifts = async (channelId: number) => {
    xhrStore.setState('pending');
    const response = (await membershipStore.getReceivedGifts(
      channelId,
    )) as unknown as MembershipDto[];
    xhrStore.setState('done');
    return response;
  };

  const getReceivedMembershipGift = async (channelId: number, membershipGiftId: number) => {
    xhrStore.setState('pending');
    const response = (await membershipStore.getReceivedMembershipGift(
      channelId,
      membershipGiftId,
    )) as unknown as MembershipGiftDto[];
    xhrStore.setState('done');
    return response;
  };

  return {
    getMembershipPlans,
    getMembershipPlan,
    pay,
    billing,
    approve,
    getCurrentMembership,
    cancelMembership,
    cancelBillingMembership,
    getPreviousMemberships,
    payGift,
    approveGiftPayment,
    getSentMembershipGift,
    getSentGifts,
    getReceivedGifts,
    getReceivedMembershipGift,
    purchaseMembership,
  };
};

export default MembershipController;
