import React, { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
const AnimatedTabButton = ({
  children,
  style,
  onClick,
}: {
  children: ReactNode[];
  style?: CSSProperties;
  onClick?: (item: any) => void;
}) => {
  const rootRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClick = useCallback((event: MouseEvent) => {
    rootRefs.current.forEach((ref, index) => {
      if (ref && ref.contains(event.target as Node)) {
        setSelectedIndex(index);
        // onClick?.(children[index]);
      }
    });
  }, []);

  //   useEffect(() => {
  //     document.addEventListener('click', handleClick);
  //     return () => {
  //       document.removeEventListener('click', handleClick);
  //     };
  //   }, [handleClick]);

  const handleOnClick = (index: number, child: ReactNode) => {
    setSelectedIndex(index);
    onClick?.(child);
  };

  return (
    <Container style={style}>
      <Background count={children.length} activeIndex={selectedIndex} />
      {React.Children.map(children, (child, index) => (
        <div
          style={{ flex: 1 }}
          ref={(el) => (rootRefs.current[index] = el)}
          onClick={() => {
            handleOnClick(index, child);
          }}
        >
          {child}
        </div>
      ))}
      {/* {tabItems.map((item, index: number) => (
        <TabItem
          key={index}
          selected={selectedIndex === index}
          onClick={() => {
            onClick?.(tabItems[index]);
            setSelectedIndex(index);
          }}
        >
          {item}
        </TabItem>
      ))} */}
    </Container>
  );
};

const Container = styled.div<{ selectedIndex?: number }>`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background: #f0f0f0;
  justify-content: space-between;
`;

const Background = styled.div<{ count: number; activeIndex: number }>`
  position: absolute;
  top: 5px;
  bottom: 5px;
  background: #fff;
  width: calc(100% / ${(props) => props.count} - 10px);
  left: calc((100% / ${(props) => props.count}) * ${(props) => props.activeIndex} + 5px);
  transition: left 0.2s cubic-bezier(0.12, 0.77, 0.32, 1.01);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
`;

const TabItem = styled.div<{ selected?: boolean; key?: any }>`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
`;

export { AnimatedTabButton, TabItem };
