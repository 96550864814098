import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import useNavigation from 'hooks/useNavigation';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

import LocalMallIcon from '@mui/icons-material/LocalMall';
import { ChannelDto } from 'services/data-contracts';
import CsTownSellerLnb from 'features/csTown/components/CsTownSellerLnb';
import CsTownBrandLnb from 'features/csTown/components/CsTownBrandLnb';
import CsTownMemberLnb from 'features/csTown/components/CsTownMemberLnb';
import { useStateContext } from 'libs/hooks/usePageState';
import { MenuBoxItemStyle, MenuBoxStyle } from 'features/csTown/commonComponents/styles';
import { useChat } from 'libs/hooks/useChat';
import { Row } from 'components/commons/layouts';
import { BadgeStyle } from 'components/commons/buttons/Badge';

const CsTownLnbContainer = ({ channelType }: { channelType?: ChannelDto['channelType'] }) => {
  const { navigate } = useNavigation();
  const [currentMenu, setCurrentMenu] = useState<string | undefined>('');
  const { setPageHeaderTitle } = useStateContext();
  const { unReadMessageCounts, getUnreadCount, initCompleted } = useChat();

  const getLnb = () => {
    switch (channelType) {
      case 'SELLER':
        return <CsTownSellerLnb currentMenu={currentMenu} />;
      case 'BRAND':
        return <CsTownBrandLnb currentMenu={currentMenu} />;
      default:
        return <CsTownMemberLnb currentMenu={currentMenu} />;
    }
  };

  useEffect(() => {
    if (window.location.pathname) {
      const t = window.location.pathname.split('/');
      if (t.length > 2) {
        setCurrentMenu(t[2]);
      } else {
        setCurrentMenu(undefined);
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (initCompleted) {
      getUnreadCount();
    }
  }, [initCompleted]);

  return (
    <MenuContainerStyle>
      {/* <div
        style={{ textAlign: 'center', padding: '50px 0', cursor: 'pointer', position: 'absolute' }}
        onClick={() => {
          navigate('/cstown/home');
          setPageHeaderTitle('');
        }}
      >
        <img src="/images/oh_logo.svg" alt="" style={{ width: 150 }} />
        <div style={{ fontWeight: 400, fontSize: 16, marginTop: 10, color: '#818181' }}>CStown</div>
      </div> */}
      <LogoStyle style={{ width: '100%' }}>
        <Row
          style={{ padding: 10, cursor: 'pointer', justifyContent: 'flex-start' }}
          onClick={() => {
            navigate('/cstown/home');
            setPageHeaderTitle('');
          }}
        >
          <img src="/images/oh_logo.svg" style={{ width: 60 }} />
          <BadgeStyle>CSTOWN V1</BadgeStyle>
        </Row>
      </LogoStyle>

      <MenuBoxStyle style={{ paddingTop: 120, paddingLeft: 20, paddingRight: 20 }}>
        <MenuBoxItemStyle
          selected={currentMenu === 'deals'}
          onClick={() => {
            setPageHeaderTitle('내 공구목록');
            navigate('/cstown/deals');
          }}
        >
          <IconButton>
            <LocalMallIcon style={{ color: currentMenu === 'deals' ? '#fff' : 'unset' }} />
          </IconButton>
          내 공구목록
        </MenuBoxItemStyle>
        <MenuBoxItemStyle
          selected={currentMenu === 'inquiries'}
          onClick={() => {
            setPageHeaderTitle('내 문의목록');
            navigate('/cstown/inquiries');
          }}
        >
          <IconButton>
            <SmsOutlinedIcon style={{ color: currentMenu === 'inquiries' ? '#fff' : 'unset' }} />
          </IconButton>
          <Row style={{ justifyContent: 'space-between' }}>
            <div>내 문의목록</div>
            <>
              {console.log('unReadMessageCounts', typeof unReadMessageCounts)}
              {typeof unReadMessageCounts !== 'undefined' && unReadMessageCounts > 0 && (
                <>
                  <BadgeStyle style={{ background: '#c60081', color: '#fff' }}>
                    {unReadMessageCounts}
                  </BadgeStyle>
                </>
              )}
            </>
          </Row>
        </MenuBoxItemStyle>
        {getLnb()}
      </MenuBoxStyle>
    </MenuContainerStyle>
  );
};

const MenuContainerStyle = styled.div`
  max-width: 350px;
  width: 100%;
  min-width: 250px;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LogoStyle = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
`;

export default CsTownLnbContainer;
