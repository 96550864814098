import { makeObservable, flow, observable, action } from 'mobx';
import { AiService } from 'services/AiService';
import { HttpClient } from 'services/http-client';
import { LinkDto } from 'services/data-contracts';
import _ from 'lodash';
import { EventSourcePolyfill } from 'event-source-polyfill';
import ServiceStoreBase from './ServiceStoreBase';
import { REST_API_URL_V2 } from 'stores/AppStore';
/**
 * @description Public Link Store
 */
class AIStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      chat: flow,
      ask: action,
      unmount: action,
      messages: observable,
      busyStatus: observable,
    });
  }

  api: AiService<unknown> = new AiService(new HttpClient());

  links: Array<LinkDto> | undefined;

  link: LinkDto;

  messages: string;

  busyStatus: 'loading' | 'done' | 'init' | '';

  /** ========================================================================================= */

  chat(content: string) {
    this.busyStatus = 'init';
    this.messages = '';
    const token = localStorage.getItem('accessToken');
    const url = `${REST_API_URL_V2}/v2/ai/ask-stream?question=${encodeURI(
      `${content}. 이 내용을 더 좋게 편집해줘. 200자 이내로 이모티콘 섞어서.`,
    )}`;

    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    eventSource.onmessage = (e) => {
      this.busyStatus = 'loading';

      if (e.data !== '[DONE]') {
        const parsedData = JSON.parse(e.data);
        const message = parsedData.choices[0].delta?.content;
        if (typeof message !== 'undefined') {
          this.messages += message;
        }
      } else {
        this.busyStatus = 'done';
        eventSource.close();
      }
    };
  }

  async ask(url: string, content?: string) {
    this.busyStatus = 'init';
    this.messages = '';
    const token = localStorage.getItem('accessToken');
    const newUrl = `${REST_API_URL_V2}${url}`;

    const eventSource = new EventSourcePolyfill(newUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // eventSource.onmessage = (e) => {
    //   if (e.data !== '[DONE]') {
    //     const parsedData = JSON.parse(e.data);
    //     const message = parsedData.choices[0].delta?.content;
    //     if (typeof message !== 'undefined') {
    //       console.log(message);
    //     }
    //   } else {
    //     eventSource.close();
    //   }
    // };
    return eventSource;
  }

  unmount() {
    this.messages = '';
    this.busyStatus = '';
  }
}

export default AIStore;
