import React from 'react';
import { DetailsContainer, DetailsHeader, DetailsItem } from './styles';
import { Divider } from '@mui/material';

const BRAND_GIFT = () => {
  const texts = [
    '링크 서비스 무료 제공 (일부 블록 제외)',
    '매니저 기능 제공',
    '제안하기 기능 제공',
    '대시보드 기능 제공',
  ];
  return (
    <>
      <DetailsContainer>
        <DetailsHeader>
          FREE
          <br />
          0원
        </DetailsHeader>
        <Divider />
        <>
          {texts?.map((feature, index) => (
            <>
              <DetailsItem>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 14 }}>
                  <img src="/images/radio_check_btn.svg" alt="" />
                  {feature}
                </div>
              </DetailsItem>
            </>
          ))}
        </>
      </DetailsContainer>
    </>
  );
};

export default BRAND_GIFT;
