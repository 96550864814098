import { useStore } from 'stores/StoreHelper';

import { ChannelDto } from 'services/data-contracts';
import { decodeToken } from 'react-jwt';
import { toast } from 'react-toastify';
import owebviewPostMessage from 'libs/webview/owebviewPostMessage';

/**
 * @description 로그인 후 기본 채널을 설정한다.
 * https://www.npmjs.com/package/secure-web-storage
 * @returns
 */
function useChannelSelector() {
  const { myChannelStore } = useStore();

  /**
   * @param channel
   * @description 로그인한 시점에 마지막 사용한 채널을 취득하거나 없을경우 첫번째
   * @description 채널로 설정한다.
   */
  const initializeFirstChannel = async () => {
    const email = await getUserEmail();
    const channels: Array<ChannelDto> = (await myChannelStore.getItems()) as any as never;

    // 기존에 남아있는 구조를 Replace한다.
    let savedChannel = localStorage.getItem(`channel_${email}`);
    const t = Number(savedChannel);
    if (Number.isNaN(t)) {
      // localStorage.removeItem(`channel_${email}`);
      if (savedChannel) {
        const temp = JSON.parse(savedChannel);
        localStorage.setItem(`channel_${email}`, temp.id.toString());
        savedChannel = temp.id;
      }
    }

    if (savedChannel) {
      const channel = getChannelByChannelId(channels, Number(savedChannel));
      if (channel?.id) {
        localStorage.setItem(`channel_${email}`, channel.id.toString());
        myChannelStore.currentChannel = channel;
        return channel;
      } else {
        return channels[0];
      }
    } else if (typeof channels?.[0] !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      localStorage.setItem(`channel_${email}`, channels[0].id!.toString());
      // eslint-disable-next-line prefer-destructuring
      myChannelStore.currentChannel = channels[0];
      return channels[0];
    } else {
      return null;
    }
  };

  /**
   * @description 현재 채널만 바꾸고 스토리지는 변경하지 않는다.
   * @param channelName
   */
  const setDefaultChannel = async (channelName?: string) => {
    if (!myChannelStore.currentChannel) {
      const channels = (await myChannelStore.getItems()) as any;
      if (Array.isArray(channels.data)) {
        if (channelName) {
          const current = getChannelByChannelName(channels.data, channelName);
          if (current) {
            myChannelStore.setCurrentChannel(current);
          } else {
            const c = await initializeFirstChannel();
            if (c) {
              myChannelStore.setCurrentChannel(c);
            }
          }
        }
      } else {
        toast('채널이 바르지 않습니다. 고객센터로 문의 주세요.');
      }
    }
  };

  const getChannelByChannelName = (channelsOrigin: Array<ChannelDto>, channelName: string) => {
    const result = channelsOrigin?.find(
      (channel: ChannelDto) => channel.channelName === channelName,
    );
    return result;
  };

  const getChannelByChannelId = (channelsOrigin: Array<ChannelDto>, id: number) => {
    const result = channelsOrigin?.find((channel: ChannelDto) => channel.id === id);
    return result;
  };

  const getUserEmail = async () => {
    const accessToken = await localStorage.getItem('accessToken');
    if (accessToken) {
      return (decodeToken(accessToken) as any).sub;
    } else {
      return undefined;
    }
  };

  const saveChannel = async (channel: ChannelDto) => {
    const email = await getUserEmail();
    if (channel && channel.id) {
      localStorage.setItem(`channel_${email}`, channel.id.toString());
      owebviewPostMessage('SET_CHANNEL_ID', channel.id.toString());
    }
  };

  return {
    initializeFirstChannel,
    saveChannel,
    setDefaultChannel,
  };
}

export default useChannelSelector;
