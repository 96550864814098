import React from 'react';
import { Row } from 'components/commons/layouts';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import moment from 'moment';
import Text from 'components/commons/Text';

const TimeDisplay = ({
  title,
  date,
  type,
}: {
  title: string;
  date?: string;
  type?: 'START' | 'END';
}) => {
  return (
    <Row style={{ width: 'unset' }}>
      <div>
        <CalendarMonthOutlinedIcon style={{ width: 40, height: 40, color: '#777' }} />
      </div>
      <div style={{ marginLeft: 10 }}>
        <div>{title}</div>
        <Text type="H3" style={{ color: type === 'END' ? '#96002b' : '#000' }}>
          {date && moment(date).format('YYYY-MM-DD hh:mm')}
        </Text>
      </div>
    </Row>
  );
};

export default TimeDisplay;
