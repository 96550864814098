import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { koKR } from '@mui/x-date-pickers';
import { ko } from 'date-fns/locale';
import { Row } from 'components/commons/layouts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InputAdornment } from '@mui/material';
import { validationHelper } from 'controls/validationHelper';

import TextField from '@mui/material/TextField';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TimePicker = forwardRef(
  (
    {
      alldayEvent,
      initialValue,
      minTime,
      pickerType,
      onChange,
      onBlur,
    }: {
      alldayEvent?: boolean;
      initialValue?: string;
      minTime?: string;
      pickerType: 'start' | 'end';
      onChange: (date?: string) => void;
      onBlur: () => void;
    },
    ref,
  ) => {
    const tempTime = useRef<string | null>();
    const [selectedDate, setSelectedDate] = useState<Date | string>();
    const [selectedTime, setSelectedTime] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [formatError, setFormatError] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      setDate,
    }));

    const setDate = (date: string) => {
      setSelectedDate(date);
      setSelectedTime(moment(date).format('HH:mm'));
    };

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleDateChange = (date: any) => {
      if (date === null) {
        setSelectedDate('');
      } else {
        setSelectedDate(date);
      }
      setNewTime();
    };

    const generateTimeIntervals = (): string[] => {
      const times: string[] = [];

      // 입력된 minTime을 시간과 분으로 분리
      const [minHours, minMinutes] = minTime?.split(':').map(Number) || [0, 0];

      // minTime 설정
      const minDate = new Date();
      minDate.setHours(minHours, minMinutes, 0, 0);

      const startTime = new Date();
      startTime.setHours(0, 0, 0, 0);
      const endTime = new Date();
      endTime.setHours(23, 30, 0, 0);

      for (
        let current = startTime;
        current <= endTime;
        current.setMinutes(current.getMinutes() + 30)
      ) {
        // current 시간이 minTime 이후일 경우에만 배열에 추가
        if (current >= minDate) {
          const hours = current.getHours();
          const minutes = current.getMinutes();
          const timeStr = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
          times.push(timeStr);
        }
      }

      return times;
    };

    const timeIntervals = generateTimeIntervals();

    const handleClickTimeItem = (time: string) => {
      setSelectedTime(time);
      handleClose();
      setNewTime(time);
    };

    const setNewTime = (time?: string) => {
      if (typeof selectedDate === 'undefined' && typeof selectedTime === 'undefined') return;
      if (selectedDate !== '' && selectedTime !== '') {
        const date = moment(selectedDate).format('YYYY-MM-DD');
        const newDate = moment(`${date} ${time || selectedTime}`).format('YYYY-MM-DD HH:mm:ss[Z]');
        onChange(newDate);
      } else {
        onChange(undefined);
      }
    };

    // useEffect(() => {
    //   setNewTime();
    // }, [selectedDate, selectedTime]);

    useEffect(() => {
      if (typeof initialValue !== 'undefined') {
        const t = moment.utc(initialValue).format('HH:mm');
        setSelectedDate(moment.utc(initialValue).toDate());
        setSelectedTime(moment.utc(initialValue).format('HH:mm'));
      }
    }, [initialValue]);

    useEffect(() => {
      if (alldayEvent === true) {
        tempTime.current = selectedTime;
        if (pickerType === 'start') {
          setSelectedTime('00:00');
        } else {
          setSelectedTime('23:59');
        }
      } else {
        if (tempTime.current) {
          setSelectedTime(tempTime.current);
          tempTime.current = null;
        } else {
          if (pickerType === 'start') {
            setSelectedTime('08:00');
          } else {
            setSelectedTime('08:30');
          }
          tempTime.current = null;
        }
      }
    }, [alldayEvent]);

    return (
      <>
        <Row>
          <LocalizationProvider adapterLocale={ko} dateAdapter={AdapterDateFns}>
            <DatePicker
              format="yyyy-MM-dd"
              disablePast={false}
              localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
              sx={{ '&.MuiFormControl-root': { flex: 1 } }}
              onChange={handleDateChange}
              value={selectedDate}
              defaultValue={moment(initialValue).toDate()}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
          <div style={{ margin: '0 10px', width: 200 }}>
            <input type="text" name="username" style={{ display: 'none' }} autoComplete="off" />
            <TextField
              error={formatError}
              disabled={alldayEvent}
              onBlur={onBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ cursor: 'pointer' }}
                    position="end"
                    onClick={(e) => {
                      handleClick(e);
                    }}
                  >
                    <ExpandMoreIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: 'off',
                onBlur: (e: any) => {
                  const timeValid = validationHelper.timeRange(e.target.value);
                  if (timeValid) {
                    let temp;
                    if (e.target.value.indexOf(':') > -1) {
                      setSelectedTime(e.target.value);
                    } else {
                      temp = `${e.target.value.substring(0, 2)}:${e.target.value.substring(2)}`;
                      setSelectedTime(temp);
                    }
                  }
                  setFormatError(!timeValid);
                },
              }}
              onChange={(e: any) => {
                setSelectedTime(e.target.value);
                setNewTime();
              }}
              value={selectedTime}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              style={{ height: 200 }}
              sx={{
                '& .MuiPaper-root': {
                  transition: 'none !important',
                  boxShadow: 'unset',
                  // marginTop: '28px !important',
                },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div style={{ border: '1px solid #efefef' }}>
                {timeIntervals.map((option) => (
                  <TimeItemStyle
                    onClick={() => {
                      handleClickTimeItem(option);
                    }}
                    style={{ width: 200 }}
                  >
                    {option}
                  </TimeItemStyle>
                ))}
              </div>
            </Popover>
          </div>
        </Row>
      </>
    );
  },
);

const TimeItemStyle = styled.div`
  padding: 10px;
  &:hover {
    background: #efefef;
  }
`;

export default TimePicker;
