import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import CategoryController from 'controllers/CategoryController';
import useLocalParams from 'libs/hooks/useLocalParams';
import { ChannelBoardDto, LinkDto } from 'services/data-contracts';
import LinkController from 'controllers/LinkController';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChannelContext } from '../context/useChannelContext';
import LinkServiceContainer from '../containers/LinkServiceContainer';
import LinkServicePageController from './pageControllers/LinkServicePageController';

/**
 * @description 실제 링크 페이지
 */
const LinkServicePage = observer(() => {
  const { linkkey } = useParams();
  const { myChannel } = useRootContext();
  const { beginChat } = LinkServicePageController();
  const { getLinkByKey } = LinkController();
  const { channelName, boardId } = useLocalParams();
  const { getCategoryBoardsByChannelName, getDefaultCategoryBoard } = CategoryController();
  const { categoryBoards, setCategoryBoards, setCategoryBoard } = useChannelContext();

  const initialize = async () => {
    if (channelName) {
      const boards = await getCategoryBoardsByChannelName(channelName as string);
      const linkBoards = boards?.filter((board) => board.boardType === 'LINK');
      setCategoryBoards(linkBoards as ChannelBoardDto[]);
    }
  };

  const initializeChat = async () => {
    if (linkkey) {
      const link = (await getLinkByKey(linkkey)) as unknown as LinkDto;
      if (link) {
        beginChat(link);
        window.history.replaceState('', '', `/${channelName}`);
      } else {
        toast('해당 공구가 존재하지 않습니다.', { type: 'warning' });
      }
    }
  };

  const getBoardInfo = async () => {
    if (typeof boardId !== 'undefined' && categoryBoards && channelName) {
      const board = (await getDefaultCategoryBoard(categoryBoards, boardId)) as ChannelBoardDto;
      // boardId == 1 : 종료된 딜 임의 ID
      if (board && boardId > 1) {
        setCategoryBoard(board);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, [channelName]);

  useEffect(() => {
    if (linkkey && myChannel) {
      initializeChat();
    }
  }, [myChannel, linkkey]);

  useEffect(() => {
    getBoardInfo();
  }, [boardId, categoryBoards, channelName]);

  return (
    <div className="global-width" style={{ margin: 'auto' }}>
      <LinkServiceContainer />
    </div>
  );
});

export default LinkServicePage;
