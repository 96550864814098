import React, { useEffect, useState, useRef } from 'react';
import { ImageUploader } from 'components/commons';
import { ItemContent, ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile, DealServiceItemModel, MyDealState, OpenGraphType } from 'types/CommonTypes';
import { IDealBlock, Layout } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import { toast } from 'react-toastify';
import { parseJSONAll } from 'libs/helper/utils';
import { ChannelBoardDto, DealOptionDto } from 'services/data-contracts';

import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import TitleForm from 'components/items/forms/TitleForm';
import WebEditor from 'components/commons/webEditor/Editor';
import UrlForm from 'components/items/forms/UrlForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import useOpenGraph from 'libs/hooks/useOpenGraph';
import TimeDealPicker from 'components/items/TimeDealPicker';
import OhmossAI from 'components/commons/AI/OhmossAI';
import useAI from 'libs/hooks/useAI';
import BlockController from 'controllers/BlockController';

import CategoryController from 'controllers/CategoryController';
import { FormControl, Select, MenuItem as SelectMenuITem } from '@mui/material';
import DealLayout from 'features/management/components/commonControl/DealLayout';
import CommonAlign from 'features/management/components/commonControl/CommonAlign';
import { ListBoxItem, ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import ItemStickerBox from 'features/csTown/commonComponents/ItemStickerBox';
import ItemCustomerService from 'features/csTown/commonComponents/ItemCustomerService';
import { usePageModelContext } from 'features/management/context/usePageModelContext';
import Required from 'components/commons/Required';
import { useStateContext } from 'libs/hooks/usePageState';
import CategoryFormContainer from 'features/management/containers/category/CategoryFormContainer';
import AddIcon from 'features/csTown/commonComponents/AddIcon';
import ItemUseInquiry from './dealList/components/ItemUseInquiry';
import ItemCustomerInquiryBox from './dealList/components/ItemCustomerInquiryBox';

const Deal = ({ blockModel, onUpdate }: { blockModel?: IDealBlock; onUpdate?: () => void }) => {
  const { uiStore } = useStore();
  const { setPageState } = useStateContext<MyDealState>();

  const serviceItemsDef: DealServiceItemModel[] = [
    { title: '전화번호', serviceType: 'multi', seq: 2, icon: '📞' },
    { title: '배송 문의', serviceType: 'multi', seq: 3, icon: '🚚' },
    { title: '공지사항', serviceType: 'multi', seq: 4, icon: '🔔' },
  ];

  /** Primitives */
  /** @description 초기값 */
  const defaultProps: Partial<IDealBlock> = {
    linkType: 'Deal',
    openNewWindow: true,
    useInquiry: false,
    isActive: true,
    layout: 'TypeA',
    useTimeDeal: false,
    items: serviceItemsDef,
  };

  const { getSiteInfo } = useOpenGraph();
  // const { getDealOptions, saveDealOptions } = useOhmossContext<LinkTypes<IDealBlock>>();

  const {
    initialModel,
    initialBinding,
    updateServiceModel,
    updateServiceModelMultiple,
    unmountModel,
    setState,
    setMultipleState,
    stateModel,
    serviceModel,
  } = usePageModelContext<IDealBlock>();

  const { updateBlock, getDealOptions, saveDealOptions } = BlockController<IDealBlock>();
  const { getMyCategoryBoards } = CategoryController();

  const [localModel, setLocalModel] = useInitializeModel<Partial<IDealBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );
  const [siteInfo, setSiteInfo] = useState<OpenGraphType>();

  /** @description 카테고리가 없을경우, 카테고리를 선택할수 있게 한다. */
  const [categories, setCategories] = useState<ChannelBoardDto[]>([]);

  /** Primitives */

  const { chat, extractText } = useAI();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [dealOptions, setDealOptions] = useState<DealOptionDto[] | undefined>([]);

  const titleRef = useRef<any>(null);
  const editorRef = useRef<any>(null);

  const handleTimeRangeChange = (
    from: string,
    to: string,
    toHour?: number,
    type?: 'time' | 'day' | undefined,
  ) => {
    if (from && to) {
      updateServiceModelMultiple({ startDate: from, endDate: to, timeType: type });
      setLocalModel('startDate', from);
      setLocalModel('endDate', to);
    }
  };

  const handlePaste = async (value: string) => {
    if (value) {
      const result = (await getSiteInfo(value)) as any as OpenGraphType;
      if (result) {
        setSiteInfo(result);
        if (result.faviconUrl) {
          updateServiceModelMultiple({ title: result.title, faviconUrl: result.faviconUrl });
          setLocalModel('showIcon', true);
        } else {
          updateServiceModel('title', result.title);
        }

        if (titleRef.current && result.image) {
          const image = `<figure><img src=${result.image} alt=${result.title} /><br/></figure>`;
          editorRef.current.setData(result.description + image);
          titleRef.current.setValue(result.title);
          validation();
        }
      }
    }
  };

  const handleClickNewCategory = () => {
    uiStore.drawer.show({
      title: '새 페이지 만들기',
      children: <CategoryFormContainer />,
      onConfirmed: (result) => {
        setCategories([...categories, result]);
        setState('boardId', result?.id);
      },
    });
  };

  const handleClickAI = () => {
    const content = editorRef.current.getData();
    if (content.length === 0) {
      toast('먼저 본문 내용을 입력해 주세요.', { type: 'warning' });
      return;
    } else if (content.length < 10) {
      toast('내용이 너무 짧습니다. 10자 이상 입력해 주세요.', { type: 'warning' });
      return;
    }
    const text = extractText(content);
    chat(text);
  };

  const setCategoryBoard = async () => {
    const boards = await getMyCategoryBoards();
    setCategories(boards);
  };

  const validation = () => {
    const titleValid = validationHelper.required(localModel?.title || titleRef.current.getValue());
    const urlValid = validationHelper.urlOrEmpty(localModel?.linkUrl);
    const start = validationHelper.required(localModel?.startDate);
    const end = validationHelper.required(localModel?.endDate);
    const categoryId = validationHelper.required(stateModel?.boardId);
    setIsValid(titleValid && urlValid && start && end && categoryId);
  };

  const checkValidation = () => {
    const titleValid = validationHelper.required(localModel?.title || titleRef.current.getValue());
    const urlValid = validationHelper.urlOrEmpty(localModel?.linkUrl);
    const start = validationHelper.required(localModel?.startDate);
    const end = validationHelper.required(localModel?.endDate);
    if (!titleValid) {
      titleRef.current.validate();
    }
    return titleValid && urlValid && start && end;
  };

  const getBlockOptions = async () => {
    const data = (await getDealOptions(
      blockModel?.boardId as number,
      blockModel?.id as number,
    )) as unknown as DealOptionDto[];
    setDealOptions(data ?? []);
  };

  useEffect(() => {
    if (localModel) {
      validation();
    }
  }, [localModel, stateModel]);

  useEffect(() => {
    if (stateModel) {
      console.log(stateModel);
    }
  }, [stateModel]);

  useEffect(() => {
    if (blockModel) {
      const blockItems = blockModel?.items?.map((item) => ({
        ...item,
        ...(parseJSONAll(item?.metadata) as DealServiceItemModel),
      }));
      const newBlockModel = { ...blockModel, items: blockItems } as IDealBlock;
      initialBinding(newBlockModel);
      setMultipleState({
        useInquiry: newBlockModel?.useInquiry,
        useTimeDeal: newBlockModel?.useTimeDeal,
      });

      getBlockOptions();
    } else {
    }
  }, [blockModel]);

  useEffect(() => {
    setCategoryBoard();
    return () => {
      unmountModel();
    };
  }, []);

  useEffect(() => {
    console.log(initialModel);
  }, [initialModel]);

  return (
    <div style={{}}>
      <ListBoxItem
        title={
          <Row style={{ width: 'unset' }}>
            <>링크주소</>
            <Required />
          </Row>
        }
      >
        <Row style={{ alignItems: 'flex-start', gap: 10 }}>
          <div style={{ width: 'auto' }}>
            <ImageUploader
              type="single"
              useCrop={false}
              useCircularCrop={false}
              initialValue={initialModel?.thumbnail}
              attachType="image"
              onSelected={(image: CustomFile) => {
                updateServiceModel('thumbnail', image);
              }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Row style={{ width: 'unset', justifyContent: 'flex-start' }}>
              <UrlForm
                onPaste={handlePaste}
                onChangeValue={(value: string) => {
                  updateServiceModel('linkUrl', value);
                  setLocalModel('linkUrl', value);
                }}
                initialValue={initialModel?.linkUrl}
                label="링크주소"
              />
            </Row>
            <>
              <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ marginRight: 5 }}>
                  {siteInfo?.faviconUrl && localModel?.showIcon && (
                    <img src={siteInfo?.faviconUrl} style={{ width: 16 }} alt="" />
                  )}
                </div>
                <div>
                  {siteInfo?.faviconUrl && (
                    <MenuItemCheckBox
                      initialValue={initialModel?.showIcon || true}
                      onChangeValue={(value: boolean) => {
                        setState('showIcon', value);
                        setLocalModel('showIcon', value);
                      }}
                      title="링크 아이콘(파비콘) 보이기"
                    />
                  )}
                </div>
              </Row>
            </>
          </div>
        </Row>
      </ListBoxItem>
      <ListBoxItemTemplate
        style={{ flexDirection: 'row', flexWrap: 'wrap', display: 'flex', gap: 20 }}
      >
        <div style={{ flex: 1.5 }}>
          <ItemSectionTitle>
            타이틀
            <Required />
          </ItemSectionTitle>
          <ItemContent>
            <TitleForm
              ref={titleRef}
              onChangeValue={(value: string) => {
                updateServiceModel('title', value);
                setLocalModel('title', value);
              }}
              initialValue={initialModel?.title}
              placeHolder="타이틀을 작성해 주세요"
              label="타이틀"
            />
          </ItemContent>
        </div>
        <div style={{ flex: 0.5 }}>
          <ItemSectionTitle>
            <Row style={{ justifyContent: 'space-between' }}>
              <Row style={{ width: 'unset', justifyContent: 'flex-start' }}>
                <div>등록할 페이지</div>
                <Required />
              </Row>
              <AddIcon onClick={handleClickNewCategory} />
            </Row>
          </ItemSectionTitle>
          <ItemContent style={{ marginTop: 0 }}>
            {categories?.length > 0 ? (
              <>
                <FormControl fullWidth>
                  <Select
                    defaultValue={blockModel?.boardId}
                    onChange={(e, data: any) => {
                      setState('boardId', data?.props?.value);
                    }}
                  >
                    {categories
                      ?.filter((category) => category.boardType === 'LINK')
                      .map((category: ChannelBoardDto) => (
                        <SelectMenuITem value={category?.id}>{category.name}</SelectMenuITem>
                      ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <div>
                <OhmossButton type="square" onClick={handleClickNewCategory}>
                  새 페이지 만들기
                </OhmossButton>
              </div>
            )}
          </ItemContent>
        </div>
      </ListBoxItemTemplate>
      <ListBoxItemTemplate>
        <WebEditor
          ref={editorRef}
          onEditorChange={(contents: string) => {}}
          initialValue={initialModel?.content}
        />
        <div style={{ marginTop: 10 }}>
          <OhmossAI onClick={handleClickAI} />
        </div>
      </ListBoxItemTemplate>
      <Row style={{ alignItems: 'flex-start', gap: 20 }}>
        <div style={{ flex: 1 }}>
          <ListBoxItem
            title={
              <Row style={{ width: 'unset' }}>
                <>공구기간 설정</>
                <Required />
              </Row>
            }
          >
            <ItemContent>
              <TimeDealPicker
                mode={initialModel?.id ? 'EDIT' : 'NEW'}
                timePickerType={initialModel?.timeType ?? 'time'}
                start={initialModel?.startDate}
                end={initialModel?.endDate}
                timeRange={handleTimeRangeChange}
              />
            </ItemContent>
          </ListBoxItem>
          {/* 고객문의 활성화 */}
          <ItemUseInquiry
            useInquiry={initialModel?.useInquiry}
            onUpdate={(value: boolean) => {
              updateServiceModel('useInquiry', value);
            }}
          />
          {/* 공구 옵션 설정 */}
          <ItemCustomerInquiryBox
            options={dealOptions}
            onUpdate={(options) => {
              setDealOptions(options);
            }}
          />
          {/* 고객서비스 설정 */}
          <ItemCustomerService
            onUpdate={(type, items) => {
              updateServiceModel('items', items?.items);
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ItemStickerBox />
          <ListBoxItem style={{ flex: 1 }} title="레이아웃">
            <DealLayout
              useTitle={false}
              initialValue={initialModel?.layout || 'TypeA'}
              onChange={(layout: Layout) => updateServiceModel('layout', layout)}
            />
          </ListBoxItem>
          <ListBoxItem style={{ flex: 1 }} title="타이틀 정렬">
            <CommonAlign
              useTitle={false}
              initialValue={initialModel?.contentAlign || 'center'}
              onChange={(value: string) => {
                updateServiceModel('contentAlign', value);
              }}
            />
          </ListBoxItem>
        </div>
      </Row>

      <Row style={{ justifyContent: 'space-evenly', gap: 20 }} />
      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000', borderRadius: 8 }}
          onClick={() => {
            setPageState('pageMode', undefined);
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          type="primaryPc"
          onClick={async () => {
            if (serviceModel) {
              updateServiceModel('content', editorRef.current.getData());
              const lastCheck = await checkValidation();
              const result = (await updateBlock(serviceModel)) as unknown as IDealBlock;
              if (result) {
                onUpdate?.();
                if (serviceModel.boardId && result?.id) {
                  await saveDealOptions(
                    serviceModel.boardId || (serviceModel.boardId as number),
                    result?.id as number,
                    dealOptions || [],
                  );
                  uiStore.modal.confirm();
                }
              }
            }
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton>
      </Row>
    </div>
  );
};

export default Deal;
