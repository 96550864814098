import React, { useEffect, useState } from 'react';
import { DealOptionDto, LinkDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { ListBoxItem, ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import { IDealBlock } from 'types/BlockExtendsTypes';

import styled from 'styled-components';
import BlockController from 'controllers/BlockController';
import Countdown from 'react-countdown';
import DealTemplate from 'features/management/components/block/ListItemTemplates/DealTemplate';
import ItemStickerBox from 'features/csTown/commonComponents/ItemStickerBox';
import ItemCustomerService from 'features/csTown/commonComponents/ItemCustomerService';
import ItemCustomerInquiryBox from './components/ItemCustomerInquiryBox';
import { usePageModelContext } from 'features/management/context/usePageModelContext';
import { toast } from 'react-toastify';
import ItemUseInquiry from './components/ItemUseInquiry';
import TimeDisplay from '../TimeDisplay';
import { useStateContext } from 'libs/hooks/usePageState';
import { MyDealState } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';

const DealDetail = ({
  deal,
  onEditMode,
}: {
  deal?: LinkDto;
  onEditMode: (isEdit: boolean) => void;
}) => {
  const { getBlockByLinkKey, getDealOptions, updateBlock, saveDealOptions } =
    BlockController<IDealBlock>();
  const { initialBinding, unmountModel } = usePageModelContext<IDealBlock>();
  const { setPageState, stateModel } = useStateContext<MyDealState>();
  const { myChannel } = useRootContext();
  const [dealOptions, setDealOptions] = useState<DealOptionDto[]>();

  const getPost = async () => {
    const tempDeal = (await getBlockByLinkKey(deal?.linkKey as string)) as unknown as LinkDto;
    initialBinding(tempDeal);
    getBlockOptions(tempDeal);
    setPageState('selectedDealLink', tempDeal);
  };

  const getBlockOptions = async (deal?: LinkDto) => {
    const data = (await getDealOptions(
      deal?.boardId as number,
      deal?.id as number,
    )) as unknown as DealOptionDto[];
    setDealOptions(data ?? []);
  };

  const handleUpdate = async (type: any, value: any) => {
    const newDeal = { ...stateModel?.selectedDealLink, ...value };
    await updateBlock(newDeal);
    getPost();
  };

  const handleUpdateOptions = async (options: DealOptionDto[]) => {
    await saveDealOptions(
      stateModel?.selectedDealLink?.boardId as number,
      stateModel?.selectedDealLink?.id as number,
      options,
    );
    //toast('저장되었습니다.');
    getPost();
  };

  // cstwon - 내 공구목록 - 상세 데이터 : props 로 넘겨받은 후 랜더링
  useEffect(() => {
    if (deal) {
      getBlockOptions(deal);
      setPageState('selectedDealLink', deal);
    }
  }, [deal]);

  // cstwon - 내 공구목록 - 상세 데이터 : 업데이트에 따른 랜더링
  useEffect(() => {
  }, [stateModel?.selectedDealLink]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{}}>
      <Row style={{ alignItems: 'flex-start', gap: 15 }}>
        <div style={{ flex: 1 }}>
          <Row style={{ justifyContent: 'flex-start', gap: 20, alignItems: 'flex-start' }}>
            <div style={{ flex: 0.4 }}>
              <ListBoxItem style={{ overflow: 'hidden', padding: 0 }}>
                <>
                  {stateModel?.selectedDealLink && (
                    <DealTemplate
                      item={stateModel?.selectedDealLink as IDealBlock}
                      editMode={false}
                    />
                  )}
                </>
              </ListBoxItem>
              <ItemStickerBox
                useModify={stateModel?.selectedDealLink?.channel?.id === myChannel?.id}
                stickers={stateModel?.selectedDealLink?.stickers}
                onUpdate={handleUpdate}
              />
              <ItemUseInquiry
                readonly={stateModel?.selectedDealLink?.channel?.id !== myChannel?.id}
                useInquiry={stateModel?.selectedDealLink?.useInquiry}
                onUpdate={(value: boolean) => {
                  handleUpdate('', { useInquiry: value });
                }}
              />
              <ItemCustomerInquiryBox
                readonly={stateModel?.selectedDealLink?.channel?.id !== myChannel?.id}
                options={dealOptions}
                onUpdate={handleUpdateOptions}
              />
              <ItemCustomerService
                readonly={stateModel?.selectedDealLink?.channel?.id !== myChannel?.id}
                items={stateModel?.selectedDealLink?.items}
                onUpdate={handleUpdate}
              />
            </div>
            <div style={{ flex: 0.6 }}>
              <ListBoxItemTemplate style={{ padding: '20px 0', marginBottom: 20 }}>
                <Row style={{ justifyContent: 'space-evenly' }}>
                  <TimeDisplay title="공구시작일" date={deal?.startDate} />
                  <TimeDisplay title="공구종료일" type="END" date={deal?.endDate} />
                  <div>
                    {deal?.status === 'ONGOING' && (
                      <CountdonwContainer>
                        {deal && (
                          <Countdown daysInHours date={deal?.endDate} onComplete={() => {}} />
                        )}
                      </CountdonwContainer>
                    )}
                  </div>
                </Row>
              </ListBoxItemTemplate>
              <ListBoxItemTemplate
                style={{ overflow: 'hidden', flex: 0.4, padding: 20, height: 'auto' }}
              >
                {stateModel?.selectedDealLink?.content === '' ? (
                  <div
                    style={{
                      height: 300,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    등록된 내용이 없습니다.
                  </div>
                ) : (
                  <div
                    style={{ overflow: 'scroll', minWidth: '100%' }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: stateModel?.selectedDealLink?.content as string,
                    }}
                  />
                )}
              </ListBoxItemTemplate>
            </div>
          </Row>
        </div>
      </Row>
    </div>
  );
};

const CountdonwContainer = styled.div`
  min-width: 200px;
  text-align: center;
  background: #173300;

  padding: 5px 10px;
  border-radius: 10px;
  > span {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 5px;
    color: #96e06e;
    font-family: 'Zen Dots';
  }
`;

export default DealDetail;
