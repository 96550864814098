import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import { XScrollBox, XScrollItem } from 'components/commons/XScrollBox.tsx';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { BlockContainerStyle } from '../common/BlockContainerStyle';
import { useStore } from 'stores/StoreHelper';
import useLocalParams from 'libs/hooks/useLocalParams';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { ChannelBoardModel } from 'types/CommonTypes';
import { ChannelBoardDto } from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStateContext } from 'libs/hooks/usePageState';

type IChannelProfileWithId = any;
const CategoryTabView = ({
  item,
  channelBoards,
  editMode = true,
  onClick,
}: {
  item: IChannelProfileWithId;
  channelBoards?: ChannelBoardModel[];
  editMode?: boolean;
  onClick: () => void;
}) => {
  const { uiLinkStore } = useStore();
  const { navigate } = useNavigation();
  const { channelName } = useLocalParams();
  const { setCategoryBoard } = useChannelContext();
  const { broadcast } = useStateContext();

  const [categories, setCategories] = useState<XScrollItem<ChannelBoardModel>[]>();
  const [selectedCategory, setSelectedCategory] = useState<XScrollItem<ChannelBoardModel>>();

  const initialize = async () => {
    const newCategories = channelBoards
      ?.filter((item: ChannelBoardDto) => item.boardType !== 'SHOP')
      ?.map((item: ChannelBoardModel) => {
        return {
          item: item,
          title: item.name,
          thumbnail: item.thumbnail?.publicUrl ?? '/images/ohmossc.png',
          seq: item.seq,
        };
      });
    setCategories(newCategories);
  };

  useEffect(() => {
    if (editMode !== true && selectedCategory) {
      setCategoryBoard(selectedCategory.item as ChannelBoardModel);
      if (channelName) {
        if (selectedCategory.item?.isDeal) {
          navigate(`/${channelName}/1`);
        } else {
          navigate(`/${channelName}/${selectedCategory.item?.id}`);
        }
      } else {
        broadcast({ id: 'ACTION', param: { categoryId: selectedCategory?.item?.id } });
        // navigate(`/management/categories/${selectedCategory.item?.id}`);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (item && channelBoards) initialize();
  }, [item, channelBoards]);

  return (
    <>
      {categories && categories?.length > 1 && (
        <BlockContainerStyle
          style={{
            backgroundColor: item?.categoryTabInfo?.useTransparent ? 'transparent' : '#fff',
            borderRadius: uiLinkStore.myStyle?.boxStyle?.borderRadius,
            boxShadow: item?.useTransparent
              ? 'transparent'
              : uiLinkStore.myStyle?.boxStyle?.boxShadow,
            overflow: 'hidden',
            marginBottom: item?.id === 1 ? 0 : categories?.length === 1 ? 0 : '20px',
          }}
        >
          <ContainerStyle>
            {editMode === true && (
              <div style={{ justifyContent: 'flex-end', textAlign: 'right', padding: '10px ' }}>
                <IconButton
                  onClick={() => {
                    onClick();
                  }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            )}
            <XScrollBox onSelectedItem={setSelectedCategory} items={categories} />
          </ContainerStyle>
        </BlockContainerStyle>
      )}
    </>
  );
};

const ContainerStyle = styled.div<{ useTransparent?: boolean }>`
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  min-height: 90px;
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
`;

export default CategoryTabView;
