import SearchBox from 'components/commons/SearchBox';
import { CSTownMobileLayout } from 'features/csTown/commonComponents/styles';
// eslint-disable-next-line max-len
import CSTownChecklistController from 'features/csTown/pages/pageControllers/CSTownChecklistController';
import ChecklistContainer from 'features/myPage/containers/checklist/ChecklistContainer';
import { useStateContext } from 'libs/hooks/usePageState';
import { isNumber } from 'lodash';
import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OhmossButton } from 'resources/controls/OhmossButton';
import styled from 'styled-components';
import { CSTownChecklistState } from 'types/CommonTypes';

const CSTownChecklistContainer = () => {
  const { stateModel, setPageState } = useStateContext<CSTownChecklistState>();
  const { createCheckList, clickItem, search } = CSTownChecklistController();

  return (
    <div style={{ minHeight: '100%' }}>
      <SearchBox onEntered={search} onCancel={() => search('')} />
      <div>
        <ChecklistContainer
          checklist={stateModel?.checklists}
          onClickItem={(item: any) => {
            clickItem(item);
          }}
        />
      </div>
      <BottomButtonStyle>
        <OhmossButton style={{ marginTop: 10 }} onClick={createCheckList} type="primaryPc">
          체크리스트 만들기
        </OhmossButton>
      </BottomButtonStyle>
    </div>
  );
};

const BottomButtonStyle = styled.div`
  position: fixed;
  bottom: 70px;
  left: 20px;
  right: 20px;
`;

export default CSTownChecklistContainer;
