import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { Avatar, IconButton } from '@mui/material';
import { useFollowContext } from 'providers/follow/useFollowContext';
import { t } from 'i18next';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react-lite';
import useLocalParams from 'libs/hooks/useLocalParams';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import useToken from 'libs/hooks/useToken';
import useNavigation from 'hooks/useNavigation';
import ProposalController from 'controllers/ProposalController';
import { ChannelProposalConfigDto, ChannelProposalTypeDto } from 'services/data-contracts';
import { BlockContainerStyle } from '../common/BlockContainerStyle';
import BusinessProposalForm from '../block/BusinessProposalForm';

const ProfileTemplate = observer(
  ({
    item,
    onClick,
    onActiveChange,
    editMode = true,
  }: {
    item: any;
    onClick: () => void;
    onActiveChange?: (result: boolean) => void;
    editMode?: boolean;
  }) => {
    const { uiLinkStore, uiStore } = useStore();
    const { channelName } = useLocalParams();
    // const { board } = useOhmossContext<LinkServiceType>();
    const { currentChannel, categoryBoard } = useChannelContext();

    const { follow, followInfo } = useFollowContext();
    // const context = useGlobalContext();
    const [searchParams] = useSearchParams();
    const { validUser } = useToken();
    const { navigate } = useNavigation();
    const { getChannelProposalType } = ProposalController();

    const [isActive, setIsActive] = useState<boolean>();
    const [isFollow, setIsFollow] = useState<boolean | undefined>(false);
    const [profileImageRatio, setProfileImageRatio] = useState<string>();

    const [proposalLabel, setProposalLabel] = useState<string>('');

    const getProposalTitle = async () => {
      const optionsTemp = (await getChannelProposalType()) as unknown as any;
      setProposalLabel(optionsTemp?.label ?? '');
    };

    useEffect(() => {
      getProposalTitle();
    }, []);
    const TypeA = () => {
      return (
        <TypeAContainer style={{ aspectRatio: profileImageRatio }}>
          <Avatar
            src={item?.profileImage?.publicUrl}
            style={{ width: '112px', height: '112px', textAlign: 'center' }}
          />
          <AlignStyle
            style={
              item?.contentAlign === 'left'
                ? { paddingRight: 25 }
                : item?.contentAlign === 'right'
                ? { paddingLeft: 25 }
                : {}
            }
            align={item?.contentAlign || 'center'}
          >
            <NicknameStyle style={{ marginTop: '20px', ...item?.profileStyle }}>{item?.nickname}</NicknameStyle>
            <div style={{ whiteSpace: 'pre-wrap', fontWeight: 400, ...item?.profileStyle }}>{item?.introduction}</div>
          </AlignStyle>
        </TypeAContainer>
      );
    };

    const TypeB = () => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <SquareImageStyle url={item?.profileImage?.publicUrl} />
          <AlignStyle
            style={
              item?.contentAlign === 'left'
                ? { paddingLeft: 25 }
                : item?.contentAlign === 'right'
                ? { paddingRight: 25 }
                : {}
            }
            align={item?.contentAlign || 'center'}
          >
            <NicknameStyle>{item?.nickname}</NicknameStyle>
            <div style={{ whiteSpace: 'pre-wrap', marginBottom: 20, fontWeight: 400 }}>
              {item?.introduction}
            </div>
          </AlignStyle>
        </div>
      );
    };

    const getComponent = () => {
      switch (item?.layout) {
        case 'TypeB':
          return <TypeB />;
        default:
          return <TypeA />;
      }
    };

    const handleClickFollow = async () => {
      if (channelName && currentChannel) {
        const result = await follow(channelName, isFollow ?? true);
        if (typeof result !== 'undefined') {
          setIsFollow(!isFollow);
        }
      }
    };

    const handleClickBusinessProposal = () => {
      if (channelName && currentChannel) {
        if (!editMode && validUser()) {
          uiStore.modal.show({
            style: {
              maxHeight: '80vh',
            },
            title: proposalLabel ?? '비즈니스 제안하기',
            children: <BusinessProposalForm />,
          });
        } else {
          uiStore.confirm.show({
            message: t('A245'),
            onConfirmed: async () => {
              navigate(`/login`);
            },
          });
        }
      }
    };

    useEffect(() => {
      if (followInfo) {
        setIsFollow(followInfo.isFollow);
      }
    }, [followInfo]);

    useEffect(() => {
      if (item) {
        setIsActive(categoryBoard?.useProfile ?? true);
        setProfileImageRatio(
          item?.profileBackgroundImage && item?.useTransparent !== true ? '1 / 1' : 'unset',
        );
      }
    }, [item]);

    useEffect(() => {
      if (currentChannel) {
        setIsFollow(currentChannel.isFollow);
      }
    }, [currentChannel]);

    useEffect(() => {
      if (categoryBoard) {
        setIsActive(categoryBoard?.useProfile ?? true);
      }
    }, [categoryBoard]);

    useEffect(() => {
      if (categoryBoard) {
        setIsActive(categoryBoard?.useProfile ?? true);
      }
    }, [categoryBoard]);

    useEffect(() => {
      if (channelName) {
        const action = searchParams.get('action');
        if (action === 'follow') {
          if (currentChannel?.isFollow === true) {
            toast('이미 팔로우 중인 채널입니다.');
          } else {
            handleClickFollow();
          }
          window.history.replaceState('', '', `/${channelName}`);
        }
      }
    }, [channelName]);

    return (
      <>
        {editMode === true ? (
          <ContainerStyle>
            <div>
              <Row style={{ justifyContent: 'flex-end', padding: '10px' }}>
                <IconButton
                  onClick={() => {
                    onClick();
                  }}
                >
                  <EditIcon />
                </IconButton>
                <>
                  <MUISwitch
                    checked={isActive}
                    onChange={(e: any, value: boolean) => {
                      if (onActiveChange) {
                        onActiveChange(value);
                        setIsActive(value);
                      }
                    }}
                  />
                </>
              </Row>
              <BlockContainerStyle
                style={{
                  margin: '0px',
                  backgroundImage:
                    // eslint-disable-next-line no-nested-ternary
                    item?.profileBackgroundImage && item?.useTransparent === true
                      ? 'none'
                      : item?.profileBackgroundImage && item?.useTransparent === false
                      ? `url(${item?.profileBackgroundImage?.publicUrl})`
                      : '',
                  // borderRadius:
                  //   item?.useTransparent === false
                  //     ? uiLinkStore.myStyle?.boxStyle?.borderRadius
                  //     : '',
                  // boxShadow:
                  //   item?.useTransparent === false ? uiLinkStore.myStyle?.boxStyle?.boxShadow : '',
                  overflow: 'hidden',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  // marginBottom: item?.id === 1 ? 0 : '20px',
                  backgroundColor: item?.useTransparent === true ? 'transparent' : '',
                }}
              >
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div style={{ ...item?.profileStyle }}>{getComponent()}</div>
                </div>
              </BlockContainerStyle>
            </div>
          </ContainerStyle>
        ) : (
          <div>
            {isActive === true && (
              <BlockContainerStyle
                style={{
                  backgroundImage:
                    // eslint-disable-next-line no-nested-ternary
                    item?.profileBackgroundImage && item?.useTransparent === true
                      ? 'none'
                      : item?.profileBackgroundImage && item?.useTransparent === false
                      ? `url(${item?.profileBackgroundImage?.publicUrl})`
                      : '',
                  borderRadius:
                    item?.useTransparent === false
                      ? uiLinkStore.myStyle?.boxStyle?.borderRadius
                      : '',
                  boxShadow:
                    item?.useTransparent === false ? uiLinkStore.myStyle?.boxStyle?.boxShadow : '',
                  overflow: 'hidden',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  marginBottom: item?.id === 1 ? 0 : '20px',
                  backgroundColor: item?.useTransparent === true ? 'transparent' : '',
                }}
              >
                <div style={{ width: '100%' }}>
                  <div style={{ ...item?.profileStyle }}>{getComponent()}</div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {item?.isFollowAllow === true && !currentChannel?.isOwner && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 15,
                          paddingBottom: 20,
                        }}
                      >
                        <FollowStyle onClick={handleClickFollow}>
                          <Row>
                            {isFollow === true ? (
                              <FavoriteIcon style={{ color: 'red' }} />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                            <div style={{ marginLeft: 10 }}>
                              {isFollow === true ? '팔로잉' : '팔로우'}
                            </div>
                          </Row>
                        </FollowStyle>
                        {item.useProposal === true && !currentChannel?.isOwner && (
                          <BusinessProposalContainer onClick={handleClickBusinessProposal}>
                            비지니스 제안하기
                          </BusinessProposalContainer>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </BlockContainerStyle>
            )}
          </div>
        )}
      </>
    );
  },
);

const ContainerStyle = styled.div`
  border-radius: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const TypeAContainer = styled.div`
  padding: 38px 98px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${(props) => props.theme.media.mobile} {
    padding: 20px;
  }
`;

const AlignStyle = styled.div<{ align: string }>`
  text-align: ${(props) => props.align};
`;

const FollowStyle = styled.div`
  display: flex;
  width: 100px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #efefef;
  background: #fff;
  font-size: 15px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
`;

const SquareImageStyle = styled.div<{ url: string }>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 10px 20px;
  height: 200px;
  border-radius: 12px;
`;

const NicknameStyle = styled.div`
  /* color: #000; */
`;

const BusinessProposalContainer = styled.div`
  border-radius: 30px;
  border: 1px solid #efefef;
  padding: 8px 20px;
  color: #333;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background-color: #fff;
  cursor: pointer;
`;

export default memo(ProfileTemplate);
