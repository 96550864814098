import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import { IconButton } from '@mui/material';
import { LinkDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import DeleteButton from 'resources/controls/DeleteButton';
import { Row } from 'components/commons/layouts';
import { on } from 'events';

type Props = {
  icon?: string;
  initialActive?: boolean;
  confirmMessage?: string; //Active 용
  useConfirm?: boolean;
  item?: LinkDto;
  onModify: () => void;
  onDelete: () => void;
  onActiveChange: (isActive: boolean) => void;
};

const ListItemHeader = ({
  icon,
  initialActive,
  onModify,
  onDelete,
  onActiveChange,
  useConfirm = false,
  confirmMessage,
}: Props) => {
  const [isActive, setIsActive] = useState<boolean>();

  const handleChangeActive = (isActive: boolean) => {
    onActiveChange?.(isActive);
  };

  const handleClickDelete = () => {
    onDelete?.();
  };

  useEffect(() => {
    if (typeof initialActive !== 'undefined') {
      setIsActive(initialActive);
    }
  }, [initialActive]);

  return (
    <Row style={{ width: 'unset', justifyContent: 'space-between' }}>
      <div style={{ marginLeft: 30 }}>
        <img src={icon} alt="" />
      </div>
      <Row style={{ width: 130 }}>
        <IconButton onClick={onModify}>
          <EditIcon />
        </IconButton>
        <div style={{ marginRight: 7 }}>
          <DeleteButton onClick={handleClickDelete} />
        </div>
        <MUISwitch
          useConfirm={useConfirm}
          message={confirmMessage}
          // defaultChecked={item?.isActive}
          checked={isActive}
          onChange={(e: any, value: boolean) => {
            handleChangeActive(value);
            setIsActive(value);
          }}
        />
      </Row>
    </Row>
  );
};

export default ListItemHeader;
