import React, { useEffect, useState } from 'react';

import BlockController from 'controllers/BlockController';
import { LinkDto } from 'services/data-contracts';
import useLocalParams from 'libs/hooks/useLocalParams';
import styled from 'styled-components';
import DashboardChartController from 'controllers/DashboardChartController';
import { CountDto, DailyCountSeriesDto, LocationCountSeriesDto } from 'services/data-contracts';
import moment from 'moment';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import LineChart from '../components/dashboard/LineChart';
import BarChart from '../components/dashboard/BarChart';
import DonutChart from '../components/dashboard/DonutChart';
import DealReviews from '../components/DealReviews';
import DealRecords from '../components/DealRecords';
import DealInfo2 from '../components/DealInfo2';
import { useRootContext } from 'libs/hooks/useRootContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiToggleButton-root': {
      color: '#BEBEBE',
      '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: '#EFEFEF',
        color: '#23BA50',
      },
    },
  },
}));

const DealDashBoardPage = () => {
  const { getBlockByLinkKey } = BlockController<LinkDto>();
  const { myChannel } = useRootContext();
  const {
    getRegionClicks,
    getDailyClicks,
    getRefererClickCountsByDeal,
    getDailyFollowerCountsByChannel,
    getDailyVisitCountsByChannel,
  } = DashboardChartController();
  const { linkKey } = useLocalParams();
  const classes = useStyles();

  const [dealBlock, setDealBlock] = useState<LinkDto>();
  const [visitCount, setVisitCount] = useState<CountDto[]>(); // 총 채널 방문자 수
  const [clickCount, setClickCount] = useState<CountDto[]>(); // 일일 딜 클릭 수
  const [followers, setFollowers] = useState<CountDto[]>(); // 팔로워 수
  const [region, setRegion] = useState<LocationCountSeriesDto[]>(); // 지역
  const [referer, setReferer] = useState<DailyCountSeriesDto[]>(); // 집입경로
  const [dashboardToggle, setDashboardToggle] = useState('딜 데이터 분석');

  const getDealBlock = async () => {
    if (linkKey && myChannel) {
      const block = await getBlockByLinkKey(linkKey);
      setDealBlock(block);
    }
  };

  const getAnalytics = async () => {
    if (dealBlock) {
      // Get today's date
      const endDate = moment(dealBlock.endDate).format('YYYY-MM-DD');
      const startDate = moment(dealBlock.startDate).format('YYYY-MM-DD');

      // 총 채널 방문자 수
      const dailyVisitCounts = (await getDailyVisitCountsByChannel(
        startDate,
        endDate,
      )) as unknown as CountDto[];
      setVisitCount(dailyVisitCounts as CountDto[]);
      // 일일 딜 클릭 수
      const dailyClicks = (await getDailyClicks(dealBlock.id as number)) as unknown as CountDto[];
      setClickCount(dailyClicks as CountDto[]);
      // 팔로워 수
      const followers = (await getDailyFollowerCountsByChannel(
        startDate,
        endDate,
      )) as unknown as CountDto[];
      setFollowers(followers ?? []);
      // 지역
      const regionClicks = (await getRegionClicks(
        dealBlock.id as number,
      )) as unknown as LocationCountSeriesDto[];
      setRegion(regionClicks ?? []);
      // 집입경로
      const refererClicks = (await getRefererClickCountsByDeal(
        dealBlock.id as number,
      )) as unknown as DailyCountSeriesDto[];

      const updatedRefererClicks = refererClicks.map((item: LocationCountSeriesDto) => {
        if (item.location === '') {
          return { ...item, location: 'ohmoss' }; // title이 빈 문자열인 경우 'test'로 변경
        } else {
          return item; // title이 빈 문자열이 아닌 경우 변경하지 않음
        }
      });
      setReferer(updatedRefererClicks ?? []);
    }
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, toggleValue: string) => {
    setDashboardToggle(toggleValue);
  };

  useEffect(() => {
    getDealBlock();
  }, [linkKey, myChannel]);

  useEffect(() => {
    if (dealBlock) {
      getAnalytics();
    }
  }, [dealBlock]);

  return (
    <div style={{ marginBottom: 100 }}>
      {/* <DetailPageHeader title={'딜 대시보드'} /> */}
      <div>
        <ToggleButtonGroup
          className={classes.root}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: 40,
            marginTop: 2,
            marginBottom: 2,
          }}
          color="primary"
          value={dashboardToggle}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            style={{
              padding: '10px 15px',
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            }}
            value="딜 데이터 분석"
          >
            딜 데이터 분석
          </ToggleButton>
          <ToggleButton
            style={{
              padding: '10px 15px',
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
            }}
            value="딜 리뷰 기록"
          >
            딜 리뷰 기록
          </ToggleButton>
        </ToggleButtonGroup>
        <DealInfo2 item={dealBlock} cutOff={1000} onClick={() => {}} />
      </div>
      {dashboardToggle === '딜 데이터 분석' ? (
        <>
          <ChartContainer>
            <LineChart title="클릭 및 접속" clickCount={clickCount} visitCount={visitCount} />
          </ChartContainer>
          <ChartContainer>
            <BarChart title="Follower 추이" data={followers} />
          </ChartContainer>
          <ChartContainer>
            <DonutChart title="접속지역" subTitle="상위 5개지역" data={region} />
          </ChartContainer>
          <ChartContainer>
            <DonutChart
              title="진입경로"
              subTitle={typeof referer === 'undefined' ? '' : '상위 4개사이트'}
              data={referer}
            />
          </ChartContainer>
        </>
      ) : (
        <>
          <DealRecords />
          <DealReviews />
        </>
      )}
    </div>
  );
};

const ChartContainer = styled.div`
  padding: 20px;
`;

export default DealDashBoardPage;
