/* eslint-disable no-nested-ternary */
import { Avatar } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { ProposalDto } from 'services/data-contracts';
import React from 'react';
import styled from 'styled-components';
import NoteIcon from '@mui/icons-material/Note';

interface ProposalBoxProps {
  inquiry?: ProposalDto;
  inquiryType?: 'RECEIVED' | 'SENT';
}

const ProposalBox: React.FC<ProposalBoxProps> = ({ inquiry, inquiryType }) => {
  return (
    <Row style={{ flex: 1, width: 'unset', justifyContent: 'flex-start' }}>
      <Avatar
        style={{ width: 50, height: 50 }}
        src={
          inquiryType === 'RECEIVED'
            ? inquiry?.proposerChannel?.profile?.profileImage?.publicUrl
            : inquiry?.recipientChannel?.profile?.profileImage?.publicUrl
        }
      />
      <div>
        <TitleStyle className="item-title" style={{ marginLeft: 20 }}>
          {inquiry?.requestType === 'BLOCK' && inquiryType === 'RECEIVED'
            ? `${inquiry?.sender}`
            : inquiry?.requestType === 'CHANNEL' && inquiryType === 'RECEIVED'
            ? `${inquiry?.company}`
            : ''}
          {inquiryType === 'SENT' && inquiry?.requestType === 'BLOCK'
            ? `${inquiry?.recipientChannel?.channelName}`
            : inquiryType === 'SENT' && inquiry?.requestType === 'CHANNEL'
            ? `${inquiry?.recipientChannel?.channelName}`
            : ''}
        </TitleStyle>
        <div className="sub-title" style={{ marginTop: 10 }}>
          <Row style={{ justifyContent: 'flex-start' }}>
            {/* <NoteIcon /> */}
            <ContentStyle>
              {inquiry?.requestType === 'BLOCK' ? `${inquiry?.title}` : `${inquiry?.description}`}
            </ContentStyle>
          </Row>
        </div>
      </div>
    </Row>
  );
};

const TitleStyle = styled.div`
  margin-left: 20px;
`;

const ContentStyle = styled.div`
  margin-left: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ProposalBox;
