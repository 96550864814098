import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'stores/StoreHelper';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { ChannelBoardDto } from 'services/data-contracts';
import { useGlobalContext } from 'context/useGlobalContext';

import styled from 'styled-components';
import useToken from 'libs/hooks/useToken';
import { ReactComponent as CSTownImage } from 'resources/images/commons/cstown.svg';
import { ReactComponent as MessageImage } from 'resources/images/commons/message.svg';
import FollowListContainer from './follower/container/FollowListContainer';

const ManagerCSTownNavigationTabBar = ({ board }: { board?: ChannelBoardDto | null }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { uiStore } = useStore();
  const { validUser } = useToken();
  const { report, isLoggedIn } = useGlobalContext();
  const [searchParam] = useSearchParams();
  const { navigate } = useNavigation();

  const showLoginConfirm = (referrer: string, params?: string) => {
    uiStore.confirm.show({
      message: t('A245'),
      onConfirmed: async () => {
        navigate(`/login?action=(${referrer}.${params})`);
      },
    });
  };

  const handleClickFollow = () => {
    const result = validUser();

    if (result) {
      uiStore.universalModal.show({
        children: <FollowListContainer />,
        useHeader: false,
        bottomSheetOptions: { blocking: true },
        modalOption: { style: { maxWidth: 500, maxHeight: 500 } },
      });
    } else {
      showLoginConfirm('3');
    }
  };

  useLayoutEffect(() => {
    const action = searchParam.get('action');
    if (action) {
      //   handleAction(action);
    }
  }, [location]);

  if (window.isWebView) {
    return <></>;
  }
  return (
    <>
      <GNBBottomStyle>
        <div
          onClick={() => {
            // reset('/');
            // getAds({ ownChannelId: myChannelStore.currentChannel?.id || '' });
            // setCategory(undefined);
            // window.history.replaceState('', '', '/');
          }}
        >
          <MenuFontStyle>
            <MessageImage />
            메시지
          </MenuFontStyle>
        </div>
        <div
          onClick={() => {
            navigate('/cstown');
          }}
        >
          <MenuFontStyle disabled={!isLoggedIn}>
            <CSTownImage />
            CS Town
          </MenuFontStyle>
        </div>
      </GNBBottomStyle>
    </>
  );
};

const GNBBottomStyle = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  gap: 40px;
  max-width: 550px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e6e6e6;
  background: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin: auto;
  > div {
    cursor: pointer;
  }

  @media ${(props) => props.theme.media.mobile} {
    border-radius: 0;
  }
`;

const NewButtonStyle = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  background: #23ba50;
  border-radius: 20px;
  padding: 5px 13px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const MenuFontStyle = styled.div<{ disabled?: boolean }>`
  font-size: 10px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export default ManagerCSTownNavigationTabBar;
