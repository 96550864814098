import React, { useEffect, useState } from 'react';
import { ListBoxItem } from 'features/csTown/commonComponents/styles';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Row } from 'components/commons/layouts';
import { Avatar } from '@mui/material';
import Text from 'components/commons/Text';
import LineChart from 'features/myPage/components/dashboard/LineChart';
import MyAnalyticsStore from 'stores/MyAnalyticsStore';
import moment from 'moment';
import { CountDto } from 'services/data-contracts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import useNavigation from 'hooks/useNavigation';
import MyInquriesContainer from './MyInquriesContainer';
import { ChannelQueryType } from 'types/CommonTypes';
import useSandbirdChat from 'hooks/useSandbirdChat';

const MYPCContainer = () => {
  const myAnalyticsStore = new MyAnalyticsStore();
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();
  const { getInquiryChannels } = useSandbirdChat();

  const [visitCount, setVisitCount] = useState<CountDto[]>();

  const getAnalystics = async () => {
    const today = moment();
    const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD');
    const thirtyDaysAgo = today.subtract(30, 'days').format('YYYY-MM-DD');
    const visitCount = (await myAnalyticsStore.getDailyVisitCounts(
      myChannel?.id as number,
      thirtyDaysAgo,
      yesterday,
    )) as unknown as CountDto[];

    setVisitCount(visitCount);
  };

  useEffect(() => {
    if (myChannel && myChannel?.channelType !== 'MEMBER') {
      getAnalystics();
    }
    if (myChannel) {
      const query: ChannelQueryType = {
        channelType: 'INQUIRY',
        metaKey: 'owner',
        metaValues: [myChannel?.id!.toString()],
      };
      getInquiryChannels(query);
    }
  }, [myChannel]);

  return (
    <Row style={{ flexWrap: 'wrap', alignItems: 'flex-start', padding: 20, gap: 20 }}>
      <div style={{ flexGrow: 1, width: '100%' }}>
        <ListBoxItem style={{ width: '50%', marginBottom: 0 }}>
          {myChannel && (
            <Row style={{ justifyContent: 'space-between' }}>
              <Row style={{ justifyContent: 'flex-start' }}>
                <Avatar
                  src={myChannel?.profile?.profileImage?.publicUrl || ''}
                  style={{ width: 80, height: 80 }}
                />
                <div style={{ marginLeft: 10 }}>
                  <Text type="H2" style={{ color: '#173300' }}>
                    {myChannel?.channelName}
                    <span style={{ fontSize: 14, marginLeft: 5, color: '#777' }}>
                      님! 환영합니다.
                    </span>
                  </Text>
                  <div style={{ textTransform: 'uppercase' }}>
                    <Text buttonRole="DESCRIPTION">
                      {myChannel?.channelType} {myChannel?.membershipType}
                    </Text>
                  </div>
                </div>
              </Row>
              <>
                {myChannel?.membershipType !== 'PREMIUM' && (
                  <div style={{ width: 120 }}>
                    <OhmossButton
                      onClick={() => {
                        navigate('/my/subscriptions?tab=0');
                      }}
                    >
                      업그레이드
                    </OhmossButton>
                  </div>
                )}
              </>
            </Row>
          )}
        </ListBoxItem>
      </div>
      <>
        {myChannel?.channelType !== 'MEMBER' && (
          <ListBoxItem title="대시보드" style={{ flex: 1 }}>
            <LineChart title="일별 방문추이" visitCount={visitCount} />
          </ListBoxItem>
        )}
      </>
      <ListBoxItem
        title="상품 문의목록"
        action={
          <>
            <OhmossButton
              type="square"
              style={{ width: 100, height: 20 }}
              onClick={() => {
                navigate('/my/deals/inquiry');
              }}
            >
              더보기
            </OhmossButton>
          </>
        }
      >
        <MyInquriesContainer useSearch={false} />
      </ListBoxItem>
    </Row>
  );
};

export default MYPCContainer;
