import { OWebViewPostMessageData } from 'ohmoss-common-types/dist/owebviewTypes';
import React, { useContext, useState, PropsWithChildren, createContext } from 'react';

type MessageContextValue = {
  message?: OWebViewPostMessageData<any>;
  setMessage: (data: OWebViewPostMessageData<any>) => void;
};

const MessageContext = createContext<MessageContextValue | null>(null);

export function MessageProvider({ children }: PropsWithChildren<any>) {
  const [message, setMessage] = useState<OWebViewPostMessageData<any>>();

  return (
    <MessageContext.Provider value={{ message, setMessage }}>{children}</MessageContext.Provider>
  );
}

export function useMessageContext() {
  const value = useContext(MessageContext);
  if (!value) {
    throw new Error('useMessageContext must be used within a <MessageProvider />');
  }

  return value;
}
