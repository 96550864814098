/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MembershipDto,
  MembershipGiftDto,
  MembershipPurchaseDto,
  OrderDto,
  TosspayApprovalDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PaymentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 구독결제
   *
   * @tags PaymentService
   * @name Pay
   * @summary 구독결제
   * @request POST:/v2/payments/toss/pay
   * @secure
   */
  pay = (
    query: {
      /** @format int64 */
      ownChannelId: number;
      authKey: string;
      customerKey: string;
    },
    data: MembershipPurchaseDto,
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipDto, any>({
      path: `/v2/payments/toss/pay`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 선물 결제요청
   *
   * @tags PaymentService
   * @name PayGift
   * @summary 선물 결제요청
   * @request POST:/v2/payments/toss/pay/gift
   * @secure
   */
  payGift = (
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    data: MembershipPurchaseDto,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderDto, any>({
      path: `/v2/payments/toss/pay/gift`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 결제승인
   *
   * @tags PaymentService
   * @name ApproveGiftPayment
   * @summary 결제승인
   * @request POST:/v2/payments/toss/approve/gift
   * @secure
   */
  approveGiftPayment = (
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    data: TosspayApprovalDto,
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipGiftDto, any>({
      path: `/v2/payments/toss/approve/gift`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
