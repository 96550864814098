import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, Slide } from 'react-toastify';
import { observer } from 'mobx-react';
import { NotFoundPage } from 'pages';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from 'stores/AppStore';
import { BrowserType, deviceInfo } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import { v4 as uuidv4 } from 'uuid';

import configurationContext from 'contexts/configurationContext';
import axios from 'axios';
import Progress from 'components/commons/Progress';
import theme from 'resources/styles/common/theme';
import ScrollTop from 'libs/commons/ScrollTop';
import ErrorBoundary from 'ErrorBoundary';
import LayoutWrapper from 'LayoutWrapper';
import useNotification from './firebase/firebase';

import 'react-toastify/dist/ReactToastify.css';
import 'resources/css/Common.css';
import 'resources/css/Links.css';
import 'resources/css/Core.css';
import 'resources/css/website.css';
import 'resources/css/mobile.css';

import 'react-spring-bottom-sheet/dist/style.css';
import 'swiper/css';

import '@sendbird/uikit-react/dist/index.css';
import 'resources/css/chat.css';

import GlobalProvider from 'context/GlobalProvider';
import useOWebViewMessageListenerEffect from 'hooks/useOWebViewMessageListenerEffect';
import ChannelProvider from 'features/linkService/context/ChannelProvider';
import PageStateProvider from 'providers/PageStateProvider';
import { useRootContext } from 'libs/hooks/useRootContext';
import GlobalStyle from 'features/csTown/commonComponents/GlobalStyle';
import Drawer from 'components/commons/Drawer';
import PageModelProvider from 'providers/PageModelProvider';
import useSandbirdChat from 'hooks/useSandbirdChat';
import MediaProvider from 'providers/MediaProvider';
import usePlatformChecker from 'libs/hooks/usePlatformChecker';
import { useMediaContext } from 'providers/useMediaContext';

const isAndroidWebView = /(Version\/[\d.]+.*Chrome\/[\d.]+.*)?; wv/.test(navigator.userAgent);
const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
const isOWebView = navigator.userAgent.includes('OWebView');

/** RN 관련 interface 등록 */
declare global {
  interface Window {
    WebView: any;
    __ch: {
      widgetOptions?: {
        position: {
          bottom: number;
          right: number;
        };
      };
    };
    ChannelIO: any;
    ReactNativeWebView: any;
    deviceInfo: {
      headerHeight: number;
      bottomSpace: number;
    };
    isWebView: boolean;
    webViewType: BrowserType;
    browserType: BrowserType;
    isProduction: boolean;
    isMobile: boolean;
    configuration: { mobileSize: number; deskSize: number };
  }
}
window.isWebView = isAndroidWebView || isIOSWebView || isOWebView;
window.webViewType = window.isWebView ? (isIOSWebView ? 'ios' : 'android') : 'pc';
window.browserType =
  navigator.userAgent.toLowerCase().indexOf('iphone') > -1
    ? 'ios'
    : navigator.userAgent.toLowerCase().indexOf('android') > -1
    ? 'android'
    : 'pc';
const App = observer(() => {
  const { getFirebaseToken } = useNotification();
  const { i18n } = useTranslation();
  const { myNotificationStore } = useStore();
  const { disconnect } = useSandbirdChat();
  const { myChannel } = useRootContext();
  const ScreenTypes = useMediaContext();

  LicenseInfo.setLicenseKey(
    'bfd158a3d0e46f8e1e73d79265a1dc9dTz01NDMxMSxFPTE3MDAwMjIyMTcyOTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );
  // const { checkIsValidUser } = useToken();
  const [configuration, setConfiguration] = useState<deviceInfo>({
    headerHeight: 0,
    topSpace: 0,
    bottomSpace: 0,
  });

  useEffect(() => {
    axios.get('https://ipapi.co/json/').then((res) => {
      const ip = res;
    });
  }, []);

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.isProduction = true;
    } else {
      window.isProduction = false;
    }

    let rLang = navigator.language;
    if (rLang === 'en-US') {
      rLang = 'en';
    }

    i18n.changeLanguage(rLang);
    document.documentElement.lang = rLang;

    // Asia / Tokyo;
  }, []);

  useLayoutEffect(() => {
    if (myChannel) {
      if (window.isWebView !== true) {
        getFirebaseToken();
      }
      myNotificationStore.getMyNotifications(myChannel?.id as number);
      myNotificationStore.getMyNotificationCount(myChannel?.id as number);
    }
  }, [myChannel]);

  useEffect(() => {
    /** 익명 채팅을 위해 guestId생성 */
    const guestId = localStorage.getItem('guestId');
    if (guestId === null) {
      const guid = uuidv4();
      localStorage.setItem('guestId', guid);
    }

    // beforeunload 이벤트 핸들러 함수
    const handleBeforeUnload = async (event: any) => {
      await disconnect();
      event.preventDefault(); // 기본 동작 방지
      event.returnValue = ''; // 크롬에서는 returnValue 설정이 필요
      return ''; // 이 메시지는 일부 브라우저에서 사용자에게 보여질 수 있습니다
    };

    // 이벤트 리스너 추가
    window.addEventListener('beforeunload', handleBeforeUnload);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useOWebViewMessageListenerEffect();
  const platformHeight = usePlatformChecker();
  const customTheme = {
    ...theme,
    platformHeight,
  };
  return (
    <ErrorBoundary>
      <ThemeProvider theme={customTheme}>
        <GlobalStyle />
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <configurationContext.Provider value={configuration}>
            <PageStateProvider>
              <PageModelProvider>
                <BrowserRouter>
                  <ChannelProvider>
                    <GlobalProvider>
                      <ScrollTop />
                      <Drawer />
                      <Routes>
                        <Route path="/*" element={<LayoutWrapper />} />
                        <Route path="*" element={NotFoundPage} />
                      </Routes>

                      <ToastContainer
                        position={!ScreenTypes.isLarge ? 'bottom-center' : 'bottom-right'}
                        autoClose={1000}
                        style={{ padding: '20px', maxWidth: 500, width: '100%' }}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        transition={Slide}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <Progress />
                    </GlobalProvider>
                  </ChannelProvider>
                </BrowserRouter>
              </PageModelProvider>
            </PageStateProvider>
          </configurationContext.Provider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
});

export default App;
