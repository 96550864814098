import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useRoutes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import managementRoute from 'routes/managementRoute';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import AdminGNB from 'features/management/components/AdminGNB';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import { initGA } from './MainContainerBase';
import AuthContainer from './AuthContainer';
import ProviderWrapper from './ProviderWrapper';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import { useMediaContext } from 'providers/useMediaContext';
import styled from 'styled-components';
import { managementMenuItems } from 'definitions/menuItems';
import CommonGnb from 'features/management/components/CommonGnb';
import ProfileProvider from 'features/management/context/providers/ProfileProvider';
import PreviewBox from 'features/management/components/common/PreviewBox';
import { useStateContext } from 'libs/hooks/usePageState';
import PreviewContainer from 'features/management/containers/PreviewContainer';
import { useRootContext } from 'libs/hooks/useRootContext';

const ManagementRouter = () => {
  const location = useLocation();
  const currentRoute = useRoutes(managementRoute);
  const { broadcast } = useStateContext();
  const ScreenTypes = useMediaContext();

  const [previewMode, setPreviewMode] = useState<boolean>(false);

  const handleClickPreview = () => {
    broadcast({ id: 'PREVIEW_MODE_CHANGE', param: !previewMode });
    const adminGnb = document.querySelector('.admin-gnb') as any;
    const modalHeader = document.querySelector('.modal-header') as any;
    const blockSelector = document.querySelector('.block-selector') as any;
    if (adminGnb && blockSelector && modalHeader) {
      if (previewMode === true) {
        adminGnb.classList.remove('hide');
        modalHeader.classList.remove('hide');
        blockSelector.classList.remove('hide');
      } else {
        adminGnb.classList.add('hide');
        modalHeader.classList.add('hide');
        blockSelector.classList.add('hide');
      }
    }
    setPreviewMode((prev) => !prev);
  };
  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  document.querySelector('html')!.style.background = '#fafafa';
  useEffect(() => {
    initGA();
  }, []);

  return (
    <>
      {ScreenTypes.isLarge && (
        <>
          <PreviewStyle>
            <ProfileProvider>
              <PreviewBox
                previewMode={previewMode}
                onClickPreview={handleClickPreview}
                categoryId={currentRoute?.props?.match?.params?.categoryid}
              />
            </ProfileProvider>
          </PreviewStyle>
          {!previewMode && (
            <GnbStyle>
              <CommonGnb
                items={managementMenuItems}
                categoryId={currentRoute?.props?.match?.params?.categoryid}
              />
            </GnbStyle>
          )}
        </>
      )}
      <ManagementLayout style={{ paddingTop: ScreenTypes.isLarge && !previewMode ? 70 : 0 }}>
        {(ScreenTypes.isSmall || ScreenTypes.isMedium) && (
          <div style={{}}>
            <DetailPageHeader
              title={currentRoute?.props?.match?.route?.title}
              gobackUrl={currentRoute?.props?.match?.route?.goBack}
            />
            <AdminGNB />
          </div>
        )}

        <Routes location={location}>
          {managementRoute.map((route, index: number) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <AuthContainer isPublic={route.isPublic} title={route.title}>
                    <>
                      <ProviderWrapper provider={route.provider}>
                        <BottomSheetOnly />
                        <FullModal />
                        <Confirm2 />
                        <UniversalModal />
                        <>{getElement(route)}</>
                      </ProviderWrapper>
                    </>
                  </AuthContainer>
                }
              />
            );
          })}
        </Routes>
        {ScreenTypes.isLarge && (
          <RightContainerStyle>
            <PreviewContainer />
          </RightContainerStyle>
        )}
      </ManagementLayout>
    </>
  );
};

const PreviewContainerStyle = styled.div`
  z-index: 1;
  height: 100vh;
  position: fixed;

  width: 440px;
  max-width: var(--max-width);
`;

const GnbStyle = styled.div`
  padding: 0 20px;
  position: fixed;
  justify-content: center;
  display: flex;
  align-items: center;
  left: 0px;
  height: 60px;
  right: 0px;
  background: #fff;
`;

const RightContainerStyle = styled.div`
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: var(--max-width);
  z-index: 1;
`;

const PreviewStyle = styled.div`
  position: fixed;
  width: 300px;
  left: 10px;
  top: 80px;
  display: flex;
  cursor: pointer;
  align-items: center;
  z-index: 2;
`;

export default ManagementRouter;
