import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import DealsProvider from 'features/mossMain/context/DealsProvider';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import cstownRoute from 'routes/csTownRoute';
import { useChat } from 'libs/hooks/useChat';
import { useRootContext } from 'libs/hooks/useRootContext';
import CsTownLnbContainer from 'features/csTown/containers/pc/CsTownLnbContainer';
import CsTownGnb from 'features/csTown/components/CsTownGnb';
import styled from 'styled-components';
import PageModelProvider from 'providers/PageModelProvider';
import chatController from 'features/community/controllers/chatController';
import CSTownController from 'controllers/CSTownController';
import { LinkDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';
import { toast } from 'react-toastify';
import AuthContainer from './AuthContainer';
import ProviderWrapper from './ProviderWrapper';
import { initGA } from './MainContainerBase';
import {
  CenterContainerStyle,
  ContainerStyle,
  GnbStyle,
  LeftContainerStyle,
  ManagementLayoutStyle,
} from 'components/commons/layouts/LayoutBox';

const CSTownPCRouter = () => {
  const location = useLocation();
  const { myChannel } = useRootContext();
  const { initChat, sendbirdChatRef } = useChat();
  const { acceptIncommingInvitations } = chatController();
  const { Brand } = CSTownController();
  const { navigate } = useNavigation();

  const [validMember, setValidMember] = useState<boolean>();

  const initializeChat = async () => {
    await initChat(myChannel?.channelName as string);
    const t = sendbirdChatRef;
    if (myChannel?.channelType !== 'SELLER') {
      // Seller가 아닌 경우, 초대장을 체크하여 수락한다.
      const dealsTemp = (await Brand.DEAL.getDelegatedDeals()) as any as LinkDto[];
      const deals = dealsTemp.map((deal) => deal.linkKey);
      if (deals?.length > 0) {
        acceptIncommingInvitations(deals as string[]);
      }
    }
  };

  useEffect(() => {
    if (myChannel && myChannel?.channelType === 'SELLER' && myChannel?.membershipType === 'FREE') {
      initGA();
    } else {
      initializeChat();
      setValidMember(true);
    }

    if (myChannel?.channelType === 'SELLER' && myChannel?.membershipType === 'FREE') {
      toast('유효하지 않은 멤버십입니다.', { type: 'warning' });
      navigate('/');
    }
  }, [myChannel]);

  return (
    <>
      {validMember === true && (
        <ContentWrapperStyle>
          <LeftContainerStyle>
            <CsTownLnbContainer channelType={myChannel?.channelType} />
          </LeftContainerStyle>
          <CenterContainerStyle style={{ maxWidth: '100%', height: 'unset' }}>
            <GnbStyle style={{ background: 'unset' }}>
              <CsTownGnb />
            </GnbStyle>
            <ManagementLayoutStyle style={{ maxWidth: '100%' }}>
              <Routes location={location}>
                {cstownRoute.map((route, index: number) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        <AuthContainer isPublic={route.isPublic} title={route.title}>
                          <PageModelProvider>
                            <DealsProvider>
                              <ProviderWrapper provider={route.provider}>
                                <BottomSheetOnly />
                                <FullModal />
                                <Confirm2 />
                                <UniversalModal />
                                <route.element />
                              </ProviderWrapper>
                            </DealsProvider>
                          </PageModelProvider>
                        </AuthContainer>
                      }
                    />
                  );
                })}
              </Routes>
            </ManagementLayoutStyle>
          </CenterContainerStyle>
        </ContentWrapperStyle>
      )}
    </>
  );
};

const ContentWrapperStyle = styled(ContainerStyle)`
  background: #fff;
  /* background: linear-gradient(312deg, rgba(218, 218, 218, 1) 0%, rgba(255, 255, 255, 1) 80%); */
`;

export default CSTownPCRouter;
