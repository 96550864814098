import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useRoutes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import managementRoute from 'routes/managementRoute';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { initGA } from './MainContainerBase';
import AuthContainer from './AuthContainer';
import ProviderWrapper from './ProviderWrapper';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import styled from 'styled-components';
import { managementMenuItems } from 'definitions/menuItems';
import CommonGnb from 'features/management/components/CommonGnb';
import PreviewContainer from 'features/management/containers/PreviewContainer';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';
import { IconButton, LinearProgress } from '@mui/material';
import useNavigation from 'hooks/useNavigation';
import ProfileProvider from 'features/management/context/providers/ProfileProvider';
import PreviewBox from 'features/management/components/common/PreviewBox';

const ManagementPCRouter = () => {
  const location = useLocation();
  const currentRoute = useRoutes(managementRoute);
  const { navigate } = useNavigation();
  const [showSimulator, setShowSimulator] = useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [shown, setShown] = React.useState<boolean>(false);

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  document.querySelector('html')!.style.background = '#fafafa';
  useEffect(() => {
    initGA();
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setShowSimulator(true);
          clearInterval(timer);
        }
        const diff = Math.random() * 5;
        return Math.min(oldProgress + diff, 100);
      });
    }, 20);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      const delayTimer = setTimeout(() => {
        setShown(true);
      }, 500); // 0.5 seconds delay

      return () => clearTimeout(delayTimer);
    }
  }, [progress]);

  return (
    <>
      <ContainerStyle>
        <LeftContainerStyle>
          <>
            <GnbStyle style={{ background: 'red' }}>
              <CommonGnb />
            </GnbStyle>
            <LogoStyle style={{ width: '100%' }}>
              <div style={{ padding: 10 }}>
                <img src="/images/oh_logo.svg" style={{ width: 60 }} />
              </div>
            </LogoStyle>
            <BackButtonStyle>
              <OhmossButton
                style={{ background: '#E50003' }}
                type="square"
                onClick={() => {
                  navigate('/my');
                }}
              >
                <Row>
                  <IconButton>
                    <CloseOutlinedIcon style={{ color: '#fff' }} />
                  </IconButton>
                  <div style={{ color: '#fff' }}>편집종료</div>
                </Row>
              </OhmossButton>
            </BackButtonStyle>
          </>
        </LeftContainerStyle>
        <CenterContainerStyle>
          <GnbStyle>
            <CommonGnb
              items={managementMenuItems}
              categoryId={currentRoute?.props?.match?.params?.categoryid}
            />
          </GnbStyle>
          <ManagementLayout style={{ height: 'calc(100vh - 60px)' }}>
            <Routes location={location}>
              {managementRoute.map((route, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <AuthContainer isPublic={route.isPublic} title={route.title}>
                        <>
                          <ProviderWrapper provider={route.provider}>
                            <BottomSheetOnly />
                            <FullModal />
                            <Confirm2 />
                            <UniversalModal />
                            <>{getElement(route)}</>
                          </ProviderWrapper>
                        </>
                      </AuthContainer>
                    }
                  />
                );
              })}
            </Routes>
          </ManagementLayout>
        </CenterContainerStyle>
        <RightContainerStyle show={shown}>
          <ProgressContainerStyle show={showSimulator}>
            <div style={{ flex: 1, margin: 'auto', textAlign: 'center' }}>
              <div style={{ paddingBottom: 20 }}>미리보기 준비중...</div>
              <LinearProgress variant="determinate" value={progress} />
            </div>
          </ProgressContainerStyle>
          <ProfileProvider>
            <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
              <PreviewTitleStyle>PREVIEW</PreviewTitleStyle>
              {currentRoute?.props?.match?.params?.categoryid && (
                <PreviewBox
                  previewMode={true}
                  onClickPreview={() => {}}
                  categoryId={currentRoute?.props?.match?.params?.categoryid}
                />
              )}
            </div>
          </ProfileProvider>
          <PreviewWrapperStyle
            isLinkEdit={typeof currentRoute?.props?.match?.params?.categoryid !== 'undefined'}
          >
            <PreviewContainer />
          </PreviewWrapperStyle>
        </RightContainerStyle>
      </ContainerStyle>
    </>
  );
};

const ContainerStyle = styled.div`
  display: flex;
`;

const PreviewTitleStyle = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  letter-spacing: 20px;
  background: var(--primary-color);
`;

const LeftContainerStyle = styled.div`
  max-width: 300px;
  width: 100%;
  background: #fff;
  position: relative;
`;

const CenterContainerStyle = styled.div`
  flex-grow: 1;
  justify-content: center;
  overflow: scroll;
  height: 100vh;
`;

const PreviewWrapperStyle = styled.div<{ isLinkEdit?: boolean }>`
  width: 100%;
  height: ${(props) => (props.isLinkEdit ? 'calc(100vh - 100px)' : '100vh')};
  position: relative;
`;

const ProgressContainerStyle = styled.div<{ show?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(props) => (props.show ? 'transparent' : '#fff')};
  opacity: ${(props) => (props.show ? 0 : 1)};
  pointer-events: ${(props) => (props.show ? 'none' : 'all')};

  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  z-index: 100;
  width: 100%;
`;

const GnbStyle = styled.div`
  padding: 0 20px;
  top: 0;
  position: sticky;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  text-align: center;
  background: #fafafa !important;
  z-index: 1;
  border-bottom: 1px solid #9e9e9e;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
`;

const RightContainerStyle = styled.div<{ show?: boolean }>`
  position: ${(props) => (props.show ? 'unset' : 'relative')};
  overflow-x: hidden;
  overflow-y: hidden;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  max-width: var(--max-width);
  min-width: 400px;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;

const LogoStyle = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
`;

const BackButtonStyle = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 20px;
`;

export default ManagementPCRouter;
