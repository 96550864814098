import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaContext } from 'providers/useMediaContext';

const screenWidth = window.innerWidth;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CommonTabButtons({
  items,
  initialValue,
  style,
  noOfTabs,
  onHandleTabClick,
}: {
  items: string[];
  initialValue?: string;
  style?: React.CSSProperties;
  noOfTabs?: number;
  onHandleTabClick: (label: string) => void;
}) {
  const ScreenTypes = useMediaContext();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (label: string) => {
    onHandleTabClick(label);
  };

  React.useEffect(() => {
    if (initialValue && items) {
      const i = items.findIndex((item: string) => item === initialValue);
      setValue(i || 0);
    }
  }, [initialValue]);

  return (
    <Tabs
      sx={{
        borderBottom: '1px solid #173300',
        margin: '0',
        padding: '0px 0px 0px 0px',
        display: 'flex',
        '& .MuiTabs-root': { margin: '0' },
      }}
      TabIndicatorProps={{
        style: { background: '#173300' },
      }}
      value={value}
      onChange={(event: React.SyntheticEvent, newValue: number) => handleChange(event, newValue)}
    >
      {items.map((item: string) => (
        <Tab
          sx={{
            minWidth: ScreenTypes.isLarge ? '200px' : '150px',
            flex: 1,
            // width: noOfTabs === 4 ? '25%' : '33.33%',
            fontSize: '14px',
            fontWeight: '500',
            color: 'grey',
            wordBreak: 'keep-all',
            whiteSpace: 'pre-wrap',
            '&.Mui-selected': {
              color: '#173300',
            },
            '& .MuiTab-indicator': {},
          }}
          label={item}
          onClick={() => handleTabClick(item)}
          {...a11yProps(1)}
        />
      ))}
    </Tabs>
  );
}
