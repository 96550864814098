import { RoutePropInterface } from 'types/CommonTypes';

import DealsProvider from 'features/management/context/providers/DealsProvider';
import CSTownDealInquiriesPage from 'features/myPage/pages/CSTownDealInquiriesPage';
import CSTownRootPage from 'features/csTown/pages/CSTownRootPage';

import ChecklistSelectorPage from 'features/myPage/pages/ChecklistSelectorPage';
import ManagerListPage from 'features/csTown/pages/ManagerListPage';
import CSTownDealsPage from 'features/csTown/pages/CSTownDealsPage';
import CSTownInquiriesPage from 'features/csTown/pages/CSTownInquiriesPage';
import CSTownPartnersPage from 'features/csTown/pages/CSTownPartnersPage';
import CSTownChecklistPage from 'features/csTown/pages/CSTownChecklistPage';
import CSTownChecklistDetailPage from 'features/csTown/pages/CSTownChecklistDetailPage';
import CSTownDashboardPage from 'features/csTown/pages/CSTownDashboardPage';

const cstownRoute: RoutePropInterface[] = [
  {
    path: '/cstown',
    title: 'cstown 메인',
    element: CSTownRootPage,
    provider: DealsProvider,
    useHeader: false,
  },
  {
    path: '/cstown/home',
    title: 'cstown 메인',
    element: CSTownRootPage,
    provider: DealsProvider,
    useHeader: false,
  },
  {
    path: '/cstown/deals',
    title: '내 공구목록',
    element: CSTownDealsPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/inquiries',
    title: '내 문의 목록',
    element: CSTownInquiriesPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/deals/:dealid',
    title: '관리 공구목록',
    element: CSTownDealsPage,
    provider: DealsProvider,
  },

  {
    path: '/cstown/deal/:dealid/inquiries',
    title: '공구 문의 목록',
    element: CSTownDealInquiriesPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/partners',
    title: '파트너 관리',
    element: CSTownPartnersPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/checklist',
    title: '체크리스트',
    element: CSTownChecklistPage,
  },
  {
    path: '/cstown/checklist/detail',
    title: '체크리스트 상세',
    element: CSTownChecklistDetailPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/checklist/detail/list',
    title: '제안서 / 상품기획 체크 리스트',
    useHeader: false,
    element: ChecklistSelectorPage,
  },
  {
    path: '/cstown/managers',
    title: '매니저 관리',
    element: ManagerListPage,
  },
  {
    path: '/cstown/dashboard',
    title: '통합대시보드',
    useHeader: false,
    element: CSTownDashboardPage,
  },
];

export default cstownRoute;
