import styled from 'styled-components';

export const BottomBoxStyle = styled.div`
  width: var(--pc-button-width) !important;
  margin: auto;
  margin-top: 0;
  @media ${(props) => props.theme.media.mobile} {
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    z-index: 1;
    width: 100%;
  }
`;
