import React, { useState, ReactNode, useEffect, useRef } from 'react';
import { Context } from 'libs/hooks/useRootContext';
import { UserDto } from 'services/data-contracts';
import useToken from 'libs/hooks/useToken';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import MyStore from 'stores/MyStore';
import { ChannelDtoModel, CountType } from 'types/CommonTypes';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

const RootProvider = ({ children }: PageProviderProps) => {
  const { validUser } = useToken();
  const { initializeFirstChannel } = useChannelSelector();
  const myStore = new MyStore();

  const [myChannel, setMyChannel] = useState<ChannelDtoModel | undefined | null>(undefined);
  const [unreadCount, setUnreadCount] = useState<CountType>({ inquiry: 0 });

  const setChannel = (channel: ChannelDtoModel | undefined) => {
    let cstownUser = false;
    if (channel?.channelType === 'SELLER' && channel?.membershipType !== 'FREE') {
      cstownUser = true;
    } else if (channel?.channelType === 'BRAND') {
      cstownUser = true;
    }
    const newChannel = { ...channel, cstownUser };
    setMyChannel(newChannel as ChannelDtoModel);
  };

  const getMyChannel = async () => {
    if (validUser()) {
      const my = (await initializeFirstChannel()) as ChannelDtoModel;
      if (my === null) {
        const myInfo = (await myStore.getLoginInfo()) as unknown as UserDto;
        const member: ChannelDtoModel = {
          channelType: 'MEMBER',
          channelName: myInfo?.username,
          id: myInfo?.id,
          cstownUser: true,
        };
        setMyChannel(member);
      } else {
        let cstownUser = false;
        if (my.channelType === 'SELLER' && my.membershipType !== 'FREE') {
          cstownUser = true;
        } else if (my.channelType === 'BRAND') {
          cstownUser = true;
        }
        setMyChannel({ ...my, cstownUser });
      }
    } else {
      setMyChannel(null);
    }
  };

  // const getCurrentChannel = async (directChannelName?: string) => {
  //   let targetChannel;
  //   if (myChannel) {
  //     targetChannel = await channelStore.getChannel(
  //       (channelName as string) || (directChannelName as string),
  //       myChannel.id,
  //     );
  //   } else {
  //     targetChannel = await channelStore.getChannel(
  //       (channelName as string) || (directChannelName as string),
  //     );
  //     // setCurrentChannel(targetChannel as ChannelDto);
  //   }
  //   setCurrentChannel(targetChannel as ChannelDto);
  // };

  useEffect(() => {
    getMyChannel();
  }, []);

  return (
    <Context.Provider
      value={{
        unreadCount,
        setUnreadCount,
        myChannel,
        getMyChannel,
        setChannel,
        // reset,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default RootProvider;
