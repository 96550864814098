import React, { useRef, useState } from 'react';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import styled from 'styled-components';
import SearchBox from 'components/commons/SearchBox';
import { IconButton, Popover, ClickAwayListener } from '@mui/material';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { useStore } from 'stores/StoreHelper';
import chatController from 'features/community/controllers/chatController';
import InquiryListItem from './InquiryListItem';
import { Row } from '../layouts';
import CircularLoading from '../CircularLoading';
import NoContent from '../NoContent';
import useSandbirdChat from 'hooks/useSandbirdChat';

/**
 * @description Inquiry목록(채널 채팅)
 * @param param0
 * @returns
 */
const TalkChannelListBox = ({
  inquiries,
  thumbnailType,
  role = 'OWNER',
  useSearch = true,
  maxCount,
  onJoinChannel,
}: {
  inquiries?: GroupChannel[];
  maxCount?: number;
  useSearch?: boolean;
  role?: 'CUSTOMER' | 'OWNER';
  thumbnailType?: 'DEAL' | 'USER';
  onJoinChannel?: (channel: GroupChannel) => void;
}) => {
  const targetChannel = useRef<GroupChannel>();

  const { markAsRead } = useSandbirdChat();
  const { deleteChannel, searchMessages } = chatController();
  const { uiStore } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [currentChannel, setCurrentChannel] = useState<GroupChannel>();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSearch = (value: string) => {
    console.log(value);
    alert(value);
    const result = searchMessages(value);
  };

  const handleCLickDelete = async () => {
    uiStore.confirm.show({
      message: '선택하신 문의를 삭제하시겠습니까? 해당 채널과 관련된 모든 정보가 삭제됩니다.',
      onConfirmed: async () => {
        if (targetChannel?.current) {
          await deleteChannel(targetChannel?.current.url);
        }
      },
    });
  };

  return (
    <>
      <ClickAwayListener onClickAway={handlePopoverClose}>
        <InquiryContainerStyle style={{ position: 'relative' }}>
          <CircularLoading />

          {inquiries?.length === 0 ? (
            <NoContent title={'등록된 문의가 '} />
          ) : (
            <>
              {useSearch && (
                <SearchBoxStyle style={{ marginBottom: 0, padding: 5, background: '#fff' }}>
                  <SearchBox onEntered={handleSearch} style={{ borderRadius: 8 }} />
                </SearchBoxStyle>
              )}
            </>
          )}

          <div style={{}}>
            {inquiries?.slice(0, maxCount)?.map((inquiry: GroupChannel, i: number) => (
              <div key={i}>
                <InquiryListItem
                  thumbnailType={thumbnailType}
                  cutoffLength={25}
                  key={inquiry.url}
                  inquiry={inquiry}
                  onContextClick={(e: any) => {
                    handlePopoverOpen(e);
                    targetChannel.current = inquiry;
                  }}
                  onClick={() => {
                    markAsRead(inquiry.url);
                    onJoinChannel?.(inquiry);
                    setCurrentChannel(inquiry);
                  }}
                  selected={currentChannel?.url === inquiry.url}
                />
              </div>
            ))}
          </div>
          <div />
          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Row>
              <IconButton>
                <DeleteIcon onClick={handleCLickDelete} />
              </IconButton>
            </Row>
          </Popover>
        </InquiryContainerStyle>
      </ClickAwayListener>
    </>
  );
};

const InquiryContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  height: 100%;
  width: 100%;
`;

const SearchBoxStyle = styled.div`
  position: sticky;
  top: 10px;
  z-index: 0;
`;
export default TalkChannelListBox;
