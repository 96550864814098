import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  ChannelChecklistCreationDto,
  ChannelChecklistDto,
  ChannelChecklistTopicDto,
  LinkDto,
} from 'services/data-contracts';
import styled from 'styled-components';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import ChecklistController from 'controllers/ChecklistController';
import { toast } from 'react-toastify';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ChecklistTopicContainer from 'features/myPage/containers/checklist/ChecklistTopicContainer';
import { useStateContext } from 'libs/hooks/usePageState';
import { CSTownChecklistState } from 'types/CommonTypes';
import Text from 'components/commons/Text';
import CSTownChecklistController from 'features/csTown/pages/pageControllers/CSTownChecklistController';
import DealList from 'features/myPage/containers/DealList';
import { FormControl, IconButton, MenuItem, Select } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

import {
  DetailHeader,
  ListBoxItemTemplate,
  MenuIconBoxStyle,
} from 'features/csTown/commonComponents/styles';

import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { Menu } from 'features/csTown/commonComponents/SubTabs';
import ChecklistTopicItemsContainer from './ChecklistTopicItemsContainer';
import ChecklistTopicSummaryContainer from './ChecklistTopicSummaryContainer';
import { IDealBlock } from 'types/BlockExtendsTypes';

const CSTownDealChecklistDetailContainer = ({ deal }: { deal?: LinkDto }) => {
  const menus: Menu[] = [
    { id: 1, title: '체크리스트 상세', Component: ChecklistTopicItemsContainer },
    { id: 2, title: '요약보기', Component: ChecklistTopicSummaryContainer },
  ];
  const { uiStore } = useStore();
  const [searchParam] = useSearchParams();
  const { myChannel } = useRootContext();
  const {
    createMyChecklist,
    getMyChecklist,
    getChannelChecklistTopic,
    getChecklistsByCategory,
    deleteMyChecklist,
    updateMyChecklist,
    downloadTopicsPdf,
  } = ChecklistController();
  const { serviceModel, updateServiceModel } = useModelManagement<ChannelChecklistCreationDto>();
  const { stateModel, setPageState, setMultipleState, broadcast, receiver } =
    useStateContext<CSTownChecklistState>();
  const { getMyChecklists } = ChecklistController();
  const [tab, setTab] = useState<Menu>(menus[0]);
  const [topics, setTopics] = useState<ChannelChecklistTopicDto[]>([]);

  const { getDeals } = CSTownChecklistController();

  const getChecklistInfo = async () => {
    const result = await getMyChecklist(
      myChannel?.id as number,
      stateModel?.selectedChecklist?.id as number,
    );

    if (result?.deal) {
      setPageState('deal', result.deal);
    }
  };

  const getTopics = async () => {
    const tempTopics = await getChannelChecklistTopic(
      myChannel?.id as number,
      stateModel?.selectedChecklist?.id as number,
    );
    setTopics(tempTopics);
  };

  const handleSelectDeal = () => {
    getDeals();
    uiStore.drawer.show({
      title: '연결할 공구 선택',
      children: (
        <DealListWrapperStyle>
          <DealList
            useContext={false}
            deals={stateModel?.deals}
            onClick={async (result?: LinkDto) => {
              setPageState('deal', result);
              updateServiceModel('dealId', result?.id);
              const newChecklist = await updateMyChecklist(
                myChannel?.id as number,
                stateModel?.selectedChecklist?.id as number,
                serviceModel as ChannelChecklistCreationDto,
              );

              broadcast({ id: 'reload' });
              setPageState('selectedChecklist', newChecklist);
            }}
            customContext={
              <IconButton>
                <InsertLinkIcon />
              </IconButton>
            }
          />
        </DealListWrapperStyle>
      ),
    });
  };

  const handleDelete = async () => {
    if (stateModel?.selectedChecklist?.id !== null) {
      uiStore.confirm.show({
        message: '체크리스트를 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteMyChecklist(
            myChannel?.id as number,
            stateModel?.selectedChecklist?.id as number,
          );
          setPageState('selectedChecklist', null);
          broadcast({ id: 'reload', param: 'setPageMode' });
        },
      });
    }
  };

  const handleClickPdf = () => {
    if (stateModel?.selectedChecklist) {
      downloadTopicsPdf(stateModel?.selectedChecklist?.id as number);
    }
  };

  const getDealChecklist = async () => {
    const lists = await getMyChecklists(myChannel?.id as number);
    const dealChecklist = lists.filter((list) => list.deal?.id === deal?.id);
    if (dealChecklist.length > 0) {
      setMultipleState({ checklists: dealChecklist, selectedChecklist: dealChecklist[0] });
    } else {
      setMultipleState({ checklists: undefined, selectedChecklist: undefined });
    }
  };

  useEffect(() => {
    if (deal) {
      getDealChecklist();
    }
    return () => {
      setPageState('selectedChecklist', undefined);
    };
  }, [deal]);

  useEffect(() => {
    if (stateModel?.selectedChecklist) {
      getTopics();
    }
    if (receiver?.param == 'checklistItemUpdate') {
      getTopics();
    }
  }, [stateModel?.selectedChecklist, receiver]);

  return (
    <>
      {typeof stateModel?.selectedChecklist === 'undefined' ? (
        <ListBoxItemTemplate>
          <div style={{ fontSize: 14, fontWeight: 500 }}>
            등록된 체크리스트가 없습니다. 체크리스트를 등록해주세요.
          </div>
        </ListBoxItemTemplate>
      ) : (
        <>
          <div style={{}}>
            <ListBoxItemTemplate>
              <Row style={{ justifyContent: 'space-between' }}>
                <>
                  <Row style={{ width: 'unset', gap: 10 }}>
                    <>
                      {stateModel?.checklists && stateModel?.checklists?.length > 1 && (
                        <FormControl style={{ width: '400px' }}>
                          <Select
                            defaultValue={stateModel?.selectedChecklist?.id}
                            // value={defaultFont}
                            onChange={(e: any, value: any) => {
                              const result = stateModel?.checklists?.find(
                                (checklist) => checklist.id === value.props.value,
                              );
                              if (result) {
                                setPageState('selectedChecklist', result);
                              }
                            }}
                          >
                            {stateModel?.checklists?.map((checklist: ChannelChecklistDto) => (
                              <MenuItem value={checklist.id}>{checklist?.title}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </>

                    <Text type="H4" buttonRole="DESCRIPTION" style={{ textTransform: 'uppercase' }}>
                      {stateModel?.selectedTopic && ` - ${stateModel?.selectedTopic?.name}`}
                    </Text>
                  </Row>
                </>
                <>
                  {stateModel?.selectedChecklist?.deal === null && (
                    <>
                      <SelectDealBtn onClick={handleSelectDeal}>공구 연결하기</SelectDealBtn>
                    </>
                  )}
                </>
                <MenuIconBoxStyle style={{ height: 70 }}>
                  <>
                    {stateModel?.deal === null && (
                      <IconButton
                        onClick={() => {
                          // setPageState('pageMode', 'EDIT');
                        }}
                      >
                        <CreateIcon style={{ width: 25, height: 25 }} />
                      </IconButton>
                    )}
                    <IconButton>
                      <PictureAsPdfOutlinedIcon onClick={handleClickPdf} />
                    </IconButton>
                    <DeleteIcon onClick={handleDelete} />
                  </>
                </MenuIconBoxStyle>
              </Row>
            </ListBoxItemTemplate>
          </div>
          <>
            {stateModel?.selectedTopic ? (
              <ChecklistTopicItemsContainer />
            ) : (
              //
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                }}
              >
                <ChecklistTopicContainer
                  style={{ padding: 0 }}
                  topics={topics}
                  checklistId={stateModel?.selectedChecklist?.id}
                />
              </div>
            )}
          </>
        </>
      )}
    </>
  );
};

const SelectDealBtn = styled.button`
  background: #011208;
  color: #0bbd58;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100px;
  padding: 0.3rem 0.3rem;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
`;

const DealListWrapperStyle = styled.div`
  padding: 0 20px;
`;

export default CSTownDealChecklistDetailContainer;
