import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';

import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { validationHelper } from 'controls/validationHelper';
import { LinkDto, LinkItemDto } from 'services/data-contracts';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';

import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import styled from 'styled-components';
import moment from 'moment';
import BlockController from 'controllers/BlockController';

import CalendarItem from './CalendarItem';
import CommonAlign from '../commonControl/CommonAlign';
import { ICalendarBlock } from 'types/BlockExtendsTypes';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import AddIcon from 'features/csTown/commonComponents/AddIcon';
import Required from 'components/commons/Required';
import ActionButton from './ActionButton';

const Calendar = ({ blockModel }: { blockModel?: ICalendarBlock & LinkDto }) => {
  const { uiStore } = useStore();
  const {
    initialModel,
    setState,
    stateModel,
    setMultipleState,
    initialBinding,
    serviceModel,
    updateServiceModel,
  } = useOhmossContext<LinkTypes<ICalendarBlock & LinkDto>>();

  const { updateBlock } = BlockController<ICalendarBlock & LinkDto>();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [eventItems, setEventItems] = useState<LinkItemDto[]>([]);

  const handleClickEvent = (eventModel?: LinkItemDto) => {
    const maxValue =
      eventItems?.length > 0 ? Math.max(...eventItems?.map((item) => item.seq || 0)) : 0;
    uiStore.bottomSheet.show({
      children: <CalendarItem itemModel={eventModel} seq={maxValue + 1} />,
      onConfirmed: (result: LinkItemDto) => {
        // setEventItems((prev) => [...prev, result]);
        setEventItems((prev) => {
          const existingIndex = prev.findIndex((item) => item.seq === result.seq);

          if (existingIndex !== -1) {
            return prev.map((item, index) => (index === existingIndex ? result : item));
          } else {
            return [...prev, result];
          }
        });
      },
    });
  };

  const handleClickDelete = (item: number) => {
    uiStore.confirm.show({
      message: '일정을 삭제하시겠습니까?',
      onConfirmed: async () => {
        const newArr = eventItems.filter((_, index) => index !== item);
        setEventItems(newArr);
      },
    });
  };

  const initializeModel = () => {
    if (blockModel) {
      setEventItems(blockModel?.items || []);
      initialBinding(blockModel);
    } else {
      setMultipleState({
        linkType: 'Calendar',
        isActive: true,
        displayType: 'calendar',
      });
    }
  };

  useEffect(() => {
    if (eventItems) {
      setState('items', eventItems);
    }
  }, [eventItems]);

  useEffect(() => {
    if (stateModel) {
      const title = validationHelper.required(stateModel?.title);
      const url = validationHelper.urlOrEmpty(stateModel?.linkUrl);
      setIsValid(title && url);
    }
  }, [stateModel]);

  useEffect(() => {
    initializeModel();
    return () => {
      initialBinding(undefined);
    };
  }, [blockModel]);

  return (
    <div style={{}}>
      <ItemSectionStyle>
        <ItemSectionTitle>
          타이틀
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              setState('title', value);
            }}
            initialValue={stateModel?.title}
            errorText="타이틀은 필수항목입니다."
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ToggleButtonGroup
          fullWidth
          value={stateModel?.displayType}
          exclusive
          onChange={(e: any, value: string) => {
            if (value === null) {
              return;
            }
            setState('displayType', value);
          }}
        >
          <ToggleButton value="calendar" aria-label="left aligned">
            <div>달력으로표시</div>
          </ToggleButton>
          <ToggleButton value="list" aria-label="centered">
            <div>일정으로표시</div>
          </ToggleButton>
        </ToggleButtonGroup>
      </ItemSectionStyle>

      <MenuItemCheckBox
        onChangeValue={(value: boolean) => {
          setState('useTransparent', value);
        }}
        initialValue={initialModel?.useTransparent}
        title="투명배경"
      />
      <MenuItemCheckBox
        onChangeValue={(value: boolean) => {
          setState('openNewWindow', value);
        }}
        initialValue={initialModel?.useTransparent}
        title="새창열기"
      />
      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        title="타이틀 정렬"
        onChange={(value: string) => {
          updateServiceModel('contentAlign', value);
        }}
      />
      <ItemSectionStyle>
        <ItemSectionTitle>
          <Row style={{ justifyContent: 'space-between' }}>
            <div>일정</div>
            <AddIcon
              onClick={() => {
                handleClickEvent();
              }}
            ></AddIcon>
          </Row>
        </ItemSectionTitle>
        <ItemContent>
          <div>
            {eventItems?.map((event: any, index: number) => (
              <EventStyle>
                <Row style={{ justifyContent: 'space-between' }}>
                  <BoxStyle
                    onClick={() => {
                      handleClickEvent(event);
                    }}
                  >
                    <div style={{ fontSize: 12, color: '#333' }}>
                      {event?.alldayEvent === true ? (
                        <>
                          {moment(event.startDate).format('YYYY-MM-DD')}
                          <b
                            style={{
                              background: 'rebeccapurple',
                              padding: '2px 10px',
                              marginLeft: 10,
                              borderRadius: 8,
                              color: '#fff',
                            }}
                          >
                            하루종일
                          </b>
                        </>
                      ) : (
                        <>
                          {moment(event.startDate).utc().format('YYYY-MM-DD HH:mm')} ~{' '}
                          {moment(event.endDate).utc().format('YYYY-MM-DD HH:mm')}
                        </>
                      )}
                    </div>
                    <div style={{ fontSize: 14 }}>{event.title}</div>
                  </BoxStyle>
                  <div>
                    <DeleteIcon
                      onClick={() => {
                        handleClickDelete(index);
                      }}
                    />
                  </div>
                </Row>
              </EventStyle>
            ))}
          </div>
        </ItemContent>
      </ItemSectionStyle>
      <ActionButton isValid={isValid} serviceModel={serviceModel} />
    </div>
  );
};

const EventStyle = styled.div`
  cursor: pointer;
  margin: 20px 0;
`;

const BoxStyle = styled.div`
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 10px;
  flex: 1;
  margin-right: 15px;
`;

export default Calendar;
