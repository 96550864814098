import React, { useState, ReactNode, useEffect } from 'react';
import { ChannelBoardDto, ChannelDto, LinkDto, PostDto } from 'services/data-contracts';
import { useGlobalContext } from 'context/useGlobalContext';
import { Context } from 'features/management/context/useOhmossContext';
import { useStore } from 'stores/StoreHelper';

import { MyStyle } from 'types/CommonTypes';
import { useModelManagement } from 'libs/hooks/useModelUpdate';

import useNavigation from 'hooks/useNavigation';
import LinkStore from 'stores/LinkStore';
import MyPostStore from 'stores/MyPostStore';
import CustomerInquiryStore from 'stores/CustomerInquiryStore';
import useLocalParams from 'libs/hooks/useLocalParams';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

const LinkServiceProvider = <T extends LinkDto>({ children }: PageProviderProps) => {
  const { navigate } = useNavigation();

  const linkStore = new LinkStore();
  const myPostStore = new MyPostStore();
  const customerInquiryStore = new CustomerInquiryStore();

  const serviceItems = useModelManagement<T>();
  const { serviceModel } = serviceItems;
  const { uiStore } = useStore();
  const { channelName } = useLocalParams();
  const { setDeal } = useGlobalContext();
  const { myChannel } = useRootContext();

  const [board, setSelectedBoard] = useState<ChannelBoardDto | undefined | null>(null);
  const [posts, setPosts] = useState<PostDto[]>([]);
  const [links, setLinks] = useState<LinkDto[]>();
  const [channel, setCurrentChannel] = useState<ChannelDto>();
  const [channelStyle, setChannelStyle] = useState<MyStyle>();

  setDeal(false);

  const getChannelBoards = () => {};

  const setBoard = (board: ChannelBoardDto | undefined) => {
    setSelectedBoard(board);
  };

  const getDealOptions = async (boardId: number, linkId: number) => {
    return await linkStore.getDealOptions(channelName as string, boardId, linkId);
  };

  const editAdPost = (post: PostDto) => {
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    navigate(
      `/deals/posts/${post.id}/modify?channelid=${post.channel?.id}&boardid=${post.board?.id}`,
      // `/deals/settings/myad/${post.id}/modify?channelid=${post.channel?.id}&boardid=${post.board?.id}`,
    );
  };

  const deleteAdPost = (post: PostDto) => {
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      title: '게시물 삭제',
      message: '해당 게시물을 삭제하시겠습니까?',
      onConfirmed: async () => {
        await myPostStore.deletePost(
          post.channel?.id as number,
          post.board?.id as number,
          post.id as number,
        );
        return true;
        // fetchData();
      },
    });
  };

  const inquire = async () => {
    const result = (await customerInquiryStore.createRoom(
      (myChannel?.id as number) ?? null,
      // todo 타입 수정할것
      serviceModel as any,
    )) as any;

    if (result) {
      return await customerInquiryStore.inquiry(
        result?.id as number,
        myChannel?.id as number,
        serviceModel as any,
      );
    }
  };

  useEffect(() => {
    if (typeof myChannel !== 'undefined' && channelName) {
      // getCurrentChannel();
    }
  }, [myChannel, channelName]);

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            getChannelBoards,
            setBoard,
            board,
            // getPostOrLinkItems,
            links,
            posts,
            editAdPost,
            deleteAdPost,
            channel,
            // getMyStyle,
            channelStyle,
            getDealOptions,
            inquire,
            setChannelStyle,
            ...serviceItems,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default LinkServiceProvider;
