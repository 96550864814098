import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { ChannelDto, PartnerDto } from 'services/data-contracts';
import styled, { css } from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

const BrandSearchBox = observer(
  ({
    onFixSearchBox,
    onChangeValue,
    memberInfo,
  }: {
    onFixSearchBox: (channel: ChannelDto) => void;
    onChangeValue?: (channelName?: string) => void;
    memberInfo?: PartnerDto;
  }) => {
    const { channelStore } = useStore();

    const [searchValue, setSearchValue] = useState<string>(
      memberInfo?.partnerChannel?.channelName ?? '',
    );
    const [selectedChannel, setSelectedChannel] = useState<ChannelDto | undefined>();
    const [searchedChannels, setSearchedChannels] = useState<ChannelDto[]>([]);

    const handleSearch = async (e: any) => {
      onChangeValue?.(e.target.value.trim());
    };

    const handleClickChannel = (channel: ChannelDto) => {
      if (channel && channel.channelName) {
        setSearchValue(channel.channelName);
      }
      setSelectedChannel(channel);
      setSearchedChannels([]);
      onFixSearchBox(channel);
    };

    useEffect(() => {
      return () => {
        channelStore.unMount();
      };
    }, []);

    return (
      <ChannelBoxStyle>
        <TextField
          id="outlined-basic"
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <>
                {selectedChannel && (
                  <CheckIcon
                    style={{ color: 'green', width: '20px', height: '20px', marginRight: '10px' }}
                  />
                )}
                <img
                  src="/images/search_grey.svg"
                  alt="search"
                  style={{ width: '20px', height: '20px' }}
                />
              </>
            ),
          }}
          style={{ width: '100%' }}
        />
      </ChannelBoxStyle>
    );
  },
);

const ChannelBoxStyle = styled.div`
  width: 100%;
`;

export default BrandSearchBox;
