import React, { useEffect, useState } from 'react';

import { useStore } from 'stores/StoreHelper';
import { ChannelBoardDto, ChannelProfileDto, LinkDto } from 'services/data-contracts';
import { MyStyle } from 'types/CommonTypes';
import { observer } from 'mobx-react-lite';

import BackgroundImageComponent from 'features/commonFeatures/components/BackgroundImageComponent';
import LinkPageProfileBlocks from 'features/linkService/components/LinkPageProfileBlocks';
import MyLinkStore from 'stores/MyLinkStore';
import LinkBoardType from 'features/adminFeatures/template/components/LinkBoardType';
import StyleController from 'controllers/StyleController';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStateContext } from 'libs/hooks/usePageState';
import MyBoardStore from 'stores/MyBoardStore';
import styled from 'styled-components';

const PreviewContainer = observer(() => {
  const myLinkStore = new MyLinkStore();
  const myBoardStore = new MyBoardStore();
  const { uiLinkStore } = useStore();
  const { myChannel } = useRootContext();
  const { getMyStyle } = StyleController();
  const { receiver } = useStateContext();

  const [links, setLinks] = useState<LinkDto[]>();
  const [profile, setProfile] = useState<ChannelProfileDto>();

  const transformStyle = (on: boolean) => {
    const rootLayout = document.querySelector('.universal-layout') as any;
    const managementLayout = document.querySelector('.management-layout') as any;
    if (rootLayout && managementLayout) {
      if (on) {
        rootLayout.classList.add('preview');
        managementLayout.classList.add('preview');
      } else {
        rootLayout.classList.remove('preview');
        managementLayout.classList.remove('preview');
      }
    }
  };

  const getMyStyles = async () => {
    if (myChannel) {
      const style = (await getMyStyle(myChannel.channelName as string)) as unknown as MyStyle;
      if (style) {
        uiLinkStore.setMyStyle(style);
      }
    }
  };

  const getLinks = async (categoryId: number) => {
    const linkList = (await myLinkStore.getItems(
      myChannel?.id as number,
      categoryId,
    )) as any as LinkDto[];
    setLinks(linkList);
  };

  const firstLoad = async () => {
    const result = (await myBoardStore.getBoards(
      myChannel?.id as number,
    )) as any as ChannelBoardDto[];

    const linkBoards = result.filter((board) => board.boardType === 'LINK');
    if (linkBoards?.length > 0) {
      getLinks(linkBoards[0].id as number);
    }
  };

  useEffect(() => {
    if (myChannel) {
      setProfile(myChannel?.profile);
      transformStyle(true);
      firstLoad();
    }
    getMyStyles();
    return () => {
      transformStyle(false);
    };
  }, [myChannel]);

  useEffect(() => {
    if (receiver?.id === 'ACTION' && receiver?.param?.categoryId) {
      getLinks(receiver?.param?.categoryId as number);
    }

    if (receiver?.id === 'ACTION' && receiver?.param?.profile) {
      const newProfile = { ...(receiver?.param?.profile as ChannelProfileDto) };
      setProfile(newProfile);
    }

    if (receiver?.id === 'ACTION' && receiver?.param?.id === 'LINK_UPDATE_DONE') {
      getLinks(receiver?.param?.categoryId as number);
    }
  }, [receiver]);

  return (
    <>
      <BackgroundImageComponent
        isPreview
        profile={profile}
        useEmpty={false}
        style={{
          maxWidth: 550,
          width: '100%',
          height: '100%',
          left: 'unset',
          position: 'absolute',
        }}
      />
      <ContentStyle>
        <div style={{ padding: '10px 0', position: 'relative' }}>
          <LinkPageProfileBlocks myChannelName={myChannel?.channelName} myProfile={profile} />
        </div>
        <LinkBoardType links={links} isPreviewMode />
      </ContentStyle>
    </>
  );
});

const ContentStyle = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 30px;
`;

export default PreviewContainer;
