import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useNavigation from 'hooks/useNavigation';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import RowColumn from './layouts/RowColumn';
import MobileModalHeader from './MobileModalHeader';
import { useMediaContext } from 'providers/useMediaContext';

const Modal = observer(() => {
  const ScreenTypes = useMediaContext();
  const { uiStore, historyStore } = useStore();
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [zIndex, setZIndex] = useState(2);

  useEffect(() => {
    return () => {
      uiStore.universalModal.updateModel(uiStore.universalModal?.contentModel);
    };
  }, []);

  useEffect(() => {
    if (uiStore.bottomSheet.modalShow || uiStore.universalModal.modalShow) {
      setZIndex(3);
    } else {
      setZIndex(100);
    }
    console.log('modalShow', uiStore.bottomSheet.modalShow);
  }, [uiStore.bottomSheet.modalShow, uiStore.universalModal.modalShow]);

  return (
    <ReactModal
      isOpen={uiStore.universalModal.modalShow}
      style={{
        overlay: {
          backgroundColor:
            uiStore.universalModal.options?.modalOption?.backgroundColor || 'rgba(0, 0, 0, 0.75)',
          zIndex,
        },
        content: {
          border: '0',
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          margin: 'auto',
          height: 'auto',
          // maxHeight: '80%',
          maxWidth: ScreenTypes.isSmall ? 550 : 900,
          borderRadius: 8,
          ...uiStore.universalModal.options?.modalOption?.style,
        },
      }}
      ariaHideApp={false}
    >
      <ContentStyle className="model-content-style">
        {/* <UniversalHeader /> */}
        {uiStore.universalModal.options?.modalType !== 'confirm' && (
          <MobileModalHeader type="modal" />
        )}
        <RowColumn style={{ top: 40 }}>
          <ContentBodyStyle>{uiStore.universalModal.options?.children}</ContentBodyStyle>
          <div
            style={{
              paddingBottom: 20,
              display: 'flex',
              width: '100%',
              height: 'auto',
              alignContent: 'flex-end',
              justifyContent: 'center',
            }}
          >
            {uiStore.universalModal.options?.useDefaultButtons === true && (
              <div style={{ padding: 20, width: '100%' }}>
                <OhmossButton
                  style={{ padding: '10px 30px' }}
                  onClick={() => {
                    uiStore.universalModal.close(
                      uiStore.universalModal.options?.useHistoryBack || false,
                    );

                    if (uiStore.universalModal.options?.useHistoryBack) {
                      if (historyStore.histories.length > 1) {
                        navigation.pop();
                      } else {
                        setTimeout(() => {
                          const newPath = window.location.pathname.toString().split('/');
                          if (newPath.length === 4) {
                            const slicedArr = newPath.slice(0, -1);
                            window.history.replaceState('force', '', slicedArr.join('/'));
                          }
                        }, 50);
                      }
                    }
                  }}
                >
                  {t('Close')}
                </OhmossButton>
              </div>
            )}
          </div>
        </RowColumn>
      </ContentStyle>
    </ReactModal>
  );
});

const ContentStyle = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentBodyStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export default Modal;
