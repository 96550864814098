import { RoutePropInterface } from 'types/CommonTypes';
import MyPage from 'features/myPage/pages/MyPage';
import InstagramAuthPage from 'features/myPage/pages/InstagramAuthPage';
import DealListPage from 'features/myPage/pages/DealListPage';
import DealsProvider from 'features/management/context/providers/DealsProvider';
import InquiryPage from 'features/myPage/pages/InquiryPage';
import { Privacy, Terms } from 'pages';
import MySettingsPage from 'features/myPage/pages/MySettingsPage';
import DealDetailPage from 'features/myPage/pages/DealDetailPage';
import ProposalPage from 'features/myPage/pages/ProposalPage';
import DealDashBoardPage from 'features/myPage/pages/DealDashBoardPage';
import AllDealsDashboardPage from 'features/myPage/pages/AllDealsDashboardPage';
import BusinessProposalListPage from 'features/myPage/pages/BusinessProposalListPage';
import PartnersManagementPage from 'features/myPage/pages/PartnersManagementPage';
import MembersManagementPage from 'features/myPage/pages/MembersManagementPage';
import DealPartnersPage from 'features/myPage/pages/DealPartnersPage';
import SellerDealListPage from 'features/myPage/pages/SellerDealListPage';
import DelegatedDealsPage from 'features/myPage/pages/DelegatedDealsPage';
import CheckListPage from 'features/myPage/pages/CheckListPage';
import InquiryDetailPage from 'features/myPage/pages/InquiryDetailPage';
import ChecklistMorePage from 'features/myPage/pages/ChecklistMorePage';
import InquiryThreadsPage from 'features/myPage/pages/InquiryThreadsPage';
import MembershipPlanPage from 'features/myPage/pages/MembershipPlanPage';
import MembershipPaymentAuth from 'features/myPage/components/membership/MembershipPaymentAuth';
import MembershipTerms from 'features/myPage/containers/membership/MembershipTerms';
import ChecklistSelectorPage from 'features/myPage/pages/ChecklistSelectorPage';
import ViewSummary from 'features/myPage/pages/ViewSummaryPage';
import MyInquriesChat from 'features/myPage/containers/MyInquriesChat';
import { useMediaContext } from 'providers/useMediaContext';

import CSTownDealInquiriesPage from 'features/myPage/pages/CSTownDealInquiriesPage';
import CSTownRootPage from 'features/csTown/pages/CSTownRootPage';

import ManagerListPage from 'features/csTown/pages/ManagerListPage';
import CSTownDealsPage from 'features/csTown/pages/CSTownDealsPage';
import CSTownInquiriesPage from 'features/csTown/pages/CSTownInquiriesPage';
import CSTownPartnersPage from 'features/csTown/pages/CSTownPartnersPage';
import CSTownChecklistPage from 'features/csTown/pages/CSTownChecklistPage';
import CSTownChecklistDetailPage from 'features/csTown/pages/CSTownChecklistDetailPage';
import CSTownDashboardPage from 'features/csTown/pages/CSTownDashboardPage';
const myRoutes: RoutePropInterface[] = [
  {
    path: '/my',
    title: 'My page',
    element: MyPage,
    provider: DealsProvider,
    useHeader: false,
  },
  {
    path: '/my/settings',
    title: '내 설정',
    element: MySettingsPage,
  },
  {
    path: '/my/deals',
    title: '공구 리스트',
    element: DealListPage,
    provider: DealsProvider,
  },
  {
    path: '/my/partners',
    title: '셀러 관리',
    element: DealPartnersPage,
    provider: DealsProvider,
  },
  {
    path: '/my/partners/:partnerid/deals',
    title: '셀러 공구리스트',
    element: SellerDealListPage,
    provider: DealsProvider,
  },
  {
    path: '/my/deals/:linkkey/dashboard',
    title: '공구 대시보드',
    element: DealDashBoardPage,
    provider: DealsProvider,
  },
  {
    path: '/my/deals/:adid', // adid === dealid
    title: 'my page',
    element: DealDetailPage,
    provider: DealsProvider,
  },
  {
    path: '/my/deals/inquiry',
    title: '공구 문의 목록',
    element: InquiryPage,
    provider: DealsProvider,
  },
  {
    path: '/chat/:channelurl',
    title: '문의',
    element: MyInquriesChat,
    provider: DealsProvider,
  },
  {
    path: '/my/deals/:dealid/inquiry',
    title: '공구 문의 내역',
    element: InquiryDetailPage,
    provider: DealsProvider,
  },
  {
    path: '/my/deals/:dealid/inquiry/:inquiryid',
    title: '문의 상세',
    element: InquiryThreadsPage,
    provider: DealsProvider,
  },
  {
    path: '/my/auth/instagram',
    title: 'instagram auth page',
    element: InstagramAuthPage,
  },
  {
    path: '/my/userterms',
    title: '이용약관',
    isPublic: true,
    element: Terms,
  },
  {
    path: '/my/privacypolicy',
    title: '개인정보 수집 및 동의',
    isPublic: true,
    element: Privacy,
  },
  {
    path: '/my/business-proposal',
    title: '제안 내역',
    element: BusinessProposalListPage,
  },
  {
    path: '/my/proposal',
    title: '문의 내역',
    element: ProposalPage,
  },
  {
    path: '/my/deals/checklist',
    title: '체크리스트',
    element: CheckListPage,
  },
  {
    path: '/my/subscriptions',
    title: 'subscriptions',
    element: MembershipPlanPage,
    provider: DealsProvider,
    isPublic: true,
    useHeader: false,
  },
  {
    path: '/my/deals/dashboard',
    title: 'dashboard',
    element: AllDealsDashboardPage,
  },
  {
    path: '/my/deals/partners',
    title: 'CS 파트너 관리',
    element: PartnersManagementPage,
  },
  {
    path: '/my/deals/delegated-deals',
    title: '내 공구리스트',
    element: DelegatedDealsPage,
  },
  {
    path: '/my/members',
    title: '매니저 관리',
    element: MembersManagementPage,
  },
  {
    path: '/my/deals/checklist',
    title: '체크리스트',
    element: CheckListPage,
    provider: DealsProvider,
  },
  {
    path: 'my/deals/checklist/detail',
    title: '체크리스트 상세',
    element: ChecklistMorePage,
    provider: DealsProvider,
  },
  {
    path: 'my/deals/checklist/detail/list',
    title: '제안서 / 상품기획 체크 리스트',
    useHeader: false,
    element: ChecklistSelectorPage,
  },
  {
    path: 'my/deals/checklist/detail/summary',
    title: '요약보기',
    useHeader: false,
    element: ViewSummary,
  },
  {
    path: '/my/subscriptions/payment',
    title: '결제 승인',
    useHeader: false,
    element: MembershipPaymentAuth,
  },
  {
    path: '/my/subscriptions/agreements',
    title: '약관동의',
    useHeader: false,
    element: MembershipTerms,
  },
  {
    path: '/cstown',
    title: 'cstown 메인',
    element: CSTownRootPage,
    provider: DealsProvider,
    useHeader: false,
  },
  {
    path: '/cstown/home',
    title: 'cstown 메인',
    element: CSTownRootPage,
    provider: DealsProvider,
    useHeader: false,
  },
  {
    path: '/cstown/deals',
    title: '내 공구목록',
    element: CSTownDealsPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/inquiries',
    title: '내 문의 목록',
    element: CSTownInquiriesPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/deals/:dealid',
    title: '관리 공구목록',
    element: CSTownDealsPage,
    provider: DealsProvider,
  },

  {
    path: '/cstown/deal/:dealid/inquiries',
    title: '공구 문의 목록',
    element: CSTownDealInquiriesPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/partners',
    title: '파트너 관리',
    element: CSTownPartnersPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/checklist',
    title: '체크리스트',
    element: CSTownChecklistPage,
  },
  {
    path: '/cstown/checklist/detail',
    title: '체크리스트 상세',
    element: CSTownChecklistDetailPage,
    provider: DealsProvider,
  },
  {
    path: '/cstown/checklist/detail/list',
    title: '제안서 / 상품기획 체크 리스트',
    useHeader: false,
    element: ChecklistSelectorPage,
  },
  {
    path: '/cstown/managers',
    title: '매니저 관리',
    element: ManagerListPage,
  },
  {
    path: '/cstown/dashboard',
    title: '통합대시보드',
    useHeader: false,
    element: CSTownDashboardPage,
  },
];

export default myRoutes;
