import { useEffect, useState } from 'react';

const usePlatformChecker = (): string => {
  const [platformHeight, setPlatformHeight] = useState<string>('auto');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('User Agent:', userAgent);

    const isAndroidWebView = /Android.*(wv|\.0\.0\.0)/.test(userAgent) || /; wv\)/.test(userAgent);
    const isIOSWebView = /iPhone|iPod|iPad.*AppleWebKit(?!.*Safari)/i.test(userAgent);

    if (isIOSWebView) {
      console.log('Detected: iOS WebView');
      document.documentElement.classList.add('ios');
      setPlatformHeight('-webkit-fill-available');
    } else if (isAndroidWebView) {
      console.log('Detected: Android WebView');
      document.documentElement.classList.add('android');
      setPlatformHeight('100%');
    } else {
      console.log('Detected: Other');
      setPlatformHeight('auto');
    }
  }, []);

  return platformHeight;
};

export default usePlatformChecker;
