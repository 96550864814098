import React from 'react';
import styled, { css } from 'styled-components';
import { ChannelBoardDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { isMobile } from 'react-device-detect';

import TextLabel from 'components/commons/labels/TextLabel';
import SingleRadio from 'components/commons/buttons/SingleRadio';
import MyBoardStore from 'stores/MyBoardStore';
import CategoryFormContainer from 'features/management/containers/category/CategoryFormContainer';
import ListItemHeader from '../block/ListItemTemplates/ListItemHeader';
import CommonThumbnail from '../commonControl/CommonThumbnail';
import { useRootContext } from 'libs/hooks/useRootContext';

const CategoryItem = ({
  item,
  onPrimaryChanged,
  onClickMore,
  fetchData,
}: {
  item: ChannelBoardDto;
  onPrimaryChanged: () => void;
  fetchData: () => void;
  onClickMore: () => void;
}) => {
  const { navigate } = useNavigation();
  const myBoardStore = new MyBoardStore();

  const { uiStore } = useStore();
  const { myChannel } = useRootContext();

  // const [isActive, setIsActive] = useState<boolean>(true);
  // const [isPrimary, setIsPrimary] = useState<boolean>();

  const getBoardType = () => {
    if (item.isDefault) {
      return '게시판';
    } else if (item.boardType === 'LINK') {
      return '링크관리';
    } else if (item.boardType === 'BOARD') {
      return '게시판';
    }
  };

  const handleClickNewCategory = () => {
    uiStore.bottomSheet.show({
      topPosition: isMobile ? 40 : undefined,
      title: item ? '페이지 수정' : '새 페이지 만들기',
      children: <CategoryFormContainer item={item} />,
      onConfirmed: () => {
        fetchData();
      },
    });
  };

  const handleDeleteCategory = () => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      message: '페이지를 삭제하시겠습니까? \n 아이템이 있는경우는 삭제하실 수 없습니다.',
      onConfirmed: async () => {
        const result = await myBoardStore.deleteBoard(myChannel?.id as number, item.id as number);
        if (result) {
          fetchData();
        }
      },
    });
  };

  const handleChangeActive = async (isActive: boolean) => {
    const newBoard: ChannelBoardDto = { ...item, isActive: !item.isActive };
    const result = await myBoardStore.updateBoard(myChannel?.id as number, newBoard);
    if (result) {
      fetchData();
    }
    // uiStore.bottomSheet.close();
    // uiStore.confirm.show({
    //   title: isActive ? '비공개 전환' : '공개 전환',
    //   message: isActive
    //     ? '비공개로 전환되면 다른 사람들은 볼 수 없게됩니다.'
    //     : '공개로 전환되면 다른 사람들도 볼 수 있습니다.',
    //   onConfirmed: async () => {
    //     const newBoard: ChannelBoardDto = { ...item, isActive: !item.isActive };
    //     const result = await myBoardStore.updateBoard(myChannel?.id as number, newBoard);
    //     if (result) {
    //       fetchData();
    //     }
    //   },
    // });
  };

  const handleClickCategory = () => {
    navigate(`/management/categories/${item.id}`);
  };

  const handleChangePrimary = () => {
    onPrimaryChanged();
  };

  // useEffect(() => {
  //   setIsActive(item.isActive || false);
  //   setIsPrimary(item.isPrimary);
  // }, [item]);

  return (
    <ContainerStyle>
      <Row style={{ width: '100%' }}>
        {/* <div style={{ marginLeft: 20, marginRight: 10 }}>
          <img src="/images/management/handle.svg" alt="" />
        </div> */}
        <div style={{ marginLeft: 10 }}>
          <SingleRadio
            checked={item.isPrimary}
            onChange={handleChangePrimary}
            useConfirm
            confirmMessage={`${item.name} 페이지를 대표페이지로 저장하시겠습니까?\r\n\r\n대표링크로 지정된 페이지가 \r\n가장 먼저표시됩니다.`}
          />
        </div>
        <div
          style={{ marginLeft: 10, width: '100%', cursor: 'pointer' }}
          onClick={handleClickCategory}
        >
          <Row style={{ width: '100%', justifyContent: 'flex-start' }}>
            <CommonThumbnail
              parent={40}
              initialValue={item.thumbnail?.publicUrl ?? '/images/ohmossc.png'}
            />
            <div style={{ marginLeft: 20 }}>
              <TextLabel type="subject" style={{ fontSize: 16 }}>
                {item.name}{' '}
              </TextLabel>
              <div style={{ fontSize: 12, fontWeight: 500, color: '#666' }}>
                {item?.postCount}개의 게시물
              </div>
              <BoardTypeStyle type={item.isDefault ? 'AD' : item.boardType}>
                {getBoardType()}
              </BoardTypeStyle>
            </div>
          </Row>
        </div>
      </Row>
      <ListItemHeaderStyle style={{ marginRight: 10 }}>
        <ListItemHeader
          useConfirm
          confirmMessage={
            item.isActive
              ? '비공개로 전환되면 다른 사람들은 볼 수 없게됩니다.'
              : '공개로 전환되면 다른 사람들도 볼 수 있습니다.'
          }
          // title={item.name}
          initialActive={item.isActive}
          onActiveChange={(isActive) => {
            handleChangeActive(isActive);
          }}
          onDelete={() => {
            handleDeleteCategory();
          }}
          onModify={() => {
            handleClickNewCategory();
          }}
        />
      </ListItemHeaderStyle>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* height: 100px; */
  padding: 20px 0;
  align-items: center;
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
    padding: 10px;
  }
`;

const LinkStyle = css`
  color: #ffffff !important;
  background: #05c755;
`;

const BoardStyle = css`
  color: #808080;
  border: 1px solid #808080;
`;

const ADBoardStyle = css`
  color: #05c755;
  background: rgba(5, 199, 85, 0.08);
`;

const BoardTypeStyle = styled.div<{
  type?: 'LINK' | 'BOARD' | 'AD' | 'CALENDAR' | 'SHOP' | undefined;
}>`
  border-radius: 5px;
  margin-top: 5px;
  text-align: center;
  padding: 0 15px;
  display: inline-block;
  font-size: 14px;
  ${(props) => {
    if (props.type === 'LINK') {
      return LinkStyle;
    } else if (props.type === 'BOARD') {
      return BoardStyle;
    } else if (props.type === 'AD') {
      return ADBoardStyle;
    }
  }};
`;

const ListItemHeaderStyle = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 100%;
  }
`;

export default CategoryItem;
